import React from "react";

import {
  Container,
  Box,
  Button,
  InputLabel,
  Typography,
  // Customizable Area Start
  ButtonGroup,
  Grid,
  TextField,
  FormControlLabel,
  RadioGroup,
  Radio,
  Select,
  MenuItem,
  FormHelperText,
  Menu,styled,
  OutlinedInput
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import NavigationSideBar from "../../../components/src/NavigationSideBar";
import Loader from "../../../components/src/Loader.web";
import { Toaster } from "react-hot-toast";

const theme = createTheme({
  palette: {
    primary: {
      main: "#8760A9",
      contrastText: "#fff",
    },
  },
  overrides: {
    MuiRadio: {
      root: {
        color: '#8760A9',
      },
      colorSecondary: {
        '&$checked': {
          color: '#8760A9',
        },
      },
    
    },
    MuiFormLabel: {
      asterisk: {
        color: '#db3131',
        '&$error': {
          color: '#db3131'
        },
      }
    },
  }
});

const CustomMenuItem = styled(MenuItem)({
  width:'200px',
  padding: '9px 16px'
})
import { ArrowDropDown, ExpandMoreRounded } from "@material-ui/icons";
import ReactQuill from "react-quill";
const modules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    [{ 'align': '' }, { 'align': 'center' }, { 'align': 'right' }, { 'align': 'justify' }],
    [{ 'color': [] }, { 'background': [] }],
    ['image'],
  ],
};

const formats = [
  'list', 'bullet',
  'bold', 'italic', 'underline', 'strike',
  'align',
  'color', 'background',
  'image'
];
// Customizable Area End

import CfslamanagementController, {
  Props,
  configJSON,
} from "./CfslamanagementController";

export default class Cfslamanagement extends CfslamanagementController {
    constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start


  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <Box style={{ position: 'fixed', height: '100%', width: '100%', pointerEvents: 'none', overflow: 'hidden', zIndex: 1000 }}>
          <Loader loading={this.state.isLoading} />
        </Box>
        <Toaster />
        <NavigationSideBar active="tickets" navigation={this.props.navigation} />
        <ThemeProvider theme={theme}>
          <Box
            style={webStyle.container}
            data-testid={"form_modal"}
          >
            <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Box style={webStyle.topheader}>
                <span style={webStyle.navigation}>
                  <span >Settings</span>
                  <span>{" > "}</span>
                  <span>Service management</span>
                  <span>{" > "}</span>
                  <span >SLA  Policies</span>
                </span>
              </Box>
            </Box>
            <Typography style={webStyle.mainHeading}>{configJSON.labelpolicynameText}</Typography>
            <Grid container spacing={4} style={{ marginTop: '2%', boxShadow: "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)", paddingBottom: '2%' }}>
              <Grid item xs={6} sm={6} md={6} lg={6}>
                <Box sx={{ ...webStyle.boxStyle_4, marginBottom: 15 }}>
                  <Typography style={webStyle.inputLabel}>Name<span style={webStyle.textColorRed}> *</span></Typography>
                  <TextField
                    variant="outlined"
                    value={this.state.name}
                    onChange={this.handleNameChange}
                    data-testId={"name_input"}
                    placeholder="Enter name"
                  />
                  {this.state.nameError && <Typography style={webStyle.textColorRed}>{this.state.nameError}</Typography>}
                </Box>
                <Box sx={{ ...webStyle.boxStyle_4, marginTop: '15px' }}>
                  <Typography style={webStyle.inputLabel}>Priority<span style={webStyle.textColorRed}> *</span></Typography>
                  <RadioGroup
                    aria-label="priority"
                    name="priority"
                    value={this.state.prioritycheckValue}
                    onChange={this.handlePriorityChange}
                    data-testid={"change_priority"}
                  >
                    <FormControlLabel value="Low" control={<Radio />} label="Low" />
                    <FormControlLabel value="Medium" control={<Radio />} label="Medium" />
                    <FormControlLabel value="High" control={<Radio />} label="High" />
                    <FormControlLabel value="Urgent" control={<Radio />} label="Urgent" />
                  </RadioGroup>
                  {this.state.priorityError && <Typography style={webStyle.textColorRed}>{this.state.priorityError}</Typography>}
                </Box>
                <Box sx={{ ...webStyle.boxStyle_4, marginTop: '15px' }}>
                  <Typography style={webStyle.inputLabel}>First response<span style={webStyle.textColorRed}> *</span></Typography>
                  <Select
                    input={<OutlinedInput />}
                    IconComponent={ExpandMoreRounded}
                    style={webStyle.dropdownStyle}
                    fullWidth
                    value={this.state.selectedFirstResponse}
                    onChange={this.handleFirstResponseChange}
                    data-testid={"first_response"}
                    displayEmpty
                  >
                    <MenuItem value="" disabled>
                      Select duration for escalation
                    </MenuItem>
                    {this.timeOptions.map((option) => (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {this.state.firstResponseError && <Typography style={webStyle.textColorRed}>{this.state.firstResponseError}</Typography>}
                </Box>
                <Box sx={{ ...webStyle.boxStyle_4, marginTop: '15px' }}>
                  <Typography style={webStyle.inputLabel}>Resolution Time<span style={webStyle.textColorRed}> *</span></Typography>
                  <Select
                    input={<OutlinedInput />}
                    IconComponent={ExpandMoreRounded}
                    style={webStyle.dropdownStyle}
                    fullWidth
                    value={this.state.resolutiontimeValue}
                    onChange={this.handleResoltionTimeChange}
                    data-testid={"change_resolution"}
                    displayEmpty
                  >
                    <MenuItem value="" disabled>
                      Select duration for escalation
                    </MenuItem>
                    {this.timeOptions.map((option) => (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {this.state.resolutiontimeError && <Typography style={webStyle.textColorRed}>{this.state.resolutiontimeError}</Typography>}
                </Box>
              </ Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} spacing={2}>
                <Box sx={{ ...webStyle.boxStyle_4, marginBottom: '20px' }}>
                  <Typography style={{ ...webStyle.inputLabel, marginTop: '10px' }}>Description<span style={webStyle.textColorRed}> *</span></Typography>

                  <ReactQuill
                    data-testid="description_change"
                    placeholder="Enter description"
                    modules={modules}
                    formats={formats}
                    value={this.state.description}
                    onChange={this.handleChange}
                    style={{
                      fontStyle: 'normal',
                    }}
                  />

                  {this.state.descriptionError && <Typography style={webStyle.textColorRed}>{this.state.descriptionError}</Typography>}
                </Box>
              </Grid>
              <Box style={webStyle.buttonBoxSyle}>
                <Typography
                  style={webStyle.cancelButton}
                  data-testid='cancel_btn'
                  onClick={() => this.handleCancel()}
                >
                  Cancel
                </Typography>

                <Button
                  style={webStyle.submitButton}
                  variant="contained"
                  onClick={this.handleSubmit}
                  data-testid={"submit_form"}
                >
                  Submit
                </Button>

              </Box>
            </ Grid>
          </Box>
        </ThemeProvider >
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  container: {
    height: "100%",
    margin: "15px 20px 15px 300px",
    paddingBottom: "25px",
    fontFamily: "Manrope",
  } as const,
  buttonBoxSyle: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '20px',
    marginTop: '100px',
    width:"98%",
    border: 'none', 
    background: 'transparent', 
    padding: 0, 
  },
  textColorRed: {
    color: 'red'
  },
  dropdownStyle: {
    color: '#A3A6C2'
  },
  inputLabel: {
    color: '#3D405C',
    fontSize: '12px',
    fontWeight: 500,
    marginBottom: '4px'
  },
  mainHeading: {
    fontFamily: 'Manrope',
    color: '#33354',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    fontSize: '30px',
    padding: '40px 0'
  } as const,
  boxStyle_4: {
    display: 'flex',
    flexDirection: 'column',
  },
  topheader: {
    display: "flex",
    justifyContent: "space-between",
    padding: '24px 0'
  } as const,
  navigation: {
    color: '#3D405C',
    display:'flex',
    alignItems:'center',
    gap:'8px',
    fontSize: '14px',
    fontFamily: 'Manrope',
    fontWeight: 400,
    fontStyle: 'normal',
    lineHeight: 'normal',
  } as const,
  mainWrapper: {
    display: "flex",
    gap:"40px",
    margin:"35px 20px 00px 20px" ,
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  lablestyle:{
    fontSize:"15px",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },

  card:{
    paddingBottom: "10px", 
    marginBottom: "10px" 
  },
  policyName:{
    width:"400px",
    marginTop:"40px ",
    fontStyle:"Manrope",
    fontWeight:500,
    fontSize:"35px",
    lineHeight:"40px",
    color:"#33354D"
  },
  task_container:{
    margin:"30px 40px 30px 00px",
    display:"flex",
    flexDirection:"column",
  },
  top_header:{
    width:"100%",
    display:"flex",
    alignItems:'center',
    justifyContent:"space-between"
  },
  buttonGroup: {
    borderRadius: 8,
  },
  button: {
    backgroundColor: "#8E5DBA",
    color: "white",
    "&:hover": {
      backgroundColor: "#7A4CA6",
  }
},
  dropdownButton: {
    backgroundColor: "#8E5DBA",
    color: "white",
    minWidth: "40px",
    "&:hover": {
      backgroundColor: "#7A4CA6",
    },
  },
  radioBtn:{
    color: "#8760A9", 
    transform: "scale(0.95)" ,
   paddingBottom:"10px"

  },
  priorityLable:{
    fontSize: "0.8rem"
  },
  dropdown:{
     borderRadius:"10px",
     fontFamily:"Manrope",
     lineHeight:"20px",
     fontSize:"18px",
     color: '#404040'
  },
  editor: {
    width: "491px",
    borderRadius:"8px",
    minHeight: "335px",
    background: "#ffff",
    lineHeight: '23px',
    fontWeight: 400,
    color: '#A3A6C2',
    fontSize: '16px',
    fontFamily: 'Manrope',
  },
  asterisk: {
    color: "red",
    marginLeft: "4px",
  },
  saveBtn:{
    display:"flex",
    justifyContent: "flex-end",
   marginTop:"100px",
   marginLeft:"85px",
    gap:"20px",
   
  },
  cancelButton: {
    color: '#8760A9',
    fontFamily: 'Manrope',
    fontSize: '16px',
    fontWeight: 700,
    cursor: 'pointer'
  },

  submitButton: {
    backgroundColor: '#8760A9',
    fontFamily: 'Manrope',
    fontSize: '16px',
    fontWeight: 700,
    color: '#FFFFFF',
    textTransform: 'initial' as const,
    padding: '5px 35px 5px 35px',
  },
  "@media(max-width:900px)": {
    "card": {
      flex: "1 1 calc(50% - 16px)",
      maxWidth: "calc(50% - 16px)"
    }
  },
 " @media (max-width: 600px)" :{
    "card": {
      flex: "1 1 calc(50% - 16px)",
      maxWidth: "calc(50% - 16px)"
    }
  }  
};
// Customizable Area End
