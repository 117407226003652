export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const cancelIcon = require('../assets/Cancel.svg')
export const downArrow = require('../assets/DownArrow.svg')
export const DropDpwnIcon = require('../assets/DropDownIcon.svg')
export const infoIcon = require('../assets/InfoIcon.svg')
export const infoIconOutlined = require('../assets/InfoIconOutlined.svg')
export const editIcon = require('../assets/edit.svg')
export const deleteIcon = require('../assets/delete.svg')
export const slaolaIcons = require("../assets/SlaOla.svg")
