import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import toast from 'react-hot-toast';
import moment from "moment-timezone";
import * as yup from 'yup';
// Interface for department data
interface Departments {
    id: string;
    type: string;
    attributes: {
        department_name: string;
        description: string;
        domains_for_department: string;
        department_head: string;
        prime_user: string;
    };
}


// Interface for agents data
interface AgentAndRequesterData {
    data: AgentAndRequester[];
}

export interface AgentAndRequester {
    id: string;
    type: string;
    attributes: AgentAndRequesterAttributes;
}

// Interface for agent fields
interface DynamicFieldData {
    data: DynamicField[];
}

export interface DynamicField {
    id: string;
    type: string;
    attributes: DynamicFieldAttributes;
}

interface DynamicFieldAttributes {
    name: string;
    title: string | null;
    column_type: string;
    mandatory: boolean;
    form_type: string;
    status: boolean;
    optional: boolean;
}

interface AgentFormValues {
    [key: string]: string | boolean | number | null;
}

interface AgentAndRequesterAttributes {
    [key: string]: string | boolean | null;
}

interface SchemaFields {
    [key: string]: yup.StringSchema | yup.NumberSchema;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    isLoading: boolean,
    txtInputValue: string;
    agentsList: AgentAndRequester[];
    page: number;
    rowsPerPage: number;
    openListIndex: number;
    showModal: boolean;
    isInfoModalOpen: boolean;
    anchorEl: HTMLElement | null | undefined
    fields: DynamicField[];
    editingAgent: AgentAndRequester | null
    editMode: boolean;
    departmentList: Departments[]
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class AgentsController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    agentSaveApiCallId: string = '';
    getAgentFieldsApiCallId: string = '';
    getAllAgentsApiCallId: string = '';
    removeAgentApiCallId: string = '';
    agentUpdateApiCallId: string = '';
    getDepartmentsListCallID: string = '';
    pathname = "/agents";
    token: string = ''
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            rowsPerPage: 5,
            txtInputValue: "",
            isLoading: false,
            agentsList: [],
            page: 0,
            openListIndex: -1,
            showModal: false,
            isInfoModalOpen: false,
            anchorEl: null,
            fields: [],
            editingAgent: null,
            editMode: false,
            departmentList: []
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        const apiRequestId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        )
        this.setState({
            isLoading: false
        })

        // This will rerdirect user to login page if token is not valid 
        if (Array.isArray(responseJson?.errors) && (responseJson?.errors[0].token === "Token has Expired" ||  responseJson?.errors[0].token === "Invalid token")) {
            this.goToLogin();
        }
        
        if (apiRequestId === this.getDepartmentsListCallID) {
            this.setState({
                departmentList: responseJson.data
            })
            return;
        }
        switch (apiRequestId) {
            case this.getAllAgentsApiCallId:
                await this.handleAgentsApiDataResponse(responseJson);
                this.getAgentFieldData()
                break;
        
            case this.agentSaveApiCallId:
                this.handleCreateAgentResponse(responseJson)
                break;
        
            case this.removeAgentApiCallId:
                await this.handleRemoveAgentResponse(responseJson);
                break;
            case this.getAgentFieldsApiCallId:
                await this.handleGetAgentFieldsResponse(responseJson);
                this.getDepartmentList()
                break;
            case this.agentUpdateApiCallId:
                await this.handelUpdateAgentResponse(responseJson)
                break;
            default:
                break;
        }
        
        // Customizable Area End
    }



    // Customizable Area Start
    async componentDidMount() {
        this.token = await getStorageData('authToken')
        this.getAllAgents();
    }


    getAllAgents() {
        this.setState({ isLoading: true });
        const header = {
            "Content-Type": configJSON.getAllAgentsApiContentType,
            token: this.token
        };
        let requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getAllAgentsApiMethod
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getAllAgentsApiEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        this.getAllAgentsApiCallId = requestMessage.messageId;

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    async getAgentFieldData() {
        this.setState({ isLoading: true });
        const header = {
            "Content-Type": configJSON.getAgentsFieldsApiContentType,
            token: this.token
        };
        let requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getAgentsFieldsApiMethod
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getAgentsFieldsApiEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        this.getAgentFieldsApiCallId = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getDepartmentList() {
        const header = {
            "Content-Type": configJSON.departmentsApiContentType,
            token: this.token
        };
        let requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.departmentsApiMethod
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.departmentsApiEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        this.getDepartmentsListCallID = requestMessage.messageId;

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    goToLogin() {
        const msg: Message = new Message(
          getName(MessageEnum.NavigationEmailLogInMessage)
        );
        msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(msg);
      }

    setInputValue = (text: string) => {
        this.setState({ txtInputValue: text });
    };

    onPageChange = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
        if (this.state.page !== page) {
            this.setState({ page });
        }
    };

    onRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        if (this.state.rowsPerPage !== newRowsPerPage) {
            this.setState({ rowsPerPage: newRowsPerPage });
        }
    };

    openAccordian = (index: number) => {
        this.setState((prevState: { openListIndex: number }) => ({
            openListIndex: prevState.openListIndex === index ? -1 : index,
        }));
    };

    getTableData() {
        return this.state.agentsList.slice(
            this.state.page * this.state.rowsPerPage,
            Math.min(
                this.state.page * this.state.rowsPerPage + this.state.rowsPerPage,
                this.state.agentsList.length
            )
        )
    }

    handleModalOpen(value: boolean) {
        if (value === false) {
            this.setState({
                editMode: false
            })
        }
        this.setState({
            showModal: value,
        })
    }

    handelInfoModal(value: boolean) {
        this.setState({
            isInfoModalOpen: value
        })
    }

    handleInfoIconClick = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({
            isInfoModalOpen: true,
            anchorEl: event.currentTarget,
        });
    };

    handleSubmit = (values: AgentFormValues, { setSubmitting }: { setSubmitting: (value: boolean) => void }) => {
        if (values) {
            if (this.state.editMode) {
                this.updateAgent(this.state.editingAgent?.id, values);
            } else {
                this.setState({ isLoading: true });

                const header = {
                    "Content-Type": configJSON.agentCreateApiContentType,
                    token: this.token
                };

                const httpBody = {
                    agent_and_requester: {
                        "user_type": "agent",
                        ...values
                    }
                }

                const requestMessage = new Message(
                    getName(MessageEnum.RestAPIRequestMessage)
                );

                this.agentSaveApiCallId = requestMessage.messageId;
                requestMessage.addData(
                    getName(MessageEnum.RestAPIResponceEndPointMessage),
                    configJSON.agentCreateApiEndPoint
                );

                requestMessage.addData(
                    getName(MessageEnum.RestAPIRequestHeaderMessage),
                    JSON.stringify(header)
                );

                requestMessage.addData(
                    getName(MessageEnum.RestAPIRequestBodyMessage),
                    JSON.stringify(httpBody)
                );

                requestMessage.addData(
                    getName(MessageEnum.RestAPIRequestMethodMessage),
                    configJSON.agentCreateApiMethod
                );

                runEngine.sendMessage(requestMessage.id, requestMessage);
            }
        } else {
            setSubmitting(false);
        }
    }

    updateAgent = (id: string | undefined, values: AgentFormValues) => {
        if (id) {
            const header = {
                "Content-Type": configJSON.updateAgentApiContentType,
                token: this.token
            };

            const httpBody = {
                agent_and_requester: {
                    "user_type": "agent",
                    ...values
                }
            }
            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.agentUpdateApiCallId = requestMessage.messageId;
            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                configJSON.updateAgentApiEndPoint + id
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(httpBody)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.updateAgentApiMethod
            );

            runEngine.sendMessage(requestMessage.id, requestMessage);
        }
    }

    getOptions = (data: DynamicField): string[] => {
        if (data.attributes.name === 'time_zone') {
            return moment.tz.names()
        } else if (data.attributes.name === 'reporting_manager') {
            return this.state.agentsList.map((x: AgentAndRequester) => {
                const fullName = x.attributes.full_name;
                return typeof fullName === 'string' ? fullName : '';
            });
        } else if (data.attributes.name === 'language') {
            return ['Hindi', 'English', 'Tamil']
        }
        return ['Am', 'Pm']
    }

    handleEditAgent = (data: AgentAndRequester) => {
        this.handleModalOpen(true);
        this.setState({
            editingAgent: data,
            editMode: true
        });
    }

    handleRemoveAgent = (id: string) => {
        this.setState({ isLoading: true });
        const header = {
            "Content-Type": configJSON.removeAgentApiContentType,
            token: this.token
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.removeAgentApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.removeAgentApiEndPoint + id
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.removeAgentApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    createInitialValues = () => {
        const initialValues: AgentFormValues = {}

        this.state.fields.forEach((field: DynamicField) => {
            initialValues[field.attributes.name] = field.attributes.column_type === 'checkbox' ? false : '';
        });

        return initialValues;
    }

    convertAgentDataToFormValues = (agentData: AgentAndRequester | null): AgentFormValues => {
        if (agentData) {
            let initialValues: AgentFormValues = {};
            this.state.fields.forEach((field: DynamicField) => {
                initialValues[field.attributes.name] = agentData.attributes[field.attributes.name] || '';
            });
            return initialValues;
        }
        return {}
    }
    updateColumnType(response:any) { if (response.title === "Address") { response.column_type ="address"
    } return response; }
   
    createDynamicValidationSchema = () => {
        const schemaFields: SchemaFields = {};
    
        const commonValidations = {
            email: () =>
                yup.string()
                    .trim()
                    .email("Invalid email address")
                    .required("Email is required")
                    .max(50, "Email must be at most 50 characters")
                    .matches(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/, "Email must be in a valid format"),
    
            address: (isOptional: boolean) => {
                let schema = yup.string()
                    .trim()
                    .min(10, "Address must be at least 10 characters long")
                    .max(250, "Address must not exceed 250 characters")
                    .required("Address is required");
    
                return isOptional ? schema.max(500, "Address must not exceed 500 characters") : schema;
            },
    
            number: () =>
                yup.string()
                    .required("Phone number is required.")
                    .matches(/^\d+$/, "No letters are allowed. Only numbers.")
                    .min(10, "Phone number must be at least 10 digits.")
                    .max(15, "Phone number must not exceed 15 digits."),
    
            textarea: (isOptional: boolean) => {
                let schema = yup.string()
                    .typeError("Must be a valid text")
                    .trim()
                    .min(10, "Must be at least 10 characters long")
                    .max(500, "Must not exceed 500 characters");
    
                return isOptional ? schema.max(700, "Must not exceed 700 characters") : schema.required("This field is required");
            },
    
            input: (isOptional: boolean, fieldName: string) => {
                let schema = yup.string().trim();
                if (fieldName === "full_name") {
                    schema = schema.matches(/^[A-Za-z\s]+$/, "Name must not contain numbers or special characters!");
                }
    
                return isOptional
                    ? schema.min(4, "The text entered is too short; please provide at least 4 characters.")
                        .max(50, "The input exceeds the maximum limit of 50 characters. Please shorten it.")
                    : schema;
            }
        } as const;
    
        this.state.fields.forEach(field => {
            if (field.attributes.status) {
                const tag = this.updateColumnType(field.attributes);
                const columnType = (field.attributes.column_type || tag.column_type) as keyof typeof commonValidations;
    
                const isOptional = field.attributes.optional ?? false;
    
                if (columnType in commonValidations) {
                    schemaFields[field.attributes.name] =
                        columnType === "input"
                            ? commonValidations[columnType](isOptional, field.attributes.name)
                            : commonValidations[columnType](isOptional);
                } else {
                    schemaFields[field.attributes.name] = yup.string().trim();
                }
            }
        });
    
        return yup.object().shape(schemaFields);
    };
    
    isPathExist = (item: { href: string, title: string }[]) => {
        return item.some((item: { href: string }) => item.href === this.pathname);
    }

    // It will redirect user to agent profile page 
    navigateUser = (id: string) => {
        const navigationMessage: Message = new Message(
            getName(MessageEnum.NavigationAgentProfileMessage)
        );
        navigationMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        navigationMessage.addData(getName(MessageEnum.UrlPathParamMessage), id);
        this.send(navigationMessage);
    }

    handleNavigation = (path:string)=>{
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), path);
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message)
    }

    handleAgentsApiDataResponse = async (responseJson: AgentAndRequesterData) => {
        if (responseJson?.data) {
            this.setState({
                agentsList: responseJson.data
            });
        }
    }

    handleRemoveAgentResponse = async (responseJson: { message: string }) => {
        if (responseJson?.message === configJSON.removeAgentResponse) {
            toast.success(configJSON.agentRemoveMessage)
            this.getAllAgents()
        }
    }

    handleGetAgentFieldsResponse = async (responseJson: DynamicFieldData) => {
        console.log('responseJson :',responseJson)
        if (responseJson?.data) {
            this.setState({
                fields: responseJson.data
            })
        }
    }

    handleCreateAgentResponse = async (responseJson: { data: DynamicField }) => {
        if (responseJson?.data?.id) {
            toast.success(configJSON.agentAddMessage)
            this.getAllAgents();
            this.setState({
                showModal: false
            })
        }
    }

    handelUpdateAgentResponse = async (responseJson: { data: DynamicField }) => {
        if (responseJson?.data?.id) {
            toast.success(configJSON.agentUpdateMessage)
            this.setState((prevState: any) => {
                const updatedAgentsList = prevState.agentsList.map((agent: any) =>
                    agent.id === responseJson.data.id ? responseJson.data : agent
                );

                return {
                    agentsList: updatedAgentsList,
                    showModal: false
                };});
        }
    }

    // Customizable Area End
}
