import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import toast from "react-hot-toast";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
}

export interface S {
    // Customizable Area Start
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    otpAuthToken: string;
    reTypePassword: string;
    data: any[];
    passwordHelperText: string;
    enablePasswordField: boolean;
    enableReTypePasswordField: boolean;
    countryCodeSelected: string;
    phone: string;
    openModal: boolean;
    languages: string[]
    isLoading: boolean
    selectedLanguage: string

    //Web Signup
    otp: any;
    isOTPReceived: boolean,
    fullName: string,
    companyName: string,
    confirmPassword: string,
    workEmail: string,
    timeZone: string;
  
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class EmailAccountRegistrationControllerData extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    createAccountApiCallIdWeb: string = ""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];
        this.receive = this.receive.bind(this);

        runEngine.attachBuildingBlock(this, this.subScribedMessages);

        this.state = {
            // Customizable Area Start
        
           isLoading: false,
            phone: "",
            isOTPReceived: false,
            otp: 0,
            fullName: "",
            confirmPassword: "",
            languages: ["English", "Spanish", "French", "German", "Chinese", "Hindi", "Arabic", "Portuguese", "Russian", "Japanese"],
            selectedLanguage: "",
            companyName: "",
            timeZone: "",
            firstName: "",
            lastName: "",
            workEmail: "",
            openModal: false,
            passwordHelperText: "",
            enablePasswordField: true,
            enableReTypePasswordField: true,
            countryCodeSelected: "", email: "",
            password: "",
            reTypePassword: "",
            otpAuthToken: "",
            data: [],
           
            // Customizable Area End
        };

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        this.handleApiResponse(message);
        // Customizable Area End
    }

    // Customizable Area Start
    handleApiResponse(message: Message) {
        this.setState({isLoading:false})
        if (getName(MessageEnum.RestAPIResponceMessage) 
            === message.id) 
            {
            const apiRequestCallId = 
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJson = 
            message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            const errorResponse = 
            message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

            if (apiRequestCallId && 
                responseJson) {
               if (apiRequestCallId === 
                this.createAccountApiCallIdWeb) {
                    this.handleCreateAccountApiResponse(responseJson, errorResponse);
                } 
            }
        }
    }

    handleCreateAccountApiResponse(responseJson: any, errorResponse: any) {
      
        if (!responseJson.errors) {
            toast.success("Data saved successfully")
            this.props.navigation.navigate('Dashboard')
        } else {
            if (Array.isArray(responseJson.errors) && responseJson.errors.length > 0){
                if(responseJson.errors[0].token){
                    toast.error(responseJson.errors[0].token)
                }else toast.error(responseJson.errors[0].account)
            }
                
            else toast.error(responseJson.errors)
        }
        this.parseApiCatchErrorResponse(errorResponse);
    }

    updateDataForAccount(): boolean {
        this.setState({isLoading:true})
        const header = {
            "Content-Type": configJSON.contentTypeApiAddDetail,
            "token": window.location.href.split('token=')[1]
        };
        const httpBody = {
            setup_account: {
                password: this.state.confirmPassword,
                time_zone: this.state.timeZone,
                language: this.state.selectedLanguage,
            }
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.createAccountApiCallIdWeb = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.registrationDatApiendpoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.patchMethod
        );


        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    webbtnConfirmPasswordShowHideProps = {
        onPress: () => {
            this.setState(prevState => ({
                enableReTypePasswordField: !prevState.enableReTypePasswordField
            }));

        }
    };

    webbtnPasswordShowHideProps = {
        onPress: () => {
            this.setState(prevState => ({
                enablePasswordField: !this.state.enablePasswordField
            }));
        }
    };

    // Customizable Area End
}
