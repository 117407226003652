import React from "react";
// Customizable Area Start
import { Path1 } from "./assets"
import { FinalLogo1 } from "./assets"
import { Path2 } from "./assets"
import { Box, Button, Grid, Container, Paper, InputLabel, Link, TextField, ThemeProvider, Typography, createTheme } from "@material-ui/core";
import * as Yup from "yup";
import moment from "moment-timezone";
import { Field, Form, Formik } from "formik";
import { Path24 } from "./assets"
import { Path25 } from "./assets"

import { Path26 } from "./assets";
import { Toaster } from "react-hot-toast";
// Customizable Area End


// Customizable Area Start
const theme = createTheme({
    palette: {
        primary: {
            main: "#8760A9",
            contrastText: "#fff",
        },
    },
});

const validationSchemaSignup = Yup.object().shape({
    fullName: Yup.string().required("Full Name is required")
    .matches(/^[A-Za-z]+( [A-Za-z]+)*$/, 'Full Name should contain letters with optional spaces'),
   
   
    company: Yup.string().required("Company is required").matches(/^[A-Za-z]+( [A-Za-z]+)*$/, 'Company Name should contain letters with optional spaces'),
    email: Yup
        .string()
        .email("Invalid email format")
        .required("Work Email is required"),
});

import Loader from "../../../components/src/Loader.web";
// Customizable Area End


import EmailAccountRegistrationController, {
    Props
} from "./EmailAccountRegistrationController";



class EmailAccountRegistration extends EmailAccountRegistrationController {
    constructor(props: Props) {
        super(props);
   // Customizable Area Start
   // Customizable Area End

    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const timezones = moment.tz.names();
        const { activeStep } = this.state;
        //istanbul ignore next/
        return (

            <ThemeProvider theme={theme}>
                <Toaster/>
                  <Box style={{position:'fixed',height:'100%',width:'100%',pointerEvents:'none',overflow:'hidden',zIndex:1000}}>
                        <Loader loading={this.state.isLoading} />
                        </Box>
                <Container style={{ maxWidth: 1440, height: 1024 }}>
                    <Box sx={webStyle.boxLogoWrapper} >
                        <img src={FinalLogo1} alt="My Image" />
                    </Box>
                    {activeStep === 0 && (
                        <Box
                            sx={webStyle.signupPprBoxWrapper}
                        >
                            <Paper

                                style={webStyle.signupPaper}
                            >
                                <Box style={{ padding: "24px" }}>
                                    <Typography variant="h4" style={{ marginBottom: "18.5px" }} component="h2">
                                        Signup
                                    </Typography>
                                    <Typography style={{ marginBottom: "18.5px" }} variant="subtitle1" component="div">
                                        {/* {this.state.labelHeader} UI Engine::From Sketch */}
                                        During your free 21-day trial period, your account will be on the highest trial plan - Enterprise (Trial) and you will have access to all of the features and capabilities of Ganga
                                    </Typography>
                                    <Formik
                                        data-test-id="formikClickEvent"
                                        initialValues={{
                                            fullName: this.state.fullName || '',
                                            email: this.state.email || '',
                                            phone_number: this.state.phone || '',
                                            company: this.state.companyName || '',
                                        }}
                                        validationSchema={validationSchemaSignup}
                                        onSubmit={(values, actions) => {
                                            console.log('Form submitted with values:', {
                                                fullName: values.fullName,
                                                workEmail: values.email,
                                                phone: values.phone_number,
                                                companyName: values.company,
                                            });
                                            this.setState({
                                                fullName: values.fullName,
                                                workEmail: values.email,
                                                phone: values.phone_number,
                                                companyName: values.company,
                                            });
                                            this.webcreateAccount()
                                        }}
                                    >
                                        {({ errors, touched }) => (
                                            <Form translate={undefined}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={6}>
                                                        <Box>
                                                            <InputLabel style={{ marginBottom: '5px' }} htmlFor="fullName">
                                                                Full Name<span style={{ color: 'red' }}>*</span>
                                                            </InputLabel>
                                                            <Field
                                                                testID="textInputFullName"
                                                                type="text"
                                                                name="fullName"
                                                                id="fullName"
                                                                as={TextField}
                                                                fullWidth
                                                                variant="outlined"
                                                                error={touched.fullName && !!errors.fullName}
                                                                helperText={touched.fullName && errors.fullName}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Box>
                                                            <InputLabel style={{ marginBottom: '5px' }} htmlFor="email">
                                                                Work Email<span style={{ color: 'red' }}>*</span>
                                                            </InputLabel>
                                                            <Field
                                                                testID="textInputEmail"
                                                                type="email"
                                                                name="email"
                                                                id="email"
                                                                as={TextField}
                                                                fullWidth
                                                                variant="outlined"
                                                                error={touched.email && !!errors.email}
                                                                helperText={touched.email && errors.email}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Box>
                                                            <InputLabel style={{ marginBottom: '5px' }} htmlFor="company">
                                                                Company<span style={{ color: 'red' }}>*</span>
                                                            </InputLabel>
                                                            <Field
                                                                testID="txtInputCompanyName"
                                                                type="text"
                                                                name="company"
                                                                id="company"
                                                                as={TextField}
                                                                fullWidth
                                                                variant="outlined"
                                                                error={touched.company && !!errors.company}
                                                                helperText={touched.company && errors.company}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Box>
                                                            <InputLabel style={{ marginBottom: '5px' }} htmlFor="phone_number">
                                                                Phone number
                                                            </InputLabel>
                                                            <Field
                                                                testID="textPhoneNumber"
                                                                type="text"
                                                                name="phone_number"
                                                                id="phone_number"
                                                                as={TextField}
                                                                fullWidth
                                                                variant="outlined"
                                                            />
                                                        </Box>
                                                    </Grid>
                                                </Grid>

                                                <Typography style={{ marginTop: '60px' }}>
                                                    Already have an account?{' '}
                                                    <Link
                                                        data-test-id="navigateToLogin"
                                                        onClick={this.redirectLogin}
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                    >
                                                        Login
                                                    </Link>
                                                </Typography>
                                                <Box sx={buttonContainerStyle}>
                                                    <Button
                                                        data-test-id="btnEmailLogIn"
                                                        variant="contained"
                                                        color="primary"
                                                        type="submit"
                                                    >
                                                        Sign up
                                                    </Button>
                                                </Box>
                                            </Form>
                                        )}
                                    </Formik>
                                </Box>
                            </Paper>
                        </Box>
                    )}
              
                    <Box sx={webStyle.footerMainWrapperSignup}  >
                        <Box style={{ display: "flex", }} >
                            <Box sx={webStyle.path2StyleSignup}  >
                                <img src={Path2} alt="Path2" />
                            </Box>
                            <Box sx={webStyle.path1StyleSignup} >
                                <img src={Path1} alt="Path1" />
                            </Box>
                        </Box>
                        <Box style={{ display: "flex", }}>
                            <Box sx={webStyle.path24StyleSignup} >
                                <img src={Path24} alt="Path24" />
                            </Box >
                            <Box sx={webStyle.path25StyleSignup} >
                                <img src={Path25} alt="Path23" />
                            </Box>
                            <Box sx={webStyle.path24StyleSignup}>
                                <img src={Path26} alt="Path26" />
                            </Box>
                        </Box>
                    </Box>

                </Container >
            </ThemeProvider >
        );
        // Customizable Area End
    }


}

export default EmailAccountRegistration;


// Customizable Area Start

const webStyle = {
    pprBoxWrapper: {
        height: 440,
        flexShrink: 0,
        position: "relative",
        top: "252px",
        left: "470px",
        bottom: "400px",

    },
    signupPprBoxWrapper: {
        flexShrink: 0,
        position: "relative",
        top: "252px",
        left: "220px",
        bottom: "178px",
    },
    signupPaper: {
        width: 1000,

        // padding: 20,
        borderRadius: "var(--1, 8px)",
        background: "var(--basic-white, #FFF)",
        boxShadow:
            "0px 8px 32px 0px rgba(135, 96, 169, 0.16), 0px 4px 8px 0px rgba(135, 96, 169, 0.13)",

    },
    verfifyOTPPpr: {
        width: 500,

        // padding: 20,
        borderRadius: "var(--1, 8px)",
        background: "var(--basic-white, #FFF)",
        boxShadow:
            "0px 8px 32px 0px rgba(135, 96, 169, 0.16), 0px 4px 8px 0px rgba(135, 96, 169, 0.13)",

    },
    boxLogoWrapper: {
        position: "absolute",
        left: "120px",
        right: "1193px",
        bottom: "920px",
        top: "52px",
    },
    footerMainWrapperSignup: {
        display: "flex", justifyContent: "space-between"
    },

    path24StyleSignup: {
        position: "absolute", zIndex: 1, bottom: 0, right: 0
    },
    path25StyleSignup: {
        position: "absolute", zIndex: 1, bottom: 0, right: "45px"
    },
    path2StyleSignup: {
        position: "absolute", zIndex: 1, bottom: 0
    },
    path1StyleSignup: {
        position: "absolute", zIndex: 1, bottom: 0, left: 0
    },
}

const buttonContainerStyle = {
    display: "flex",
    // width: 452,
    justifyContent: "flex-end",
    alignItems: "center",
    gap: 12,
    borderRadius: "0px 0px var(--1, 8px) var(--1, 8px)",
    background: "var(--basic-white, #FFF)",
    padding: "10px 0px",
};
// Customizable Area End
