import React from "react";
// Customizable Area Start
import { Box, Button, Collapse, Dialog, Grid, Input, InputLabel, List, ListItem, ListItemText, TextField, Typography, styled } from "@material-ui/core";
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  MoreVert,
  Search,
} from "@material-ui/icons";
import { cancelIcon } from "./assets";
import NavigationSideBar from "../../../components/src/NavigationSideBar";
import Loader from "../../../components/src/Loader.web";
import CustomisableusersubscriptionsController, {
  Props,
  NavigationItem,
  configJSON
} from "./CustomisableusersubscriptionsController";
import { Field, Form, Formik } from "formik";
import { Toaster } from 'react-hot-toast';
// Customizable Area End

export default class Customisableusersubscriptions2 extends CustomisableusersubscriptionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderSkipBreadcrumbs = () => {
    return (
      <Box style={styles.breadcrumb_wrapper}>
        <span style={styles.navigation}>
          {configJSON.settingLabel} {<KeyboardArrowRight />} {configJSON.accountSettingLabel}
          {<KeyboardArrowRight />} {configJSON.accountLabel}
        </span>
        <Box style={styles.morevert_wrapper}>
          <span style={styles.morevert_span}>
            <MoreVert style={styles.morevert_icon} />
          </span>
        </Box>
      </Box>
    );
  };

  renderNextPrevButton = () => {
    return (
      <Box style={styles.arrow_wrapper}>
        <Box style={styles.arrow_btn_wrapper}>
          <button style={styles.arrow}>
            <KeyboardArrowLeft style={styles.arrow_icon} />
          </button>
          <button style={styles.arrow}>
            <KeyboardArrowRight style={styles.arrow_icon} />
          </button>
        </Box>
        <Box style={styles.label_heading}>
          <h1 style={styles.heading}>{configJSON.accountLabel}</h1>
        </Box>
      </Box>
    );
  };

  renderSearchBar = () => {
    return (
      <Box style={styles.search_wrapper}>
        <Search style={styles.search_icon} />
        <Input
          disableUnderline={true}
          style={styles.input}
          placeholder={configJSON.searchPlacholder}
          data-test-id="searchInput"
        />
      </Box>
    );
  };

  renderSettingList = (link: NavigationItem, index: number) => {
    return (
      <List key={`key-${link.title}-${index}`} disablePadding>
        <ListItem
          button
          style={{
            ...styles.navigation_item_nested,
            ...(this.pathname === link.href && styles.navigation_item_active) 
          }}
          href={link.href}
          component="a"
        >
          <ListItemText
            disableTypography={true}
            primary={link.title}
            style={{
              ...styles.navigation_link,
              ...(this.pathname === link.href && styles.active_link)              
            }}
          />
        </ListItem>
      </List>
    );
  };

  contextMenuSetting = () => {
    return (
      <Box style={styles.accordians}>
        <List component="nav">
          {this.navigationList.map((item, index: number) => {
            if (item.list && item.list.length > 0) {
              return (
                <>
                  <ListItem
                    button
                    data-test-id="openAccordian-user"
                    style={styles.navigation_item}
                    key={`key-${item.title}-${index}`}
                    onClick={() => this.openAccordian(index)}
                  >
                    <ListItemText
                      style={styles.navigation_link}
                      primary={item.title}
                      disableTypography={true}
                    />
                  </ListItem>
                  <Collapse
                    timeout="auto"
                    unmountOnExit
                    in={this.state?.openListIndex === index}
                  >
                    {item.list.map((link, index: number) =>
                      this.renderSettingList(link, index)
                    )}
                  </Collapse>
                </>
              );
            }
            return (
              <ListItem button key={item.title} style={styles.navigation_item}>
                <ListItemText
                  style={styles.navigation_link}
                  disableTypography={true}
                  primary={item.title}
                />
              </ListItem>
            );
          })}
        </List>
      </Box>
    );
  };

  renderGeneralDetails = () => {
    const { accountDetails } = this.state;
    return (
      <Box className="generalLayout">
        <Box className="generalInnerContainer">
          <Box className="generalHeadingContainer">
            <Typography className="generalTextStyle">{configJSON.generalLabel}</Typography>
          </Box>
          <Box className="generalContentContainer">
            <Box className="generalLeftContent">
              <Box>
                <Typography className="lightTextStyle">
                  {configJSON.currentPlan}
                </Typography>
                <Typography className="darkTextStyle">
                  {this.handleText(accountDetails.attributes.current_plan)}
                </Typography>
              </Box>
              <Box>
                <Typography className="lightTextStyle">
                  {configJSON.accountName}
                </Typography>
                <Typography className="darkTextStyle">
                  {this.handleText(accountDetails.attributes.company_name)}
                </Typography>
              </Box>
              <Box>
                <Typography className="lightTextStyle">
                  {configJSON.activeSince}
                </Typography>
                <Typography className="darkTextStyle">
                  {accountDetails.attributes.created_at || "-"}
                </Typography>
              </Box>
              <Box>
                <Typography className="lightTextStyle">{configJSON.assets}</Typography>
                <Typography className="darkTextStyle">
                  {configJSON.assetsCount}
                </Typography>
              </Box>
            </Box>
            <Box className="generalRightContent">
              <Box>
                <Typography className="lightTextStyle">{configJSON.agents}</Typography>
                <Typography className="darkTextStyle">{this.state.accountDetails.attributes.agent || "0"}</Typography>
              </Box>
              <Box>
                <Typography className="lightTextStyle">
                  {configJSON.dataCenter}
                </Typography>
                <Typography className="darkTextStyle">{configJSON.dataCenterCount}</Typography>
              </Box>
              <Box>
                <Typography className="lightTextStyle">
                  {configJSON.version}
                </Typography>
                <Typography className="darkTextStyle">
                  {configJSON.versionNumber}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    )
  };

  renderContactDetails = () => {
    const { accountDetails } = this.state;
    return (
      <Box className="contactDetailLayout">
        <Box className="contactInnerContainer">
          <Box className="generalHeadingContainer">
            <Typography className="generalTextStyle">
              {configJSON.contactDetailsLabel}
            </Typography>
          </Box>
          <Box className="generalContentContainer">
            <Box className="generalLeftContent">
              <Box>
                <Typography className="primaryContactText">
                  {configJSON.primaryContactDetailsLabel}
                </Typography>
              </Box>
              <Box>
                <Typography className="lightTextStyle">
                  {configJSON.fullName}
                </Typography>
                <Typography className="darkTextStyle">
                  {this.handleText(accountDetails.attributes.full_name)}
                </Typography>
              </Box>
              <Box>
                <Typography className="lightTextStyle">{configJSON.email}</Typography>
                <Typography className="darkTextStyle">
                {this.handleText(accountDetails.attributes.email)}
                </Typography>
              </Box>
              <Box>
                <Typography className="lightTextStyle">
                  {configJSON.phoneNumber}
                </Typography>
                <Typography className="darkTextStyle">
                {this.handleText(accountDetails.attributes.full_phone_number)}
                </Typography>
              </Box>
            </Box>
            <Box className="generalRightContent">
              <Box>
                <Typography className="primaryContactText">
                  {configJSON.invoiceLabel}
                </Typography>
              </Box>
              <Box>
                <Typography className="lightTextStyle">{configJSON.email}</Typography>
                <Typography className="darkTextStyle">
                {this.handleText(accountDetails.attributes.email)}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className="exitBtnContainer" onClick={this.handleDialogOpen} data-test-id="editBtn">
          <Button className="editButtonContainer" >{configJSON.editBtnText}</Button>
        </Box>
      </Box>
    )
  };

  renderOtherDetails = () => {
    return (
      <Box className="otherDetailLayout">
        <Box className="otherInnerContainer">
          <Box className="generalHeadingContainer">
            <Typography className="generalTextStyle">
              {configJSON.otherDetailsLabel}
            </Typography>
          </Box>
          <Box>
            <Box className="supportContainer">
              <Box>
                <Typography className="supportText">{configJSON.support}</Typography>
                <Typography className="haveIsueTxt">
                  {configJSON.haveInIssue}
                </Typography>
              </Box>
              <Box>
                <Button className="talkToSupportBtn">
                  {configJSON.talkToSupport}
                </Button>
              </Box>
            </Box>
            <Box className="exportDataContainer">
              <Box className="exportTextContainer">
                <Typography className="exportText">
                  {configJSON.exportData}
                </Typography>
                <Typography className="exportContentTxt">
                  {configJSON.exportDataDetailText}
                </Typography>
              </Box>
              <Box className="exportBtnContainer">
                <Button className="exportBtn">{configJSON.exportNowBtn}</Button>
              </Box>
            </Box>
            <Box className="exportDataContainer">
              <Box className="exportTextContainer">
                <Typography className="exportText">
                  {configJSON.rightToRestrictProcessing}
                </Typography>
                <Typography className="exportContentTxt">
                  {configJSON.rightToRestrictProcessingTxt}
                </Typography>
              </Box>
              <Box className="exportBtnContainer">
                <Button className="exportBtn">{configJSON.optOut}</Button>
              </Box>
            </Box>
            <Box className="exportDataContainer">
              <Box className="exportTextContainer">
                <Typography className="exportText">
                  {configJSON.activeSince}
                </Typography>
                <Typography className="exportContentTxt">
                  {this.state.accountDetails.attributes.created_at || "-"}
                </Typography>
              </Box>
              <Box className="optBtnContainer">
                <Button className="cancelBtn">{configJSON.cancelBtn}</Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    )
  };

  renderAccountSettingDetails = () => {
    return (
      <AccountContainer>
        <Box className="allLayoutContainer">
          {this.renderGeneralDetails()}
          {this.renderContactDetails()}
          {this.renderOtherDetails()}
        </Box>
        <Box className="contextSettingContainer">
          {this.contextMenuSetting()}
        </Box>
      </AccountContainer>
    )
  }

  renderEditDialog = () => {
    const { accountDetails } = this.state;
    return (
      <Dialog open={this.state.showDialog} fullWidth={true} maxWidth="md" onClose={this.handleDialogClose} data-test-id="edit-dialog">
        <Box p={"1rem"}>
          <Box display={"flex"} justifyContent={"space-between"} width={"100%"}>
            <Typography style={styles.table_heading}>{configJSON.editContactDetails}</Typography>
            <span style={styles.cancelIconSpan} onClick={this.handleDialogClose} data-test-id="cancelIcon">
              <img src={cancelIcon} alt={configJSON.cancelIconAltText} />
            </span>
          </Box>
          <Formik
            initialValues={{email:accountDetails.attributes.email, full_name:accountDetails.attributes.full_name, full_phone_number:accountDetails.attributes.full_phone_number}}
            onSubmit={this.updateAccountDetails}
            validationSchema={this.getValidationSchema()}
            data-test-id="editFormik"
          >
            {({ errors, touched ,values, setFieldValue}) => (
              <Form translate={undefined} style={styles.form_main_box}>
                  <Grid container spacing={4}>
                    <Grid item xs={12} sm={12} md={6}>
                      <Typography style={styles.common_space} variant={"h6"}>{configJSON.primaryDetailsLabel}</Typography>
                      <Box style={styles.common_space}>
                        <InputLabel
                          style={styles.input_label}
                          htmlFor={configJSON.nameInputId}
                        >
                          {configJSON.fullNameLabel + " "}
                          <span style={styles.span_color}>*</span>
                        </InputLabel>
                        <Field
                          type="text"
                          name="full_name"
                          id="name"
                          as={TextField}
                          fullWidth
                          variant="outlined"
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {this.handleFieldChange(event, setFieldValue, "full_name", true)}}
                          error={touched.full_name && !!errors.full_name}
                          helperText={touched.full_name && errors.full_name}
                          required
                        />
                      </Box>
                      <Box style={styles.common_space}>
                        <InputLabel
                          style={styles.input_label}
                          htmlFor={configJSON.emailInputId}
                        >
                          {configJSON.emailLabel + " "}
                          <span style={styles.span_color}>*</span>
                        </InputLabel>
                        <Field
                          type="email"
                          name="email"
                          id="email"
                          as={TextField}
                          fullWidth
                          variant="outlined"
                          error={touched.email && !!errors.email}
                          helperText={touched.email && errors.email}
                          required
                        ></Field>
                      </Box>
                      <Box style={styles.common_space}>
                      <InputLabel
                          style={styles.input_label}
                          htmlFor={configJSON.phoneInputId}
                        >
                          {configJSON.phoneLabel + " "}
                          <span style={styles.span_color}>*</span>
                        </InputLabel>
                        <Field
                          type="tel"
                          name="full_phone_number"
                          id="phone"
                          as={TextField}
                          fullWidth
                          variant="outlined"
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleFieldChange(event, setFieldValue, "full_phone_number", false)}
                          error={touched.full_phone_number && !!errors.full_phone_number}
                          helperText={touched.full_phone_number && errors.full_phone_number}
                          required
                        ></Field>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <Typography
                        variant={"h6"}
                        style={styles.common_space}
                      >
                        {configJSON.sendInvoiceLabel}
                      </Typography>
                      <Box>
                      <InputLabel
                          style={styles.input_label}
                          htmlFor={configJSON.invoiceEmailInputId}
                        >
                          {configJSON.emailLabel + " "}
                        </InputLabel>
                        <Field
                          type="email"
                          name="invoiceEmail"
                          id="invoiceEmail"
                          as={TextField}
                          fullWidth
                          value={values.email}
                          variant="outlined"
                          readOnly
                        ></Field>
                      </Box>
                    </Grid>
                  </Grid>
                  <Box mt={"30px"} style={styles.modal_button_main_box}>
                    <Button
                      data-test-id="modal_Cancel_Btn"
                      type="reset"
                      style={styles.cancel_button}
                      onClick={this.handleDialogClose}
                    >
                      {configJSON.modalCancel}
                    </Button>
                    <Button type="submit" style={styles.add_button}>
                      {configJSON.saveChanges}
                    </Button>
                  </Box>
                </Form>
            )}
          </Formik>
        </Box>
      </Dialog>
    )
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <>
        <NavigationSideBar active={'Settings'} navigation={this.props.navigation} />
        <Toaster />
        <Loader loading={this.state.isLoading} />
        <Box style={styles.container}>
          {this.renderSkipBreadcrumbs()}
          {this.renderNextPrevButton()}
          {this.renderSearchBar()}
          {this.renderAccountSettingDetails()}
          {this.renderEditDialog()}
        </Box>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const AccountContainer = styled(Box)({
  display: "flex",
  gap: "10px",
  "& .generalLayout": {
    display: "flex",
    padding: "16px",
    width: "800px",
    boxShadow: "0px 8px 32px 0px rgba(135, 96, 169, 0.16), 0px 4px 8px 0px rgba(135, 96, 169, 0.13)",
    borderRadius: "8px",
    "@media(max-width:1400px)": {
      width: "600px"
    },
    "@media(max-width:1200px)": {
      width: "400px"
    },
    "@media(max-width: 900px)": {
      width: "275px"
    },
  },
  "& .generalInnerContainer": {
    width: "600px",
    display: "flex",
    gap: "30px",
    flexDirection: "column"
  },
  "& .generalTextStyle": {
    fontSize: "20px",
    fontFamily: "Manrope",
    fontWeight: 700,
    fontStyle: "normal",
    "@media(max-width:1200px)": {
      fontSize: "18px"
    },
  },
  "& .generalContentContainer": {
    display: "flex",
    justifyContent: "space-between"
  },
  "& .generalLeftContent": {
    display: "flex",
    flexDirection: "column",
    gap: "10px"
  },
  "& .generalRightContent": {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    width: "200px",
    "@media(max-width: 900px)": {
      width: "100px"
    },
  },
  "& .lightTextStyle": {
    fontSize: "14px",
    fontFamily: "Manrope",
    fontWeight: 600,
    color: "#A3A6C2",
    fontStyle: "normal",
    "@media(max-width:1200px)": {
      fontSize: "12px"
    },
  },
  "& .darkTextStyle": {
    fontSize: "14px",
    fontFamily: "Manrope",
    fontWeight: 700,
    color: "#33354D",
    fontStyle: "normal",
    "@media(max-width:1200px)": {
      fontSize: "12px"
    },
  },
  "& .contactDetailLayout": {
    padding: "16px",
    width: "800px",
    boxShadow:
      " 0px 8px 32px 0px rgba(135, 96, 169, 0.16), 0px 4px 8px 0px rgba(135, 96, 169, 0.13)",
    borderRadius: "8px",
    marginTop: "12px",
    "@media(max-width:1400px)": {
      width: "600px"
    },
    "@media(max-width:1200px)": {
      width: "400px"
    },
    "@media(max-width: 900px)": {
      width: "275px"
    },
  },
  "& .contactInnerContainer": {
    width: "600px",
    display: "flex",
    gap: "30px",
    flexDirection: "column",
    "@media(max-width:1200px)": {
      width: "400px"
    },
    "@media(max-width: 900px)": {
      width: "250px"
    },
  },
  "& .primaryContactText": {
    color: "#33354D",
    fontFamily: "Manrope",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 700,
    "@media(max-width:1200px)": {
      fontSize: "12px"
    },
  },
  "& .editButtonContainer": {
    width: "30px",
    padding: "10px 16px",
    gap: "8px",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "4px",
    backgroundColor: "#E6DEED",
    display: "flex",
    textTransform: "inherit",
    color: "#33354D",
    fontFamily: "Manrope",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    marginTop: "10px",
    "@media(max-width:1200px)": {
      fontSize: "12px"
    },
  },
  "& .exitBtnContainer": {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end"
  },
  "& .otherDetailLayout": {
    padding: "16px",
    width: "800px",
    boxShadow:
      " 0px 8px 32px 0px rgba(135, 96, 169, 0.16), 0px 4px 8px 0px rgba(135, 96, 169, 0.13)",
    borderRadius: "8px",
    marginTop: "12px",
    gap: "32px",
    "@media(max-width:1400px)": {
      width: "600px"
    },
    "@media(max-width:1200px)": {
      width: "400px"
    },
    "@media(max-width: 900px)": {
      width: "275px"
    },
  },
  "& .otherInnerContainer": {
    display: "flex",
    gap: "30px",
    flexDirection: "column"
  },
  "& .supportContainer": {
    display: "flex",
    width: "95%",
    justifyContent: "space-between",
    padding: "12px",
    alignItems: "flex-start",
    borderRadius: "8px",
    backgroundColor: "#F9F6FB"
  },
  "& .talkToSupportBtn": {
    backgroundColor: "#fff",
    borderRadius: "4px",
    padding: "10px 16px",
    textTransform: "inherit",
    color: "#8760A9",
    fontSize: "16px",
    fontWeight: 500,
    fontFamily: "Manrope",
    "@media(max-width: 900px)": {
      fontSize: "12px",
      padding: "5px 8px"
    },
  },
  "& .supportText": {
    color: "#33354D",
    fontFamily: "Manrope",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 700,
    "@media(max-width:1200px)": {
      fontSize: "16px"
    },
  },
  "& .haveIsueTxt": {
    color: "#33354D",
    fontFamily: "Manrope",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "140%"
  },
  "& .exportDataContainer": {
    display: "flex",
    width: "95%",
    padding: "12px",
    alignItems: "flex-start",
    gap: "4px",
    borderRadius: "8px",
    border: "1px solid #E6DEED",
    backgroundColor: "#fff",
    marginTop: "10px",
    "@media(max-width:900px)": {
      flexDirection: "column"
    },
  },
  "& .exportContentTxt": {
    color: "#33354D",
    fontFamily: "Manrope",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    "@media(max-width:1200px)": {
      fontSize: "12px"
    },
    "@media(max-width:900px)": {
      fontSize: "16px"
    },
  },
  "& .exportTextContainer": {
    display: "flex",
    flexDirection: "column",
    justifySelf: "center",
    alignItems: "flex-start",
    alignSelf: "stretch",
    width: "60%",
    "@media(max-width:900px)": {
      width: "100%"
    },
  },
  "& .exportText": {
    color: "#33354D",
    fontFamily: "Manrope",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 700,
    "@media(max-width:1200px)": {
      fontSize: "16px"
    },
  },
  "& .exportBtnContainer": {
    display: "flex",
    width: "40%",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    gap: "24px",
    alignSelf: "stretch"
  },
  "& .exportBtn": {
    display: "flex",
    width: "180px",
    height: "36px",
    alignItems: "center",
    gap: "8px",
    justifyContent: "center",
    borderRadius: "4px",
    background: "#E6DEED",
    color: " #33354D",
    fontFamily: "Manrope",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: 700,
    textTransform: "inherit",
    "@media(max-width:1200px)": {
      width: "122px",
      fontWeight: 600,
      fontSize: "12px"
    },
    "@media(max-width: 900px)": {
      fontSize: "12px",
      padding: "2px 2px",
      width: "100px",
      marginRight: "4px"
    },
  },
  "& .cancelBtn": {
    display: "flex",
    width: "180px",
    height: "36px",
    alignItems: "center",
    gap: "8px",
    justifyContent: "center",
    borderRadius: "4px",
    background: "#E6DEED",
    color: " #33354D",
    fontFamily: "Manrope",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: 700,
    textTransform: "inherit",
    "@media(max-width:1200px)": {
      fontWeight: 600,
      fontSize: "12px"
    },
  },
  "& .optBtnContainer": {
    display: "flex",
    width: "300px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-end",
    gap: "24px",
    alignSelf: "stretch",
    "@media(max-width:900px)": {
      alignItems: "flex-start",
      marginLeft: "2px"
    },
  },
  "& .contextSettingContainer": {
    display: "flex",
    width: "30%",
    padding: "4px",
    flexDirection: "column",
    gap: "24px",
    alignSelf: "stretch",
    height: "400px"
  },
});


const styles: Record<string, React.CSSProperties> = {
  container: {
    height: "100%",
    margin: "15px 15px 15px 300px",
    paddingBottom: "25px",
    fontFamily: "Manrope"
  },

  breadcrumb_wrapper: {
    display: "flex",
    justifyContent: "space-between"
  },

  navigation: {
    fontFamily: "Manrope",
    fontSize: "14px",
    lineHeight: "19px",
    display: "flex",
    alignItems: "center"
  },

  morevert_wrapper: {
    background: "#e6deed",
    borderRadius: "5px"
  },

  morevert_span: {
    width: "36px",
    height: "36px"
  },

  morevert_icon: {
    width: "24px",
    height: "24px",
    color: "black",
    padding: "5px"
  },

  arrow_wrapper: {
    marginTop: "30px",
    marginBottom: "30px"
  },

  arrow_btn_wrapper: {
    display: "flex",
    gap: "5px"
  },

  arrow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "28px",
    borderRadius: "100px",
    height: "28px",
    border: "1px solid #E6DEED",
    cursor: "pointer",
    backgroundColor: "white"
  },

  arrow_icon: {
    width: "20px",
    color: "#33354D",
    height: "20px"
  },

  label_heading: {
    display: "flex",
    alignItems: "center",
    gap: "5px"
  },

  heading: {
    color: "#33354D",
    fontSize: "30px",
    marginTop: "10px",
    lineHeight: "40px",
    fontWeight: "bold"
  },

  search_wrapper: {
    marginBottom: "32px",
    position: "relative"
  },

  search_icon: {
    height: "24px",
    width: "24px",
    position: "absolute",
    color: "#33354D",
    top: "50%",
    left: "10px",
    transform: "translateY(-50%)"
  },

  input: {
    fontFamily: "Manrope",
    width: "100%",
    height: "40px",
    border: "1px solid #e6deed",
    padding: "10px 10px 10px 40px",
    fontSize: "16px",
    borderRadius: "10px"
  },

  accordians: {
    boxShadow: "0px 4px 8px 0px #8760A921",
    flexBasis: "30%",
    padding: "4px",
    borderRadius: "8px",
  },

  navigation_item: {
    borderRadius: "4px",
    padding: "9px, 20px, 9px, 16px"
  },

  navigation_link: {
    fontSize: "14px",
    fontWeight: "bold",
    fontFamily: "Manrope",
    letterSpacing: "0em",
    lineHeight: "22px",
    color: "#33354D"
  },

  navigation_item_active: {
    color: "white",
    backgroundColor: "#8760A9"
  },

  navigation_item_nested: {
    borderRadius: "4px",
    padding: "9px 20px 9px 30px"
  },

  active_link: {
    color: "white"
  },

  table_heading: {
    color: "#33354D",
    fontStyle: "normal",
    fontFamily: "Manrope",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "normal",
    marginBottom: "30px"
  },
  
  cancelIconSpan: {
    height: "30px",
    width: "30px",
    cursor: "pointer"
  },
  
  input_label: {
    marginBottom: "5px"
  },
  
  common_space:{
    marginBottom: "20px"
  },

  span_color: {
    color: "red"
  },
  
  modal_button_main_box: {
    gap: "1rem",
    float: "right",
    display: "flex"
  },
  
  cancel_button: {
    padding: "10px 16px",
    fontSize: "16px",
    fontFamily: "Manrope",
    backgroundColor: "transparent",
    fontWeight: 600,
    color: "#8760A9",
    lineHeight: "16px",
    letterSpacing: "0em",
    borderRadius: "4px",
    textTransform: "initial"
  },
  
  add_button: {
    padding: "10px 16px",
    backgroundColor: "#8760A9",
    fontSize: "16px",
    color: "#FFF",
    fontWeight: 600,
    fontFamily: "Manrope",
    lineHeight: "16px",
    letterSpacing: "0em",
    borderRadius: "4px",
    textTransform: "initial"
  }

};
// Customizable Area End