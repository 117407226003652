import React from "react";
import {
  Box,
  Grid,
  Input,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { ReactSVG } from 'react-svg';
import { MoreVert, Search } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import { slaolaIcons } from "./assets";
const Agents = require('../assets/Agents.svg') as string;
const Roles = require('../assets/Roles.svg') as string;
const Departments = require('../assets/Departments.svg') as string;
const DepartmentFieds = require('../assets/DepartmentFieds.svg') as string;
const Requesters = require('../assets/Requesters.svg') as string;
const UserFields = require('../assets/UserFields.svg') as string;
const CAB = require('../assets/CAB.svg') as string;
const AgentsGroup = require('../assets/AgentsGroup.svg') as string;
const RequesterGroup = require('../assets/RequesterGroup.svg') as string;
const Account = require('../assets/Account.svg') as string;
const PlanAndBiling = require('../assets/PlanAndBiling.svg') as string;
const ServiceDeskRebranding = require('../assets/ServiceDeskRebranding.svg') as string;
const SLAandOLA = require('../assets/Vector.svg') as string;
const EmailNotificatiion = require('../assets/EmailNotificatiion.svg') as string;



const listData = [
  'Account settings',
  'User management',
  '', // Place for subItems
  'Channels',
  'Service management',
  '2',
  'Asset management',
]
const subListData = [
  { 
    title: 'Agents', 
    href: 'Agents'
  },
  { 
    title: 'Roles', 
    href: 'Rolesandpermissions' 
  },
  { 
    title: 'Categories', 
    href: 'Categories' 
  },
  { 
    title: 'Form Template', 
    href: 'FormTemplates' 
  },
  { 
    title: 'Departments', 
    href: 'Departments' 
  },
  {
    title: 'Department Fields',
    href: 'DepartmentFields',
  },
  { 
    title: 'Requesters', 
    href: 'Requesters' 
  },
  {
     title: 'User Fields', 
     href: 'UserFields'
  },
  { 
    title: 'CAB', 
    href: 'Cab' 
  },
  { 
    title: 'Agent groups', 
    href: 'AgentGroup' 
  },
 
]
const subListData2 = [
  {
    titles:'SLA and OLA Policies',
    hrefs:'ServiceManagement'
  }
]
const accountSettingData = [{
  icon: Account,
  title: "Account",
  pOne:  "Manage account information",
  pTwo: "and related settings",
  href: "Account"
},
{
  icon: PlanAndBiling,
  title: "Plans & Billing",
  pOne: "Manage plans, subscriptions and",
  pTwo: "licenses",
},
{
  icon: ServiceDeskRebranding,
  title: "Service Desk Rebranding",
  pOne: "Customize agent and requester",
  pTwo: "portals to your brand needs",
},
{
  icon: EmailNotificatiion,
  title: "Email Notification",
  pOne: "Manage account information and",
  pTwo: "related settings",
},
];
const serviceManages = [{
  icons:slaolaIcons,
  titles: "SLA and OLA Policies",
  pOnes:  "Define service agreements ",
  pTwos: "with internal and external stakeholders",
  hrefs: "ServiceManagement"
}]
const gridData = [{
  icon: Agents,
  title: 'Agents',
  pOne: 'Manage all service desk agents',
  pTwo: 'permission and acces rights',
  href: 'Agents'
},
{
  icon: Roles,
  title: 'Roles',
  pOne: 'Create and modify roles to manage ',
  pTwo: 'agent permissions',
  href: 'Rolesandpermissions'
},
{
  icon: Roles,
  title: 'Categories',
  pOne: 'Create and modify roles to manage ',
  pTwo: 'agent permissions',
  href: 'Categories'
},
{
  icon: Roles,
  title: 'Form Template',
  pOne: 'Create and modify roles to manage ',
  pTwo: 'agent permissions',
  href: 'FormTemplates'
},
{
  icon: Departments,
  title: 'Departments',
  pOne: 'Manage departments and their',
  pTwo: 'associated members',
  href: 'Departments'
},
{
  icon: DepartmentFieds,
  title: 'Department Fields',
  pOne: 'Create and manage fields that',
  pTwo: 'Capture info for departments',
  href : 'DepartmentFields'
},
{
  icon: Requesters,
  title: 'Requesters',
  pOne: 'Manage requesters and their',
  pTwo: 'information',
  href: 'Requesters'
},
{
  icon: UserFields,
  title: 'User Fields',
  pOne: 'Customize agent and requester',
  pTwo: 'portais to your brand needs',
  href: 'UserFields'
},
{
  icon: CAB,
  title: 'CAB',
  pOne: 'Switch between Employee Support',
  pTwo: 'Mode and Managed Services Mode',
  href: 'CAB'
},
{
  icon: AgentsGroup,
  title: 'Agents Group',
  pOne: 'Manage plans, subscriptions and',
  pTwo: 'licenses',
  href: "AgentGroup"
},
]


const StyledGridItem = withStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    lineHeight: '5px',
    alignItems: "center",
    width: "100%",
    height: "192px",
    background: "white",
    borderRadius: "20px",
    border: '1px solid #e6deed',
    cursor: 'pointer',
    transition: '.3s ease-out all',
    "&:hover": {
      background: '#f9f5fb'
    }
  }
})(Grid)

const StyledGridItemSecond = withStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    lineHeight: '5px',
    alignItems: "center",
    width: "100%",
    height: "192px",
    background: "white",
    borderRadius: "20px",
    border: '1px solid #e6deed',
    cursor: 'pointer',
    transition: '.3s ease-out all',
    "&:hover": {
      background: '#f9f5fb'
    }
  }
})(Grid)
const StyledListItem = withStyles({
  root: {
    borderRadius: '4px',
    padding: '8px 0px 8px 10px',
    cursor: 'pointer',
    transition: '.3s ease-out all',
    "&:hover": {
      borderRadius: '8px',
      background: '#8760a9',
      color: 'white'
    }
  },
})(Box)
// Customizable Area End

import Settings2Controller, {
  Props,
  configJSON,
} from "./Settings2Controller";
import NavigationSideBar from "../../../components/src/NavigationSideBar";

export default class Settings2 extends Settings2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <NavigationSideBar active={'Settings'} navigation={this.props.navigation} />
        <div style={webStyles.container}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <span style={{ fontFamily: 'Manrope',color:"#3D405C" ,fontWeight:400, fontSize: '14px', lineHeight: '19.12px'}}>Settings </span>
            </div>
            <div style={{ background: '#e6deed', borderRadius: '5px' }}>
              <span style={{ width: '36px', height: '36px', }}>
                <MoreVert style={{ width: '24px', height: '24px', color: 'black', padding: '5px' }} />
              </span>
            </div>
          </div>
          <h2 style={{ fontFamily: 'Manrope', fontSize: '30px',fontWeight:600, lineHeight: '40.98px',color:"#33354D", marginTop: "60px", marginBottom: "50px" }}>
            Settings
          </h2>
          <div style={{ position: 'relative' }}>
            <Search style={{ position: 'absolute', left: '10px', bottom: '31px' }} />
            <Input disableUnderline={true} data-test-id={"txtInputSearch"} onChange={((e) => this.setInputValue(e.target.value))} style={webStyles.input} placeholder={"Search..."} />
          </div>
          <div style={webStyles.main_wrapper}>
            <div style={webStyles.group_card_wrapper}>
              <div style={webStyles.content__wrapper}>
                <div style={webStyles.flex__wrapper}>
                  <h3 style={webStyles.headline}>{configJSON.accountSetting}</h3>
                  <Grid style={webStyles.grid}>
                    {accountSettingData.map((item, idx) => (
                      <StyledGridItem key={idx} onClick={() => this.redirectUser(item?.href)}>
                         <ReactSVG 
                         src={item.icon} 
                         />
                        <div style={webStyles.grid__item__wrapper}>
                          <h5 style={webStyles.h5}>{item.title}</h5>
                          <h6 style={webStyles.h6}>{item.pOne}<br />{item.pTwo}</h6>
                        </div>
                      </StyledGridItem>
                    ))}
                  </Grid>
                </div>
              </div>
              <div style={webStyles.content__wrapper}>
                <div style={webStyles.flex__wrapper}>
                  <h3 style={{ fontFamily: 'Manrope', fontWeight: 700, fontSize: '20px', lineHeight: '27px', marginTop: '0px', marginBottom: '30px' }}>User management</h3>
                  <Grid style={webStyles.grid}>
                    {gridData.map((item, idx) => (
                      <StyledGridItemSecond data-test-id={`user-management${idx}`} key={idx} onClick={() => item.href == 'DepartmentFields' ? this.props.navigation.navigate('DepartmentFields') : this.redirectUser(item?.href)}>
                       <ReactSVG src={item.icon} />
                        <div style={webStyles.grid__item__wrapper}>
                          <h5 style={webStyles.h5}>{item.title}</h5>
                          <h6 style={webStyles.h6}>{item.pOne}<br />{item.pTwo}</h6>
                        </div>
                      </StyledGridItemSecond>
                    ))}
                  </Grid>
                </div>
              </div>
              <div style={webStyles.content__wrapper}>
                <div style={webStyles.flex__wrapper}>
                  <h3 style={webStyles.headline}>{configJSON.slanadolaPolicy}</h3>
                  <Grid style={webStyles.grid}>
                    {serviceManages.map((item, idx) => (
                      <StyledGridItem key={idx} onClick={() => this.redirectUser(item?.hrefs)}>
                         <ReactSVG
                          src={item.icons} />
                        <div style={webStyles.grid__item__wrapper}>
                          <h5 style={webStyles.h5}>{item.titles}</h5>
                          <h6 style={webStyles.h6}>{item.pOnes}<br />{item.pTwos}</h6>
                        </div>
                      </StyledGridItem>
                    ))}
                  </Grid>
                </div>
              </div>
            </div>
            <div style={{ width: "36%" }}>
  <div style={webStyles.list}>
    {listData.map((item, idx) => {
      if (item === "") {
        return subListData.map((subItem, subIdx) => (
          <StyledListItem 
            data-testid="right-sidebar"
            onClick={() =>
              subItem.href === "DepartmentFields"
                ? this.props.navigation.navigate("DepartmentFields")
                : this.redirectUser(subItem.href)
            }
            key={subIdx}
          >
            <span style={webStyles.subListItem}>{subItem.title}</span>
          </StyledListItem>
        ));
      } else if (item === "2") {
        return subListData2.map((subItem, subIdx) => (
          <StyledListItem
            data-testid="right-sidebar"
            onClick={() => this.props.navigation.navigate("ServiceManagement")}
            key={subIdx}
          >
            <span style={webStyles.subListItem}>{subItem.titles}</span>
          </StyledListItem>
        ));
      } else {
        return (
          <StyledListItem key={idx}>
            <span style={webStyles.span}>{item}</span>
          </StyledListItem>
        );
      }
    })}
  </div>
</div>
          </div>
        </div>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyles: Record<string, React.CSSProperties> = {
  container: {
    width: 'calc(100% - 250px) !important',
    height: '1024px',
    display: 'flex',
    flexDirection: 'column',
    margin: '15px 15px 15px 300px',
    paddingBottom: '25px',
    fontFamily: "Manrope"
  } as const,
  content__wrapper: {
    display: "flex",
    justifyContent: 'center',
    gap: "30px",
    marginTop: "15px",
    width: "100%"
  },
  flex__wrapper: {
    width: '100%',
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    padding: "15px",
    borderRadius: "15px",
    boxShadow: "0px 3px 30px 20px #e6deed",
  } as const,
  grid: {
    background: "white",
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gridColumnGap: "15px",
    gridRowGap: "15px",
  },
  gridSvg: {
        color: '#8760a9',
    marginBottom: '-30px',
    width: '32px',
    height: '32px',
  },
  input: {
    fontFamily: 'Manrope',
    border: "1px solid #e6deed",
    padding: "7px 10px 7px 45px",
    borderRadius: "10px",
    marginBottom: "20px",
    width: '100%'
  },
  grid__item__wrapper: {
    height: "50px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    lineHeight: "15px",
    textAlign: "center",
  } as const,
  h5: {
    fontFamily: 'Manrope',
    margin: '10px',
    marginBottom: '0px',
    color:"#33354D",
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: '24px'
  } as const,
  h6: {
    fontFamily: 'Manrope',
    margin: '0px',
    fontSize: '12px',
    color: "#a3a6c2",
    fontWeight: 600,
    lineHeight: '19px'
  } as const,
  list: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: "15px",
    boxShadow: "0px 3px 30px 20px #e6deed",
    height:"655px",
    padding: "5px",
    marginTop: "15px",
    width:"90%",
    float: "right"
  } as const,

  main_wrapper: {
    display: "flex",
    gap: "0px",
    padding: "20px"
  },

  headline: {
    fontFamily: "Manrope",
    fontWeight: 700,
    fontSize: "20px",
    color:"#33354D",
    lineHeight: "27px",
    marginTop: "0px", 
    marginBottom: "30px"
  },
  span: {
    fontFamily: 'Manrope',
    fontWeight: 600,
       color:"#33354D",
    fontSize: '14px',
    lineHeight: '22px',
  },
  group_card_wrapper: {
    flex: "81%"
  },
  subListItem: {
     color:"#33354D",
    fontFamily: 'Manrope',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '22px',
    marginLeft: '12px'
  } as const,
};
// Customizable Area End
