import React from "react";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import toast from "react-hot-toast";
export const configJSON = require("./config");

interface CategoriesResponse {
  data: CategoriesResponseData[],
  errors: any
}

interface CategoriesResponseData {
  id: string,
  type: "category",
  attributes: {
    id: string | number,
    name: string,
    description: string
  }
}

interface CateogryData {
  id?: string | number;
  name: string;
  description: string;
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoading: boolean;
  categories: CategoriesResponseData[];
  isInfoModalOpen: boolean;
  anchorEl: any;
  categoryData: CateogryData;
  categoryDataError: any
  addModalOpen: boolean;
  editModal: boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}
  
class CategoriesController extends BlockComponent<
Props,
S,
SS
> {

  // Customizable Area Start
  getAllCategoriesCallId: string = "";
  addCategoryAPICallId: string = "";
  token: string = "";
  getParticularCategoryAPICallId: string = "";
  updateCategoryAPICallId: string="";
  deleteCategoryAPICallId: string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
  // Customizable Area Start
  // Customizable Area End
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isLoading: false,
      categories: [],
      editModal:false,
      isInfoModalOpen: false,
      anchorEl: null,
      categoryData: { id: undefined, name: "", description: "" },
      addModalOpen: false,
      categoryDataError: null
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    )
    this.setState({
      isLoading: false
    })

    switch (apiRequestCallId) {
      case this.getAllCategoriesCallId:
        this.setState({ categories: responseJson.data });
        break;
      case this.addCategoryAPICallId:
        this.handleAddEditCategoryResponse(responseJson, true);
        break;
      case this.updateCategoryAPICallId:
        this.handleAddEditCategoryResponse(responseJson, false);
        break;
      case this.deleteCategoryAPICallId:
        this.handleDeleteCategoryResponse(responseJson);
        break;
      default:
        break;
    }
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    this.token = await getStorageData('authToken');
    this.getAllCategories();
    // Customizable Area End
  }

  // Customizable Area Start
handleChangeCategoryData = (event: React.ChangeEvent<HTMLInputElement>) => {
  const { name, value } = event.target;
  const minLength = 4;
  const maxLength = 50;

  const nameRegex = /^[a-zA-Z]{3}[\w\s@$!%*?&()-+=[\]{}|;:'",.<>\/]*$/; 

  const isValidLength = value.length >= minLength && value.length <= maxLength;
  const isValidChars = nameRegex.test(value);
  const isValid = isValidLength && isValidChars || value === "";

  this.setState(prevState => {
    const updatedCategoryData = { ...prevState.categoryData, [name]: value };

    return {
      categoryData: updatedCategoryData,
      categoryDataError: isValid
        ? ""
        : value.length < minLength
        ? `Name must be at least ${minLength} characters.`
        : value.length > maxLength
        ? `Name cannot exceed ${maxLength} characters.`
        : "Name must start with 3 letters and can then contain letters, numbers, spaces, or special characters.",
    };
  });
};


  handleAddEditCategoryResponse = (responseJson: CategoriesResponse, isNew: boolean) => {
    if (responseJson.data) {
      isNew ? toast.success(configJSON.categoryCreateSuccessMessage) : 
      toast.success(configJSON.categoryUpdateSuccessMessage);
      this.handleNewCategoryModal(false);
      this.getAllCategories();
    }
    if (responseJson.errors) {
      this.handleErrors(responseJson.errors);
    }
  }

  handleDeleteCategoryResponse (responseJson: {errors: any}) {
    if (responseJson.errors) {
      this.handleErrors(responseJson.errors);
    } else {
      toast.success(configJSON.categorydeleteSuccessMessage);
      this.getAllCategories();
    }
  }

  handleErrors(errors: any) {
    if (typeof (errors[0]) === "object") {
      toast.error(Object.values(errors[0])[0] as string);
      this.handleNewCategoryModal(false);
    } else if(Array.isArray(errors)) {
      this.setState({ categoryDataError: errors[0]});
    } else if (typeof (errors) === "object") {
      toast.error(Object.values(errors)[0] as string);
      this.handleNewCategoryModal(false);
    }
  }

  getAllCategories() {
    this.setState({ isLoading: true });
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllCategoriesCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getCategoriesApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  validateForm = () => {
    if (this.state.categoryData.name.trim() === '') {
      this.setState({ categoryDataError: configJSON.categoryNameRequire });
      return false;
    }
    return true;
  }
  addCategories = () => {
    if (!this.validateForm()) {
      return
    }
    const { categoryData } = this.state;
    this.setState({ isLoading: true });
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.token
    };

    const httpBody = {
      category:{
          name: categoryData.name,
          description: categoryData.description
      }
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addCategoryAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getCategoriesApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  updateCategories = () => {
    if (!this.validateForm()) {
      return
    }
    const { categoryData } = this.state;
    this.setState({ isLoading: true });
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.token
    };

    const httpBody = {
      category:{
          name: categoryData.name,
          description: categoryData.description
      }
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateCategoryAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getCategoriesApiEndPoint}/${this.state.categoryData.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  deleteCategories = (categoryId: string | number) => {
    this.setState({ isLoading: true });
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteCategoryAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getCategoriesApiEndPoint}/${categoryId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleNewCategoryModal = (addModalOpen: boolean, id?: string | number) => {
    let categoryData = { id: undefined, name: "", description: "" } as CateogryData;
    if (id) {
      let currentCategory = this.state.categories.find(category => category.id.toString() === id.toString());
      if (currentCategory){
        categoryData = {...categoryData, id: id, name: currentCategory.attributes.name, description: currentCategory.attributes.description }
        this.setState({editModal: true})
      }
        
    }
    this.setState({ addModalOpen: addModalOpen, categoryData: categoryData, categoryDataError: null });
  }

  handleNavigation = (path: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), path);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message)
  }

  handleInfoIconClick = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ isInfoModalOpen: true, anchorEl: event.currentTarget });
  }

  handelCategoriesInfoModal = (value: boolean) => {
    this.setState({ isInfoModalOpen: value });
  }
  // Customizable Area End
}

export default CategoriesController;