import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import toast from "react-hot-toast";

interface Dropdown {
    id: string;
    email: string;
  }
  interface AssetAttributes {
    id: number;
    display_name: string;
    description: string;
    asset_type: string;
  }
  
  interface Asset {
    id: string;
    type: string;
    attributes: AssetAttributes;
  }
  interface TicketTemplate {
    id: string;
    type: string;
    attributes: {
        ticket_template_attachments: any | null;
      title: string;
      description: string;
      available_for: string;
      ticket: {
        data: {
          id: string;
          type: string;
          attributes: {
            associate_assets:{data:Asset[]}
            subject: string;
            description: string;
            priority: string;
            planned_startdate: string;
            planned_enddate: string;
            planned_start_time: string | null;
            planned_end_time: string | null;
            planned_effort: string;
            status: string;
            source: string;
            ticket_template_id: number;
            created_at: string;
            impact: string | null;
            urgency: string | null;
            category: string;
            tags: string[];
            requester: { id: number | null;
                full_name: string | null;
                email: string | null;};
            cc: any[];
            ticket_attachments: any | null; 
            group: { name: string | null;
              id: number | null; };
            department: {
              name: string | null; id: number | null;};
            agent: { id: number | null;full_name: string | null;email: string | null;};
           
          };
        };
      };
     
    };
  }
  interface TicketTemplateUpdate {
    type: "ticket_template";
    id: string;
    attributes: TicketTemplateAttributes;
  }
  
  interface TicketTemplateAttributes {
    title: string;
    description: string;
    available_for: string;
    ticket: {
      data: TicketData;
    };
    ticket_template_attachments: any | null;
  }
  
  interface TicketData {
    id: string;
    type: "ticket";
    attributes: TicketAttributes;
  }
  
  interface ManagedByGroup {
    id: number | null;
    name: string | null;
  }
  
  interface UsedBy {
    id: number | null;
    name: string | null;
    email: string | null;
  }
  
  interface Department {
    id: number | null;
    name: string | null;
  }
  
  interface ManagedBy {
    id: number | null;
    name: string | null;
    email: string | null;
  }
  
  interface AssetAttributes {
    id: number;
    display_name: string;
    description: string;
    impact: string;
    asset_type: string;
    asset_tag: string;
    end_of_life: string;
    location: string;
    model: string | null;
    serial_no: string | null;
    received_by: string | null;
    purchase_date: string | null;
    dealer_id: string | null;
    managed_by_group: ManagedByGroup;
    used_by: UsedBy;
    department: Department;
    managed_by: ManagedBy;
    assigned_on: string;
    asset_files: string | null;
  }
  
  interface Asset {
    id: string;
    type: string;
    attributes: AssetAttributes;
  }
  
  interface AssetsResponse {
    data: Asset[];
  }
  interface TicketAttributes {
    subject: string;
    description: string;
    priority: string;
    planned_startdate: string;
    planned_enddate: string;
    planned_effort: string;
    status: string;
    source: string;
    impact: string | null;
    urgency: string | null;
    category: string;
    tags: string;
    associate_assets:{data:Asset[]}
    ticket_template_id: number;
    created_at: string;
    planned_start_time: string | null;
    planned_end_time: string | null;
    cc: any[];
    group: {
      id: number | null;
      name: string | null;
    };
    requester: {
      id: number | null;
      full_name: string | null;
      email: string | null;
    };
    department: {
      id: number | null;
      name: string | null;
    };
    agent: {
      id: number | null;
      full_name: string | null;
      email: string | null;
    };
    ticket_attachments: any | null;
  }
  
export interface AllGroupsList {
    id: string,
    type: string,
    attributes: {
        name: string,
        category: {
            data: {
                id: number | null,
            }
        },
        agents: {
            id: number;
            full_name: string;
            email: string;
            agent_type: string;
          }[]
    }
}
export interface AllAgentsList {
    id: string,
    type: string,
    attributes: {
        full_name: string
    }
}

export interface DepartmentList {
    id: string,
    type: string,
    attributes: {
        department_name: string
    }
}
interface TicketTemplate {
    title: string;
    index: string;
}
interface FileData {
    name: string;
    lastModified: number;
    size: number;
    type: string
  }
 export interface CategoryList {
    id: string;
    type: string;
    attributes: {
        name: string;
    }
  }
  interface AssociateAssets{
    id: string
    name: string,
    type: string,
    used_by:string
    department: string
    location: string
    serial_no:string
    state: string
  }
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    ccDropdowns: Dropdown[];
    requestersList: any[];
    isAddCcDisabled: boolean;
    selectedCategoryValue: CategoryList
    isLoading: boolean,
    txtInputValue: string;
    filteredCategories: CategoryList[]
    templateList: TicketTemplate[];
    hideDropdown: boolean
    subject: string;
    showAssetChip: boolean
    selectedTemplate: string | number;
    subjectError: string,
    sourceError : string;
    title: string;
    fileData: FileData[] | null;
    fileName: string
    fileNames: string[]
    titleError:string;
    generalDescription:string;
    generalDescriptionError:string;
    statusError : string;
    agentValue: AllAgentsList;
    selectedSource: string,
    selectedStatus: string,
    showButtonDropdown: boolean
    selectedPriority: string,
    availableFor: string,
    departmentValue: DepartmentList
    allGroupList: AllGroupsList[],
    selectedGroup: string,
    filteredOptions: AllGroupsList[];
    allAgentsList: AllAgentsList[],
    filteredAgents: AllAgentsList[],
    association_assests:AssociateAssets[]
    allDepartmentList: DepartmentList[],
    filteredDepartmentList: DepartmentList[],
    description: string;
    descriptionError: string;
    categoryList: CategoryList[];
    dueDate: string;
    dueTime: string;
    dueDateError: string;
    dueTimeError: string;
    startDate: string;
    startTime: string;
    endDate: string;
    endTime: string;
    timeDifference: string;
    inputValue: string;
    selectedTags: string[];
    requesterError: string;
    isValidationError: boolean;
    startDateError : string;
    ticket_id: string;
    endDateError : string;
    changedDepartment: string;
    changedAgent: string;
    changedGroup: string;
    changedRequester: string;
    startTimeError:string;
    endTimeError: string;
    filtered_assets: AssociateAssets[]
    toastOpen: boolean;
    selectedAssets: AssociateAssets[]
    editTemplate: boolean
    template_id: string;
    createAnchorEl: HTMLElement | null | undefined,
    showAssetsDropdown: boolean
    showAssetModal: boolean
    selectedCategory: string
    editedGroup: {id: string, type: string, attributes: {name: string,category: {data: {id: number | null}}}};
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class TicketTemplateCustomFormController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    createTicketApiCallId: string = ''
    getSingalTemplateDataApiCallId: string = ''
    getAllAssetsApiCallId: string = ''
    token: string = ''
    status: { title: string, id: string }[] = [
        {
            id: 'Open',
            title: 'Open',
        },
        {
            id: 'Closed',
            title: 'Closed',
        },
        {
            id: 'Pending',
            title: 'Pending',
        },
        {
            id: 'Resolved',
            title: 'Resolved'
        }
    ]
    source = [
        {
            id: '1',
            title: 'Email'
        },
        {
            id: '2',
            title: 'Service Desk'
        },
        {
            id: '3',
            title: 'Phone'
        },
    ]
    getRequesterDataAPICallId: string = "";
    getAllGroupsAPICallId: string = "";
    getAllAgentsListApiCallId: string = "";
    getAllDepartmentAPICallId: string = "";
    submitFormAPICallId: string = "";
    getAllCategoryAPICallId: string = "";
    updateFormApiId: string=""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            ccDropdowns: [{ id: "", email: "" }], 
            requestersList: [], 
            showAssetModal:false,
            fileNames:[],
            isAddCcDisabled: false,
            selectedCategoryValue: {id:'',type:'',attributes:{name:''}},
            isLoading: false,
            createAnchorEl: null,
            showAssetChip: false,
            showAssetsDropdown: false,
            hideDropdown: false,
            template_id:'',
            txtInputValue: '',
            association_assests:[],
            editTemplate: false,
            showButtonDropdown: false,
            filteredCategories:[],
            filtered_assets:[],
            toastOpen: false,
            subject: '',
            agentValue: {id: '',attributes: {full_name: ''}, type: ''},
            fileData: [],
            selectedAssets:[],
            templateList: [],
            departmentValue:{id:'',type:'',attributes:{department_name:''}},
            selectedTemplate: 'none',
            subjectError: '',
            sourceError : "",
            statusError:"",
            fileName:'',
            availableFor:"",
            generalDescription:"",
            generalDescriptionError:"",
            selectedSource: this.source[0].id,
            selectedStatus: this.status[0].id,
            selectedPriority: "Low",
            allGroupList: [],
            selectedGroup: '',
            title:'',
            titleError:'',
            filteredOptions: [],
            editedGroup:{id:'',type:'',attributes:{name:'',category:{data:{id:null}}}},
            allAgentsList: [],
            filteredAgents: [],
            allDepartmentList: [],
            filteredDepartmentList: [],
            description: '',
            descriptionError: '',
            categoryList: [],
            dueDate: "",
            dueTime: "",
            startDate: "",
            startTime: "",
            endDate: "",
            endTime: "",
            timeDifference: "",
            selectedCategory: "",
            inputValue: "",
            selectedTags: [],
            requesterError: '',
            ticket_id:'',
            isValidationError: false,
            startDateError : '',
            endDateError : '',
            dueDateError: '',
            changedDepartment: '',
            changedAgent: '',
            changedGroup: '',
            changedRequester: '',
            startTimeError:'',
            endTimeError: '',
            dueTimeError: ''
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            )
            this.setState({
                isLoading: false
            })

            // This will rerdirect user to login page if token is not valid 
            if (responseJson && Array.isArray(responseJson.errors) && responseJson.errors.length > 0 &&
                (responseJson.errors[0].token === "Token has Expired" || responseJson.errors[0].token === 'Invalid token')) {
                this.goToLogin();
            } 
            if(responseJson && Array.isArray(responseJson.errors) && responseJson.errors[0].length>0){
                toast.error(responseJson.errors[0])
            }
            switch (apiRequestCallId) {
           

                case this.getAllGroupsAPICallId:
                    this.handleGroupApiDataResponse(responseJson);
                    break;

                case this.getAllDepartmentAPICallId:
                    this.handleDepartmentListResponse(responseJson);
                    break;

                case this.submitFormAPICallId:
                    this.handleCreateTicketResponse(responseJson);
                    break;
                case this.updateFormApiId:
                   this.handleUpdateTicketResponse(responseJson);
                   break;
                case this.getAllCategoryAPICallId:
                    this.handleCategoryListResponse(responseJson);
                    break;
                case this.getAllAssetsApiCallId:
                    this.handleAssetsresponse(responseJson);
                    break;
            }
        }
        // Customizable Area End
    }



    // Customizable Area Start
    async componentDidMount() {
        this.token = await getStorageData('authToken')
        this.getAllRequesterList()
        this.getAllGroups()
        this.getAllCategoryList()
        this.setState({ filteredOptions: this.state.allGroupList });
        this.getAllAssets()
        this.setState({ filteredAgents: this.state.allAgentsList });
        this.getAllDepartment()
        this.setState({filteredCategories: this.state.categoryList})
        this.setState({ filteredDepartmentList: this.state.allDepartmentList })
        const data =  sessionStorage.getItem('ticketTemplate')
        if(data){
            const templateData = JSON.parse(data) as TicketTemplateUpdate;
            this.setState({
                editTemplate: true,
                template_id: templateData.id,
                ticket_id: templateData.attributes.ticket.data.id,
                title: templateData.attributes.title || this.state.title,
                generalDescription: templateData.attributes.description || this.state.generalDescription,
                subject: templateData.attributes.ticket.data?.attributes?.subject || this.state.subject,
                selectedStatus: templateData.attributes.ticket?.data?.attributes?.status || this.state.selectedStatus,
                selectedSource: templateData.attributes.ticket?.data?.attributes?.source || this.state.selectedSource,
                selectedPriority: templateData.attributes.ticket?.data?.attributes?.priority || this.state.selectedPriority,
                availableFor: templateData.attributes.available_for || this.state.availableFor,
                selectedGroup: templateData.attributes.ticket?.data?.attributes?.group?.name || this.state.selectedGroup,
                selectedTags:templateData.attributes.ticket?.data?.attributes?.tags.length >0 && templateData.attributes.ticket?.data?.attributes?.tags ? templateData.attributes.ticket?.data?.attributes?.tags.split(',') : this.state.selectedTags,
                selectedCategory: templateData.attributes.ticket?.data?.attributes?.category || this.state.selectedCategory,
                description: templateData.attributes.ticket?.data?.attributes?.description || this.state.description,
                startDate :  templateData.attributes.ticket.data.attributes.planned_startdate ? new Date(templateData.attributes.ticket.data.attributes.planned_startdate).toISOString().split('T')[0] 
                :this.state.startDate,
                selectedAssets: templateData.attributes.ticket.data.attributes.associate_assets.data.length >0 ? this.mapAssets(templateData.attributes.ticket.data.attributes.associate_assets.data) : this.state.selectedAssets,
                startTime:templateData.attributes.ticket?.data?.attributes?.planned_startdate ? new Date( templateData.attributes.ticket?.data?.attributes?.planned_startdate).toISOString().split('T')[1].split('.')[0] : this.state.startTime,
                selectedCategoryValue:templateData.attributes.ticket.data.attributes.category?  await this.getCategory(templateData.attributes.ticket.data.attributes.category): this.state.selectedCategoryValue,
                endTime: templateData.attributes.ticket?.data?.attributes?.planned_enddate ?  new Date( templateData.attributes.ticket?.data?.attributes?.planned_enddate).toISOString().split('T')[1].split('.')[0] : this.state.endTime,
                endDate: templateData.attributes.ticket?.data?.attributes?.planned_enddate ? new Date(templateData.attributes.ticket?.data?.attributes?.planned_enddate).toISOString().split('T')[0]  : this.state.endDate,
                timeDifference: templateData.attributes.ticket?.data?.attributes?.planned_effort || this.state.timeDifference,
                changedDepartment:templateData.attributes.ticket?.data?.attributes?.department?.id? String(templateData.attributes.ticket?.data?.attributes?.department?.id) : this.state.changedDepartment,
                changedAgent: templateData.attributes.ticket?.data?.attributes?.agent?.id? String(templateData.attributes.ticket?.data?.attributes?.agent?.id) : this.state.changedAgent,
                changedGroup:templateData.attributes.ticket?.data?.attributes?.group?.id? String(templateData.attributes.ticket?.data?.attributes?.group?.id) : this.state.changedGroup,
                requesterError: this.state.requesterError, 
                inputValue: this.state.inputValue, 
                departmentValue: {id: templateData.attributes.ticket?.data?.attributes?.department?.id? String(templateData.attributes.ticket?.data?.attributes?.department?.id) : '', type: templateData.attributes.ticket.data?.attributes?.department?.name || '', attributes: {department_name: templateData.attributes.ticket?.data?.attributes?.department?.name || ''}},
                agentValue: {id:templateData.attributes.ticket?.data?.attributes?.agent?.id? String(templateData.attributes.ticket?.data?.attributes?.agent?.id) :'', type: templateData.attributes.ticket.data?.attributes?.agent?.full_name || '', attributes: {full_name: templateData.attributes.ticket?.data?.attributes?.agent?.full_name || ''}},
                editedGroup: {id:templateData.attributes.ticket?.data?.attributes?.group?.id? String(templateData.attributes.ticket?.data?.attributes?.group?.id) : '', type: templateData.attributes.ticket.data?.attributes?.group?.name || '', attributes: {name: templateData.attributes.ticket?.data?.attributes?.group?.name || '',category: {data:{id:Number(templateData.attributes.ticket?.data?.attributes?.category)}}}}
                ,fileNames: templateData.attributes.ticket_template_attachments&& templateData.attributes.ticket_template_attachments.length > 0?
                 templateData.attributes.ticket_template_attachments.map((attachment: {id: string,url: string}) => {
                    const urlParts = attachment.url.split('/');
                    return urlParts[urlParts.length - 1];
                  }): this.state.fileNames,
                  fileData: templateData.attributes.ticket_template_attachments&& templateData.attributes.ticket_template_attachments.length > 0? templateData.attributes.ticket_template_attachments.map((attachment: {id: string,url: string}) => {
                    return attachment
                  }): this.state.fileData   
            });
            if(templateData.attributes.available_for === 'All agents'){
                this.setState({hideDropdown:true})
            }
            
        }
    }

    goToLogin() {
        const messages: Message = new Message(
            getName(MessageEnum.NavigationEmailLogInMessage)
        );
        messages.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(messages);
    }

    handleSourceChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const sourceValue = event.target.value as string;
        const isSourceEmpty = sourceValue.trim() === '';
        this.setState({
            selectedSource: sourceValue,
            sourceError: isSourceEmpty ? configJSON.sourceReq : ''
        });
    };
    handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
          const newSelecteds = this.state.association_assests;
          this.setState({ selectedAssets: newSelecteds });
          return;
        }
        this.setState({ selectedAssets: [] });
      };
    
      handleClick = (event: React.MouseEvent<HTMLElement>, name: string) => {
        const selectedIndex = this.state.selectedAssets.findIndex((item) => item.id === name);
        let newSelected: AssociateAssets[] = [];
    
        if (selectedIndex === -1) {
            const selectedItem = this.state.association_assests.find((item) => item.id === name);
            if (selectedItem) {
                newSelected = newSelected.concat(this.state.selectedAssets, selectedItem);
            }
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(this.state.selectedAssets.slice(1));
        } else if (selectedIndex === this.state.selectedAssets.length - 1) {
            newSelected = newSelected.concat(this.state.selectedAssets.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                this.state.selectedAssets.slice(0, selectedIndex),
                this.state.selectedAssets.slice(selectedIndex + 1)
            );
        }
    
        this.setState({ selectedAssets: newSelected });
    };
    handleAssetsresponse = (response: { data: Asset[] }) => {
        if (response.data) {
          const association_assests: AssociateAssets[] = response.data.map(asset => ({
            id: asset.id,
            name: asset.attributes.display_name,
            type: asset.attributes.asset_type,
            used_by: asset.attributes.used_by.name || '',
            department: asset.attributes.department.name || '',
            location: asset.attributes.location || '',
            serial_no: asset.attributes.serial_no || '',
            state: asset.attributes.impact || '',
          }));
      
          this.setState({ association_assests:association_assests,filtered_assets:association_assests });
        }
      }
    handleSearchAssets = (event: React.ChangeEvent<HTMLInputElement>)=>{
        const filteredAssets = this.state.association_assests.filter(asset =>
            asset.name.toLowerCase().includes(event.target.value.toLowerCase())
          );
          this.setState({filtered_assets:filteredAssets})
    }
    handleSelectedAssets = ()=>{
        this.setState({showAssetModal:false,showAssetChip:true})
    }
    isSelected = (name: string): boolean => this.state.selectedAssets.some((asset) => asset.id === name);
    mapAssets = (data: Asset[])=>{
         const newData = data.map((item) => ({
            id: item.id,
            name: item.attributes.display_name,
            type: item.attributes.asset_type,
            used_by: item.attributes.used_by?.name || '',
            department: item.attributes.department?.name || '',
            location: item.attributes.location || '',
            serial_no: item.attributes.serial_no || '',
            state: item.attributes.impact || '',
          }));
          if(newData.length >0) this.setState({showAssetChip: true})
          return newData
    }

    handleSubjectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const subjectValue = event.target.value;
        const subjectRegex = /^[a-zA-Z][a-zA-Z0-9\s]*$/;
        const validCharacters = subjectRegex.test(subjectValue);
        const validLength = subjectValue.length <= 50;
        const isValid = (validCharacters && validLength) || subjectValue === '';

        let subjectError = '';
        if (!validCharacters) {
          subjectError = 'Subject must start with letters and can only contain letters and numbers.';
        } else if (!validLength) {
          subjectError = 'Subject must be less than 50 characters.';
        }
        this.setState({  subject: subjectValue,subjectError: isValid ? '' : subjectError,});
    }
    getCategory = async (id: string): Promise<CategoryList> => {
        return new Promise((resolve) => {
            setTimeout(() => {
                const category = this.state.filteredCategories.filter(agents =>
                    agents.id === id
                );
                resolve(category.length > 0 ? category[0] : { id: '7', type: '', attributes: { name: '' } });
            }, 2000);
        });
    }
    handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const titleValue = event.target.value;
        const minLength = 4;
        const maxLength = 50;
    
        const titleRegex = /^[a-zA-Z][a-zA-Z0-9\s]*$/;
    
        const isValidLength = titleValue.length >= minLength && titleValue.length <= maxLength;
        const isValidChars = titleRegex.test(titleValue);
        const isValid = isValidLength && isValidChars || titleValue === '';
    
        this.setState({
            title: titleValue,
            titleError: isValid
                ? ''
                : titleValue.length < minLength
                ? `Title must be at least ${minLength} characters.`
                : titleValue.length > maxLength
                ? `Title cannot exceed ${maxLength} characters.`
                : !isValidChars
                ? 'Title must start with a letter and can only contain letters and numbers'
                : '',
        });
    }
    getBackground (){
        return  this.state.hideDropdown ? "#e9e9e9":""
    }
    handleGeneralDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const descValue = event.target.value;
        const descRegex = /^[a-zA-Z0-9][\w\s@$!%*?&()-+=[\]{}|;:'",.<>\/]*$/;
        const isValidChars = descRegex.test(descValue);
        const isValid =  isValidChars || descValue === '';
    
        this.setState({
            generalDescription: descValue,
            generalDescriptionError: isValid
                ? ''
                :!isValidChars
                ? 'Description must start with a letter or numbers'
                : '',
        });
    }


    handleStatusChange = (event: React.ChangeEvent<{ value: unknown }>) => {

        const statusValue = event.target.value as string;
        const isStatusEmpty = statusValue.trim() === '';
        this.setState({
            selectedStatus: statusValue,
            statusError: isStatusEmpty ? configJSON.subjectReq : ''
        });
    }

    handlePriorityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ selectedPriority: event.target.value });
    };
    handleAvailableForChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(event.target.value === 'All agents'){
            this.setState({changedAgent:"",changedGroup:"",selectedCategory:"", hideDropdown: true, availableFor: event.target.value, selectedCategoryValue: { id: '', type: '', attributes: { name: '' } }, editedGroup: { id: '', type: '', attributes: { name: '', category: { data: { id: null } } } }, agentValue: { id: '', attributes: { full_name: '' }, type: '' } })
        }else this.setState({ availableFor: event.target.value,hideDropdown:false });
    }

    handleGroupSelectionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event?.target?.value;
        const filteredOptions = this.state.allGroupList.filter(group =>
            group.attributes?.name?.toLowerCase().includes(inputValue?.toLowerCase())
        );
        this.setState({ filteredOptions: filteredOptions });
    };

handleCancel = ()=>{
    this.props.navigation.navigate("FormTemplates")
}
    handleAgentsSelectionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event?.target?.value;
        const filteredAgents = this.state.allAgentsList.filter(agents =>
            agents.attributes?.full_name?.toLowerCase().includes(inputValue?.toLowerCase())
        );
        this.setState({ filteredAgents });
    }
    handleCategorySelectionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event?.target?.value;
        const filteredCategories = this.state.categoryList.filter(agents =>
            agents.attributes?.name?.toLowerCase().includes(inputValue?.toLowerCase())
        );
        this.setState({ filteredCategories });
    }

    handleDepartmentSelectionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event?.target?.value;
        const filteredDepartmentList = this.state.allDepartmentList.filter(department =>
            department.attributes?.department_name?.toLowerCase().includes(inputValue?.toLowerCase())
        );
        this.setState({ filteredDepartmentList });
    }

    handleShowAssetModal = ()=>{
        this.setState({showAssetModal:true})
    }
    handleCloseAssetModal = ()=>{
        this.setState({showAssetModal:false})
    }

    handleStartDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedDate = event.target.value;
        const { endDate } = this.state;
        if (endDate && selectedDate > endDate) {
            this.setState({ startDate: selectedDate, startDateError: configJSON.startDateErrorValidation });
        } else {
            this.setState({ startDate: selectedDate,  endDateError: '',startDateError:'',startTimeError:'',endTimeError:'' }, () => {
                this.calculateTimeDifference();
            });
        }
    };

    handleStartTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedTime = event.target.value;
        if (!selectedTime) {
            this.setState({ startTime: selectedTime, startTimeError: configJSON.startTimeError });
        } else {
            this.setState({ startTime: selectedTime, endDateError: '',startDateError:'',startTimeError:'',endTimeError:'' }, ()=>{
                this.calculateTimeDifference();
            });
        }   
    };

    handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedDate = event.target.value;
        
        if (selectedDate < this.state.startDate) {
            this.setState({ endDate: selectedDate, endDateError: configJSON.endDateError });
        } else {
            this.setState({ endDate: selectedDate, endDateError: '',startDateError:'',startTimeError:'',endTimeError:'' },()=>{
                this.calculateTimeDifference()
            });
        }

    };

    handleEndTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedEndTime = event.target.value;
       
        const { startDate, endDate, startTime } = this.state;
        if (!selectedEndTime) {
            this.setState({ endTime: selectedEndTime, endTimeError: configJSON.endTimeError });
        } else {
            let endTimeError = '';
            if (startDate === endDate ) {
                if(selectedEndTime < startTime)
                    endTimeError = 'End time cannot be earlier than start time on the same date.';
                else if(selectedEndTime === startTime){
                    endTimeError ="The end time must be different from the start time on the same date."
                }
            }
    
            this.setState({ endTime: selectedEndTime, endDateError: '', startDateError: '', startTimeError: '', endTimeError }, () => {
                if (!endTimeError) {
                    this.calculateTimeDifference();
                }
            });
        }
    };

    calculateTimeDifference = () => {
        const { startDate, startTime, endDate, endTime } = this.state;
        if(startDate && startTime && endDate && endTime){
            const startDateTime = new Date(startDate + "T" + startTime);
            const endDateTime = new Date(endDate + "T" + endTime);
            const difference = endDateTime.getTime() - startDateTime.getTime();
    
            const hoursDifference = Math.floor(difference / 1000 / 60 / 60) ?? 0;
            const minutesDifference = Math.floor((difference / 1000 / 60) % 60) ?? 0;
    
            this.setState({
                timeDifference: `${hoursDifference} hours ${minutesDifference} minutes`,
            });
        }
       
    };

    handleInputChange = (event: { target: { value: string } }) => {
        this.setState({ inputValue: event.target.value });
    };

    handleAddTag = (tags: string) => {
        if (tags && !this.state.selectedTags.includes(tags)) {
            this.setState({
                inputValue: '',
                selectedTags: [...this.state.selectedTags, tags]
            })
        }
    };
    handleDeleteTag = (tagToDelete: string) => () => {
        this.setState((tags) => {
            return {
                selectedTags: tags.selectedTags.filter((tags: string) => tags !== tagToDelete)
            }
        });
    };

    handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === configJSON.textEnter) {
            this.handleAddTag(this.state.inputValue);
        }
    };
    navigateUser = () => {
        const navigateToLoginMessage: Message = new Message(
            getName(MessageEnum.NavigationRequestersMessage)
        );
        navigateToLoginMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        navigateToLoginMessage.addData(getName(MessageEnum.UrlPathParamMessage), 'createRequester');
        this.send(navigateToLoginMessage);
    }


    handleChangeDepartment = (value: DepartmentList | null) => {
        if(value)
            this.setState({departmentValue:value})
        else this.setState({ departmentValue: {id:"",type:'',attributes:{department_name:''}} });
        this.setState({ changedDepartment: value ? value.id : '', });
    }

    handleChangeAgents = (value: AllAgentsList | null) => {
        if(value){
            this.setState({agentValue: value})
        }else this.setState({ agentValue: {id:"",type:'',attributes:{full_name:''}} });
        this.setState({ changedAgent: value ? value.id : '' })
    }

    handleChangeGroup = (value: AllGroupsList | null) => {
        if (value) {
            const associatedCategory = value.attributes.category.data !== null && value.attributes.category.data.id !== null ? value.attributes.category.data.id.toString() : '';
            let data: CategoryList[] = [];
            if (value.attributes.category.data && value.attributes.category.data.id !== null) {
                data = this.state.categoryList.filter((item) => item?.id === String(value.attributes.category.data.id));
            }

            this.setState({
                editedGroup: value,
                filteredAgents: value.attributes.agents.length > 0 ? 
                value.attributes.agents.map((item)=> {
                    return {
                        id: String(item.id),
                        attributes: {
                            full_name: item.full_name
                        },
                        type: item.agent_type,
                       
                    }
                }): [],
                changedGroup: value.id,
                selectedCategory: associatedCategory,
                selectedCategoryValue: data.length > 0 ? data[0] : {id:'',attributes:{name:''},type:''},
                allAgentsList: 
                value.attributes.agents.length > 0 ? 
                value.attributes.agents.map((item)=> {
                    return {
                        id:
                         String(item.id),
                        type:
                         item.agent_type,
                        attributes: 
                        {
                            full_name: item.full_name
                        }
                    }
                }): [],
               
            });
        } else {
            this.setState({
                filteredAgents:[],
                editedGroup: { id: "", type: '', attributes: { name: '', category: { data: { id: null } } } },
                changedGroup: '',
                selectedCategory: '',
                selectedCategoryValue: {id:'',attributes:{name:''},type:''},
                allAgentsList:[],
            
            });
        }
    }
     stripHtmlTags = (html: string): string => {
        const div = document.createElement('div');
        div.innerHTML = html;
        return div.textContent || div.innerText || '';
      };
    handleChange = (value: string) => {
        const descriptionValue = value || ''
        const plainTextDescription = this.stripHtmlTags(descriptionValue);
        const isDescriptionValid = plainTextDescription.length >= 10;
        const isDescriptionEmpty = descriptionValue?.trim() === '';
        let descriptionError = '';

        if (isDescriptionEmpty) {
            descriptionError = configJSON.descriptionRequired;
        } else if (!isDescriptionValid) {
            descriptionError = configJSON.descriptionCharcter;
        }

        this.setState({
            description: descriptionValue,
            descriptionError: descriptionError
        });
      };
    // API Integration

    handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        this.setState(prevState => ({
            fileData: [...(prevState.fileData || []), file].filter(Boolean) as FileData[], 
            fileNames: [...(prevState.fileNames || []), file ? file.name : '']
          }));
    }
    
    removeFile = (index: number) => {
        this.setState(prevState => {
            const fileData = [...(prevState.fileData || [])];
            const fileNames = [...prevState.fileNames];
            fileData.splice(index, 1);
            fileNames.splice(index, 1);
            return { fileData, fileNames };
        });
    }
    removeAsset = (index: number) => {
        this.setState(prevState => {
            const assets = [...(prevState.selectedAssets || [])];
            assets.splice(index, 1);
            return { selectedAssets: assets };
        });
    }

    handleCategoryChange = (value: CategoryList | null) => {
        if (value) {
            const selectedCategory = value.id.toString(); 
            const associatedGroup = this.state.allGroupList.find(
                (group) => group.attributes.category.data !== null && String(group.attributes.category.data.id) === selectedCategory
            ) || { id: '', type: '', attributes: { name: '', category: { data: { id: null } } } };
            this.setState({selectedCategoryValue:value, selectedCategory, editedGroup: associatedGroup, changedGroup: associatedGroup.id });
        } else {
            this.setState({filteredCategories:this.state.categoryList, selectedCategoryValue:{id:'',type:'',attributes:{name:''}},selectedCategory: '', editedGroup: { id: '', type: '', attributes: { name: '', category: { data: { id: null } } } }, changedGroup: '' });
        }
    };
    handleCategoryListResponse = (responseJson:{data:CategoryList[]} )=>{
        if(responseJson.data)
        this.setState({categoryList: responseJson.data,filteredCategories:responseJson.data})
    }
    getAllCategoryList = () => {
        const header = {
            "Content-Type": configJSON.apiContentType,
            token: this.token
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getAllCategoryAPICallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getAllCategoriesApiEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);

    }
    getAllRequesterList = () => {
        const header = {
            "Content-Type": configJSON.apiContentType,
            token: this.token
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getRequesterDataAPICallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getAllRequestersApiEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    getAllGroups = () => {
        const header = {
            "Content-Type": configJSON.apiContentType,
            token: this.token
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getAllGroupsAPICallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getAllGroupsApiEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    getAllAssets = () => {
        const header = {
            "Content-Type": configJSON.apiContentType,
            token: this.token
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getAllAssetsApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getAllAssetsApiEndpoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };


    getAllDepartment = () => {
        const header = {
            "Content-Type": configJSON.apiContentType,
            token: this.token
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getAllDepartmentAPICallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getAllDepartmentsApiEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    
    updateSubmiit = () => {
        const { endDate, title, startTime, endTime, description, startDate, selectedStatus } = this.state;
        let isError = false;
      
        const validations = [
          {
            field: selectedStatus.trim(),
            errorKey: "statusError",
            messageKey: configJSON.statusReq,
          },
          {
            field: title.trim(),
            errorKey: "titleError",
            messageKey: configJSON.titleReq,
            minLength: 5,
            messageKeyLength: configJSON.titleCharcter,
          },
          {
            field: description.trim(),
            errorKey: "descriptionError",
            messageKey: configJSON.descriptionRequired,
            minLength: 10,
            messageKeyLength: configJSON.descriptionCharcter,
          },
        ];
      
        validations.forEach((validation) => {
            const { field, errorKey, messageKey, minLength, messageKeyLength } = validation;
            if (!field) {
              this.setState((prevState) => ({ ...prevState, [errorKey]: messageKey }));
              isError = true;
            } else if (minLength && field.length < minLength) {
              this.setState((prevState) => ({ ...prevState, [errorKey]: messageKeyLength }));
              isError = true;
            } else {
              this.setState((prevState) => ({ ...prevState, [errorKey]: "" }));
            }
          })
      
        if (isError) {
          this.setState({ isValidationError: true });
          return;
        }
      
        this.setState({ isValidationError: false });
        this.updateTicketTemplate();
      };
    updateTicketTemplate = () => {
        this.setState({ isLoading:true})
        const header = {
            token: this.token,
        };

        // Prepare form data
        let formdata = new FormData();
        formdata.append("ticket_template[title]", this.state.title.trim());
        formdata.append("ticket_template[available_for]", this.state.availableFor);
        formdata.append("ticket_template[description]", this.state.generalDescription.trim());
        // Prepare start and end date-time
        const startDate_string = this.state.startDate;
        const startTime_string = this.state.startTime;
        if(startDate_string && startTime_string){
        const startDate_TimeString = `${startDate_string}T${startTime_string}`;
        const start_DateTime = new Date(startDate_TimeString);
        const startDateTime_ISOString = start_DateTime.toISOString();
        formdata.append("ticket_template[tickets_attributes][0][planned_startdate]",
             startDateTime_ISOString);
        }

        const endDate_String = this.state.endDate;
        const endTime_String = this.state.endTime;
        if(endDate_String && endTime_String){
        const endDate_TimeString = 
        `${endDate_String}T${endTime_String}`;
        const endDate_Time =
         new Date(endDate_TimeString);
        const endDate_TimeISOString = 
        endDate_Time.toISOString();
        formdata.append("ticket_template[tickets_attributes][0][planned_enddate]",
             endDate_TimeISOString);
        }
        // Ticket attributes
        formdata.append("ticket_template[tickets_attributes][0][id]", this.state.ticket_id);
        formdata.append("ticket_template[tickets_attributes][0][subject]", this.state.subject.trim());
        formdata.append("ticket_template[tickets_attributes][0][description]", this.state.description);
        formdata.append("ticket_template[tickets_attributes][0][department_id]", this.state.changedDepartment);
        formdata.append("ticket_template[tickets_attributes][0][category]", this.state.selectedCategory);
        formdata.append("ticket_template[tickets_attributes][0][tags]", this.state.selectedTags.join(','));
        formdata.append("ticket_template[tickets_attributes][0][priority]", this.state.selectedPriority);
        formdata.append("ticket_template[tickets_attributes][0][planned_effort]", this.state.timeDifference);
        formdata.append("ticket_template[tickets_attributes][0][status]", this.state.selectedStatus);
        formdata.append("ticket_template[tickets_attributes][0][source]", this.state.selectedSource);
        formdata.append("ticket_template[tickets_attributes][0][group_id]", this.state.changedGroup);
        formdata.append("ticket_template[tickets_attributes][0][agent_id]", this.state.changedAgent);
        if(this.state.selectedAssets.length >0){
            for (let i = 0; i < this.state.selectedAssets.length; i++){
                formdata.append("ticket_template[tickets_attributes][0][associate_assets][]", (this.state.selectedAssets[i].id))
            }
        }else  formdata.append("ticket_template[tickets_attributes][0][associate_assets]", '');
        
        // Attach file data if present
        if (this.state.fileData && this.state.fileData.length>0 ) {
            for (let i = 0; i < this.state.fileData.length; i++) {
              formdata.append("ticket_template[ticket_template_attachments][]", this.state.fileData[i] as unknown as Blob);
            }
          }



        // Prepare the request message
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

        this.updateFormApiId = requestMessage.messageId;

        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.updateTicketTemplateApiEndPoint + '/' + this.state.template_id
        );
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
            formdata
        );
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.patchApiMethod
        );
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
   
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    

    onFormSubmiit = () => {
        const { title , description, selectedStatus} = this.state;
        let isError = false;
        if (!title.trim()) {
            this.setState({ titleError: configJSON.titleReq });
            isError = true;
        } else if (title.length < 5) {
            this.setState({ titleError: configJSON.titleCharcter });
            isError = true;
        } else {
            this.setState({ titleError: '' });
        }
    
        // validate status
        if (!selectedStatus.trim()) {
            this.setState({ statusError: configJSON.statusReq });
            isError = true;
        } else {
            this.setState({ statusError: '' });
        }

        // Validate description
        if (!description.trim()) {
            this.setState({ descriptionError: configJSON.descriptionRequired });
            isError = true;
        } else if (description.length < 10) {
            this.setState({ descriptionError: configJSON.descriptionCharcter });
            isError = true;
        } else {
            this.setState({ descriptionError: '' });
        }

        if (isError) {
            this.setState({ isValidationError: true });
            return;
        }

        this.setState({ isValidationError: false });

        // If no validation errors, proceed with the form submission
        this.createTicket();
    };

    createTicket = () => {
        this.setState({ isLoading:true})
        const header = {
            token: this.token
        };

        let formdata = new FormData();
        formdata.append("ticket_template[title]",this.state.title.trim())
        formdata.append("ticket_template[available_for]",this.state.availableFor)
        formdata.append("ticket_template[description]", this.state.generalDescription.trim());
        formdata.append("ticket_template[tickets_attributes][0][subject]", this.state.subject.trim());
        formdata.append("ticket_template[tickets_attributes][0][description]",this.state.description)
        formdata.append("ticket_template[tickets_attributes][0][priority]", this.state.selectedPriority);
        formdata.append("ticket_template[tickets_attributes][0][planned_effort]", this.state.timeDifference);
        formdata.append("ticket_template[tickets_attributes][0][status]", this.state.selectedStatus);
        formdata.append("ticket_template[tickets_attributes][0][source]", this.state.selectedSource);
        formdata.append(`ticket_template[tickets_attributes][0][group_id]`, this.state.changedGroup);
        formdata.append(`ticket_template[tickets_attributes][0][agent_id]`, this.state.changedAgent);
        formdata.append(`ticket_template[tickets_attributes][0][department_id]`, this.state.changedDepartment);
        formdata.append("ticket_template[tickets_attributes][0][category]", this.state.selectedCategory);
        formdata.append("ticket_template[tickets_attributes][0][tags]", this.state.selectedTags.join(','));
        if(this.state.selectedAssets.length >0)
            {
            for (let i = 0; i < this.state.selectedAssets.length; i++){
                formdata.append("ticket_template[tickets_attributes][0][associate_assets][]",
                     (this.state.selectedAssets[i].id))
            }
        }

        if (this.state.fileData && this.state.fileData.length > 0) {
            for (let i = 0; i < this.state.fileData.length; i++) {
              formdata.append("ticket_template[ticket_template_attachments][]", this.state.fileData[i] as unknown as Blob);
            }
          }

        const startDateString = this.state.startDate; 
        const startTimeString = this.state.startTime;
        if(startDateString && startTimeString){
        const startDateTimeString = `${startDateString}T${startTimeString}`;
        const startDateTime = new Date(startDateTimeString);
        const startDateTimeISOString = startDateTime.toISOString();
        formdata.append("ticket_template[tickets_attributes][0][planned_startdate]", startDateTimeISOString);
        }

        const endDateString = this.state.endDate; 
        const endTimeString = this.state.endTime;
        if(endDateString && endTimeString){
        const endDateTimeString = `${endDateString}T${endTimeString}`;
        const endDateTime = new Date(endDateTimeString);
        const endDateTimeISOString = endDateTime.toISOString();
        formdata.append("ticket_template[tickets_attributes][0][planned_enddate]", endDateTimeISOString);
        }
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

        this.submitFormAPICallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.createTicketTemplate
        );
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
            formdata
        );
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }


    // API Responses




    handleGroupApiDataResponse = (responseJson: { data: AllGroupsList[] }) => {
        if (responseJson.data) {
            const groupData = responseJson.data.map((group) => ({
                id: group.id,
                type: 'someType',
                attributes: {
                    name: group.attributes.name || '',
                    category: group.attributes.category || '',
                    agents:group.attributes.agents
                }
            }));
            this.setState({
                allGroupList: groupData,
                filteredOptions: groupData
            })
        }
    }

    handleAgentsListResponse = (responseJson: { data: AllAgentsList[] }) => {
            const agentData = responseJson.data.map((agents) => ({
                id: agents.id,
                type:agents.type,
                attributes: {
                    full_name: agents.attributes.full_name || ''
                }
            }));
            this.setState({
                allAgentsList: agentData,
                filteredAgents: agentData
            })
        
    }

    handleDepartmentListResponse = (responseJson: { departments: {data: DepartmentList[]} }) => {

            const departmentData = responseJson.departments.data.map((department) => ({
                id: department.id,
                type: 'someType',
                attributes: {
                    department_name: department.attributes.department_name || ''
                }
            }));
            this.setState({
                allDepartmentList: departmentData,
                filteredDepartmentList: departmentData
            })
        
    }

    handleCreateTicketResponse = (responseJson: { data: TicketTemplate }) => {
        if (responseJson.data) {

            toast.success(configJSON.successTicketTemplateCreateMessage)
            this.setState({
                isValidationError: false,
                isLoading:true
            })
            setTimeout(() => {
                this.props.navigation.navigate("FormTemplates") 
            }, 3000);
        }
    }
    handleUpdateTicketResponse = (responseJson: { data: TicketTemplate }) => {
        if (responseJson.data) {
            toast.success(configJSON.successTicketTemplateUpdateMessage)
            this.setState({
                isValidationError: false,
                isLoading:true
            })
            setTimeout(() => {
                this.props.navigation.navigate("FormTemplates") 
            }, 3000);
        }else{
            toast.error(configJSON.errorTicketTemplateUpdate    )
        }
    }

    // Customizable Area End
}
