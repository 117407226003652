import React from "react";

// Customizable Area Start
import { CSSProperties } from "react";
import {
  Button,
  Typography,
  Box,
  styled,
  createTheme,
  ThemeProvider,
  AppBar,
  Tabs,
  Tab,
  Chip,
  TextField,
} from "@material-ui/core";
import {
  MoreVert,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@material-ui/icons";

import NavigationSideBar from "../../../components/src/NavigationSideBar";
import { TabPanel } from "../../user-profile-basic/src/AgentProfile.web";
import Loader from "../../../components/src/Loader";
import { Toaster } from "react-hot-toast";
import { cancelIcon, downArrow, searchIcon } from "./assets";

import EditIncidantTicket from "./EditIncidantTicket.web";
// Customizable Area End

import TaskController, { Props, configJSON } from "./TaskController";

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

const CustomChip = styled(Chip)({
  fontFamily: 'Manrope',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
  borderRadius: '4px !important',
  border: '0',
  margin: '1px 25px 15px',
  color: 'white'
})

const NoDataMessage = styled(Box)({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '14px',
  fontWeight: 'bold',
  color: '#33354D',
  padding: '2rem'
})

const TopCardMain = styled(Box)({
  border: '1px solid #E6DEED',
  borderRadius: '8px',
  '& .userImage': {
      height: '32px',
      width: '32px',
      borderRadius: '50%'
  },
  '& .card-top-bar': {
      display: 'flex',
      justifyContent: 'space-between',
      borderBottom: '1px solid #E6DEED',
      padding: '12px',
  },
  "& .card_desc": {
      fontSize: '16px',
      fontWeight: '400',
      color: '#33354D',
      padding: '12px',
  },
  '& .right-profile': {
      display: 'flex',
      alignItems: 'center',
      gap: '10px'
  }
})

// Customizable Area End

export default class Task extends TaskController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  returnNoDataMessage = (value: boolean) => {
    return value ? <NoDataMessage>{configJSON.noAgentDataMessage}</NoDataMessage> : null
}
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <>
      <NavigationSideBar active={'Settings'} navigation={this.props.navigation} />
      <Loader loading={this.state.isLoading} />
      <Toaster toastOptions={{
          duration: 1000
      }} />
      <ThemeProvider theme={theme}>
          <Box style={webStyles.main_container}>
              <Box
                  style={{
                      display: "flex",
                      justifyContent: "space-between",
                  }}
              >
                 <span 
                              style={webStyles.navigation}>
                                <span >Tickets
                
                                </span>
                                <span>
                                  {" > "}</span>
                                <span>
                                All Tickets</span>
                                <span>
                                  {" > "}</span>
                                <span >
                                Ticket Name</span>
                              </span>
                 
                  <Box style={webStyles.buttons_style}>
                      <Button style={{ ...webStyles.filled_button, textTransform: 'inherit' }} data-testID='delete-btn-id' onClick={() => this.handleDeleteTicketApiCall(this.state.ticketData.id)}>{configJSON.textCancel}</Button>
                      <span style={webStyles.more_icon_style}>
                          <MoreVert style={webStyles.moreIconStyle} />
                      </span>
                  </Box>
              </Box>

              <Box style={{ marginTop: "60px", marginBottom: "50px", }}>
                  <Box style={{ display: "flex", gap: "5px", }}>
                      <button style={webStyles.arrow}><KeyboardArrowLeft style={webStyles.arrow_icon} /></button>
                      <button style={webStyles.arrow}><KeyboardArrowRight style={webStyles.arrow_icon} /></button>
                  </Box>
                  <Box
                      style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                      <h1 style={webStyles.heading}>{this.state?.ticketData?.attributes?.subject}</h1>
                      <CustomChip variant="outlined" label={this.state?.ticketData?.attributes?.status} style={{ backgroundColor: this.getStateColor(this.state?.ticketData?.attributes?.status) }} />
                  </Box>
              </Box>

              <Box style={{ position: "relative", marginBottom: "32px", borderBottom: 'border-bottom: 1px solid #E6DEED' }}>
                  <AppBar position="static" style={{
                      color: '#fff',
                      backgroundColor: 'transparent',
                      boxShadow: 'none',
                      borderBottom: '2px solid #E6DEED'
                  }}>
                      <Tabs
                          value={this.state.selectedTab}
                          onChange={this.handleTabChange}
                          scrollButtons="on"
                          TabIndicatorProps={{
                              style: {
                                  background: '#B49CC9'
                              }
                          }}
                          aria-label="scrollable force tabs example"
                      >
                          <Tab style={webStyles.tab_style} label="General"   {...this.a11yProps(0)} />
                          <Tab style={webStyles.tab_style} label="Related tickets"   {...this.a11yProps(1)} data-test-id="relatedTickets" />
                          <Tab style={webStyles.tab_style} label="Tasks"   {...this.a11yProps(2)} />
                          <Tab style={webStyles.tab_style} label="Associations"   {...this.a11yProps(3)} />
                          <Tab style={webStyles.tab_style} label="Assets"   {...this.a11yProps(4)} />
                          <Tab style={webStyles.tab_style} label="Impacted services"   {...this.a11yProps(5)} />
                          <Tab style={webStyles.tab_style} label="Responders"   {...this.a11yProps(6)} />
                          <Tab style={webStyles.tab_style} label="Activities"   {...this.a11yProps(7)} />
                      </Tabs>
                  </AppBar>
              </Box>
              <Box style={{ display: "flex", gap: "20px" }}>
                  <Box flex={1}>
                      <Box style={webStyles.main_content_card}>
                          <TabPanel value={this.state.selectedTab} index={0}>

                              <Box style={webStyles.cardHead} >
                                  <Typography style={webStyles.cardHeading}>{configJSON.textDescription}</Typography>
                                  <CustomChip variant="outlined" label={this.state?.ticketData?.attributes?.priority} style={{ backgroundColor: this.getStateColor(this.state?.ticketData?.attributes?.priority) }} />
                              </Box>
                              <Box style={webStyles.card_body}>
                                  <TopCardMain>
                                      <Box className="card-top-bar">
                                          <Typography style={webStyles.table_heading}>
                                              {this.handleInvalidText(this.state?.ticketData?.attributes?.subject)}
                                          </Typography>

                                          <Box className="right-profile">
                                              <Box>
                                                  <Typography style={webStyles.card_profile_heading} >{this.state.ticketData.attributes?.group.name}</Typography>
                                                  <Typography style={webStyles.card_profile_heading2} >{this.state.ticketData.attributes?.requester.name}</Typography>
                                              </Box>
                                              <img className="userImage" src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?q=80&w=1374&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="" />
                                          </Box>
                                      </Box>
                                      <Typography className="card_desc">
                                          {this.state.ticketData.attributes?.description}
                                      </Typography>
                                  </TopCardMain>
                              </Box>
                              <Box style={webStyles.card_bottom_content}>
                                  <Button data-testId="modalOpen" style={{ ...webStyles.add_button, textTransform: 'inherit' }} onClick={() => this.handleDescModal(true)}>{configJSON.textEdit}</Button>
                              </Box>

                          </TabPanel>
                          <TabPanel value={this.state.selectedTab} index={1}>

                              {this.state.relatedTickets.length > 0 && <><Box style={webStyles.cardHead} >
                                  <Typography style={webStyles.cardHeading}>Related Tickets</Typography>
                              </Box>
                                  <div style={{ maxHeight: '700px', overflow: 'auto' }}>
                                      {this.state.relatedTickets.map((item, index) => (
                                          <Box style={webStyles.card_body}>
                                              <TopCardMain sx={{ border: 'none !important' }}>
                                                  <Box className="card-top-bar" sx={{ display: 'flex', alignItems: 'center' }}>
                                                      <Typography style={webStyles.table_heading}>
                                                          {item.attributes.subject}
                                                      </Typography>

                                                      <div style={rightBox}>
                                                          <img className="userImage" src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?q=80&w=1374&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="" />
                                                          <div style={webStyles.textBox}><Typography style={webStyles.card_profile_heading} >{this.state.ticketData.attributes?.group.name}</Typography>
                                                              <Typography style={webStyles.card_profile_heading2} >{item.attributes.agent.name}</Typography> </div>
                                                          <div style={webStyles.dpartmentBox}>
                                                              <p style={webStyles.table_heading}>{item.attributes.department.name}</p>
                                                          </div>


                                                      </div>
                                                  </Box>
                                              </TopCardMain>
                                          </Box>))}
                                  </div>

                                  <Box style={webStyles.card_bottom_content}>
                                      <Button data-test-id="modalOpenAdd" style={{ ...webStyles.add_button, textTransform: 'inherit' }} onClick={() => this.openRightPopUp()}>{"Add child tickets"}</Button>
                                  </Box>
                              </>}


                              {this.state.relatedTickets.length == 0 && <><Box style={webStyles.cardHead} >
                                  <Typography style={webStyles.cardHeading}>Related Tickets</Typography>
                              </Box>
                                  <Box style={webStyles.card_body} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '8rem' }}>
                                      <p style={webStyles.noMessage}>There are no related tickets</p>
                                  </Box>
                                  <Box style={webStyles.card_bottom_content}>
                                      <Button data-test-id="modalOpenNoResult" style={{ ...webStyles.add_button, textTransform: 'inherit' }} onClick={() => this.openRightPopUp()}>{"Add child tickets"}</Button>
                                  </Box></>
                              }

                          </TabPanel>

                          <TabPanel value={this.state.selectedTab} index={2}>

                              <Box style={webStyles.cardHead} >
                                  <Typography style={webStyles.cardHeading}>Tasks</Typography>

                              </Box>
                              <Box style={webStyles.card_body}>
                                  <div style={{ maxHeight: '700px', overflow: 'auto' }}>
                                      <TopCardMain>
                                          {this.state.taskList.map((task, index) => (
                                              <>
                                                  <Box className="card-top-bar" style={{ alignItems: 'center' }}>
                                                      <div style={webStyles.taskLeft}>
                                                          <Typography style={webStyles.table_heading}>
                                                              {task.attributes.title}
                                                          </Typography>
                                                          <p style={webStyles.taskDesc}>
                                                              {task.attributes.note}
                                                          </p>
                                                      </div>

                                                      <div style={webStyles.stateWrapper}>
                                                          <p style={webStyles.stateText}>{task.attributes.status}</p>
                                                          <img src={downArrow} style={{ height: '14px', width: '14px' }}></img>
                                                      </div>

                                                      <Box className="right-profile">
                                                          <Box sx={{ display: 'flex', flexDirection: 'row', width: '70px' }}>
                                                              <Typography style={webStyles.card_profile_heading} >{task.attributes.department?.name}</Typography>
                                                              <Typography style={webStyles.card_profile_heading2} >{task.attributes.agent?.name}</Typography>
                                                          </Box>
                                                          <img className="userImage" src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?q=80&w=1374&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="" />
                                                      </Box>
                                                  </Box>
                                              </>
                                          ))}
                                      </TopCardMain>
                                  </div>
                              </Box>
                              <Box style={webStyles.card_bottom_content}>
                                  <Button data-test-id="modalTask" style={{ ...webStyles.add_button, textTransform: 'inherit' }} onClick={() => this.openModal()}>Add Task</Button>
                              </Box>

                          </TabPanel>


                      </Box>
                  </Box>
                  <Box style={webStyles.right_box}>
                      <Typography style={webStyles.right_menu_heading}>{configJSON.textDetails}</Typography>
                      <Box mt={'2rem'}>
                          <Box>
                              <Typography style={webStyles.list_heading}>{configJSON.Customizable}</Typography>
                              <ul style={webStyles.user_info_main}>
                                  <li style={webStyles.user_info_list}>
                                      <Typography style={webStyles.title_1}>{configJSON.textStatus}</Typography>
                                      <Typography style={webStyles.title_2}>{this.handleInvalidText(this.state.ticketData?.attributes?.status)} </Typography>
                                  </li>
                                  <li style={webStyles.user_info_list}>
                                      <Typography style={webStyles.title_1}>{configJSON.textPriority}</Typography>
                                      <Typography style={webStyles.title_2}>{this.handleInvalidText(this.state.ticketData?.attributes?.priority)}</Typography>
                                  </li>
                                  <li style={webStyles.user_info_list}>
                                      <Typography style={webStyles.title_1}>{configJSON.textResolutionDate}</Typography>
                                      <Typography style={webStyles.title_2}>{this.handleInvalidText('18 Aug 2023')} </Typography>
                                  </li>
                                  <li style={webStyles.user_info_list}>
                                      <Typography style={webStyles.title_1}>{configJSON.textResolutionTime}</Typography>
                                      <Typography style={webStyles.title_2}>{this.handleInvalidText('11:00 PM')} </Typography>
                                  </li>

                                  <Typography style={webStyles.viewBtnStyle}>
                                      {configJSON.textTimeRntries}
                                  </Typography>
                              </ul>
                          </Box>

                          <Box mt={'32px'}>
                              <Typography style={webStyles.list_heading}>{configJSON.textRequesterinformation}</Typography>
                              <ul style={webStyles.user_info_main}>
                                  <li style={webStyles.user_info_list}>
                                      <Typography style={webStyles.title_1}>{configJSON.textRequester}</Typography>
                                      <Typography style={webStyles.title_2}>{this.handleInvalidText(this.state.ticketData.attributes?.requester.name)} </Typography>
                                  </li>
                                  <li style={webStyles.user_info_list}>
                                      <Typography style={webStyles.title_1}>{configJSON.textPrimaryEmail}</Typography>
                                      <Typography style={webStyles.title_2}>{this.handleInvalidText(this.state.ticketData.attributes?.requester.email)} </Typography>
                                  </li>

                                  <Typography style={webStyles.viewBtnStyle}>{configJSON.textViewTickets} </Typography>
                              </ul>
                          </Box>

                          <Box mt={'32px'}>
                              <Typography style={webStyles.list_heading}>{configJSON.textProperties}</Typography>
                              <ul style={webStyles.user_info_main}>
                                  <li style={webStyles.user_info_list}>
                                      <Typography style={webStyles.title_1}>{configJSON.textSource}</Typography>
                                      <Typography style={webStyles.title_2}>{this.handleInvalidText(this.state.ticketData?.attributes?.source)} </Typography>
                                  </li>
                                  <li style={webStyles.user_info_list}>
                                      <Typography style={webStyles.title_1}>{configJSON.textType}</Typography>
                                      <Typography style={webStyles.title_2}>{this.handleInvalidText('Incident')} </Typography>
                                  </li>
                                  <li style={webStyles.user_info_list}>
                                      <Typography style={webStyles.title_1}>Group</Typography>
                                      <Typography style={webStyles.title_2}>{this.handleInvalidText(this.state.ticketData?.attributes?.group.name)} </Typography>
                                  </li>
                                  <li style={webStyles.user_info_list}>
                                      <Typography style={webStyles.title_1}>Agent</Typography>
                                      <Typography style={webStyles.title_2}>{this.handleInvalidText(this.state.ticketData?.attributes?.agent?.name)} </Typography>
                                  </li>
                                  <li style={webStyles.user_info_list}>
                                      <Typography style={webStyles.title_1}>{configJSON.textDepartment}</Typography>
                                      <Typography style={webStyles.title_2}>{this.handleInvalidText(this.state.ticketData?.attributes?.department?.name)} </Typography>
                                  </li>
                                  <li style={webStyles.user_info_list}>
                                      <Typography style={webStyles.title_1}>{configJSON.textCategory}</Typography>
                                      <Typography style={webStyles.title_2}>{this.handleInvalidText(this.state.ticketData?.attributes?.category.data.attributes.name)} </Typography>
                                  </li>
                                  <li style={webStyles.user_info_list}>
                                      <Typography style={webStyles.title_1}>{configJSON.textPlannedStartDate}</Typography>
                                      <Typography style={webStyles.title_2}>{this.handleInvalidText(
                                          this.convertStringToDate(this.state.ticketData?.attributes?.planned_startdate)?.date)
                                      } </Typography>
                                  </li>
                                  <li style={webStyles.user_info_list}>
                                      <Typography style={webStyles.title_1}>Planned effort</Typography>
                                      <Typography style={webStyles.title_2}>{this.handleInvalidText(this.state.ticketData?.attributes?.planned_effort)
                                      } </Typography>
                                  </li>
                                  <li style={webStyles.user_info_list}>
                                      <Typography style={webStyles.title_1}>{configJSON.textTags}</Typography>
                                      <Typography style={webStyles.title_2}>{this.handleInvalidText(this.state.ticketData?.attributes?.tags)} </Typography>
                                  </li>
                                  <li style={webStyles.user_info_list}>
                                      <Typography style={webStyles.title_1}>{configJSON.textPlannedEndDate}</Typography>
                                      <Typography style={webStyles.title_2}>{this.handleInvalidText(
                                          this.convertStringToDate(this.state.ticketData?.attributes?.planned_enddate)?.date)
                                      } </Typography>
                                  </li>
                                  <li style={webStyles.user_info_list}>
                                      <Typography style={webStyles.title_1}>{configJSON.textTags}</Typography>
                                      <Typography style={webStyles.title_2}>{this.handleInvalidText(this.state.ticketData?.attributes?.tags)} </Typography>
                                  </li>

                              </ul>

                          </Box>
                      </Box>
                      <Box
                          style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              marginTop: "30px",
                          }}>
                          <Button onClick={this.handleOpenFormModal} data-testId={"formModal"} style={{ ...webStyles.add_button, textTransform: 'inherit' }}>{configJSON.textEdit}</Button>                                </Box>
                  </Box>

              </Box>
          </Box>
          {this.state.rightPopUp &&
              <div style={webStyles.rightPopUp}>
                  <div style={webStyles.popupTitleAndCloseWrapper}>
                      <p style={webStyles.title}>Child Tickets</p>
                      <img src={cancelIcon} data-test-id="rightClose" style={{ height: '25px', width: '25px' }} onClick={() => { this.setState({ rightPopUp: false }) }}>

                      </img>
                  </div>
                  <div style={webStyles.popupTitleAndCloseWrapper}>
                      <span style={webStyles.searchWrapper}><img style={webStyles.adornment} src={searchIcon}></img><input type="text" placeholder="Search assets" style={webStyles.search}></input></span>
                      <div style={{ position: 'relative', display: 'flex' }}>
                          <div style={webStyles.dropDown}>All Ticket</div>
                          <select></select>
                          <div style={webStyles.rightPart}><img src={downArrow} style={{ height: "14px", width: "14px" }}></img></div>
                      </div>
                  </div>

                  <div style={webStyles.tableContainer}>
                      <div style={webStyles.tableHead}>
                          <div style={webStyles.leftContainer}>
                              <input type="checkbox" data-test-id="tableCheck" className="checkbox" style={{ height: '24px', width: '24px' }} onChange={(event) => this.globalCheckBoxClick(event)}></input>
                              <div style={webStyles.subjectText}>Subject</div>
                          </div>

                          <div style={webStyles.rightContainer}>
                              <div style={webStyles.requesterText}><p style={{ color: 'grey' }} > Requester </p></div>
                              <div style={webStyles.requesterText}><p style={{ color: 'grey' }} >Department </p></div>
                          </div>
                      </div>

                      {this.state.allTickets.map((item, index) => (<div style={webStyles.tableHead}>
                          <div style={webStyles.leftContainer}>
                              <input type="checkbox" data-test-id={"rowCheck" + index} className="checkbox" style={{ height: '24px', width: '24px' }} onChange={(event) => this.checkBoxHandle(event, Number(item.id))}></input>
                              <div style={webStyles.subjectDesc}>{item.attributes.subject}</div>
                          </div>

                          <div style={webStyles.rightContainer}>
                              <div style={webStyles.requesterText}>
                                  <div style={{ display: "flex", alignItems: 'center' }}>
                                      <img style={{ height: '44px', width: '44px', borderRadius: '50%' }} className="userImage" src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?q=80&w=1374&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="" />
                                      <div style={webStyles.textBox}><Typography style={webStyles.card_profile_heading} >{this.state.ticketData.attributes?.group.name}</Typography>
                                          <Typography style={webStyles.card_profile_heading2} >{this.state.ticketData.attributes?.requester.name}</Typography> </div>



                                  </div>
                              </div>
                              <div style={webStyles.requesterDesc}>{item.attributes.department?.name}</div>
                          </div>
                      </div>))}



                  </div>
                  <div style={webStyles.bottomButtons}>

                      <Button className="cancelBtn" data-test-id="cancelRel" onClick={() => this.setState({ rightPopUp: false })}>Cancel</Button>
                      <Button type="reset" style={webStyles.save_button} data-test-id='saveAs' onClick={() => this.addTickets()}>
                          Save as child ticket
                      </Button>

                  </div>

              </div>
          }

          {this.state.sidePopup ? <><div style={webStyles.modalStyle} id="myModal">
              <div style={webStyles.topContainer}>
                  <p style={webStyles.addTitle}>
                      Add Task
                  </p>

                  <img src={cancelIcon} style={{ height: '14px', width: '14px' }} data-test-id="closeTask" onClick={() => this.setState({ sidePopup: false })}></img>
              </div>
              <div style={webStyles.dataContainer}>
                  <div style={webStyles.leftControl}>
                      <div style={webStyles.fieldContainer}>
                          <div style={webStyles.labelText}><p style={webStyles.textStyle}> </p>Title <span style={{ color: 'red' }}>*</span></div>
                          <input type="text" style={webStyles.textInput} value={this.state.titleText} data-test-id="title" onChange={(event) => this.handleText(event, "title")}></input>
                      </div>       <div style={webStyles.fieldContainer}>
                          <div style={webStyles.labelText}><p style={webStyles.textStyle}> </p>Group</div>
                          <select style={webStyles.textInput} value={this.state.selectedGroup} data-test-id="group" onChange={(event) => this.handleSelect(event, 'group')}></select>
                      </div>
                      <div style={webStyles.fieldContainer}>
                          <div style={webStyles.labelText}><p style={webStyles.textStyle}> </p>Assign To</div>

                          <select style={webStyles.textInput} value={this.state.selectedAssignee} data-test-id="assignTo" onChange={(event) => this.handleSelect(event, 'assignee')}>
                              <option selected>Select Assignee</option>
                              {this.state.agentsList.map((agent, index) => (
                                  <option value={agent.id}>{agent.attributes.full_name}</option>
                              ))}
                          </select>
                      </div>
                      <div style={webStyles.fieldContainer}>
                          <div style={webStyles.labelText}><p style={webStyles.textStyle}> </p>Status</div>
                          <select style={webStyles.textInput} value={this.state.status} data-test-id="status" onChange={(event) => this.handleSelect(event, 'status')}>
                              <option selected>Select Status</option>
                              <option value={"open"}>Open</option>
                              <option value={"pending"}>Pending</option>
                              <option value={"resolved"}>Resolved</option>
                              <option value={"closed"}>Closed</option>
                          </select>
                      </div>
                      <div style={webStyles.fieldContainer}>
                          <div style={webStyles.labelText}><p style={webStyles.textStyle}> </p>Assign before</div>
                          <select style={webStyles.textInput} value={this.state.assignDuration} data-test-id="assign-before" onChange={(event) => this.handleSelect(event, 'duration')}>
                              <option selected>Select Duration</option>
                              <option value={"15 minutes"}>15 minutes</option>
                              <option value={"30 minutes"}>30 minutes</option>
                          </select>
                      </div>
                  </div>


                  <div style={webStyles.rightControl}>
                      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} >
                          <div style={webStyles.fieldContainer}>

                              <div style={webStyles.labelText}><p style={webStyles.textStyle}> </p>Planned Start Date</div>
                              <div style={{ display: 'flex', flexFlow: 'row', width: '100%', gap: '2px' }}>
                                  <TextField
                                      id="date"
                                      type="date"
                                      variant="outlined"
                                      placeholder="DD-MM-YYYY"
                                      onChange={(event) => this.handleDate(event, 'start')}
                                      value={this.state.plannedStartDate}
                                      data-test-id="start-date"
                                  />

                                  <TextField
                                      id="date"
                                      type="time"
                                      variant="outlined"
                                      placeholder="DD-MM-YYYY"
                                      onChange={(event) => this.handleTime(event, 'start')}
                                      value={this.state.plannedStartTime}
                                      data-test-id="start-time"
                                  />
                              </div>
                          </div>
                      </Box>


                      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} >
                          <div style={webStyles.fieldContainer}>
                              <div style={webStyles.labelText}><p style={webStyles.textStyle}> </p>Planned End Time</div>
                              <div style={{ display: 'flex', flexFlow: 'row', width: '100%', gap: '2px' }}>
                                  <TextField
                                      id="date"
                                      type="date"
                                      variant="outlined"
                                      placeholder="DD-MM-YYYY"
                                      onChange={(event) => this.handleDate(event, 'end')}
                                      value={this.state.plannedEndDate}
                                      data-test-id="end-date"
                                  />

                                  <TextField
                                      id="date"
                                      type="time"
                                      variant="outlined"
                                      placeholder="DD-MM-YYYY"
                                      onChange={(event) => this.handleTime(event, 'end')}
                                      value={this.state.plannedEndTime}
                                      data-test-id="end-time"
                                  />
                              </div>
                          </div>
                      </Box>
                      <div style={webStyles.fieldContainer}>
                          <div style={webStyles.labelText}><p style={webStyles.textStyle}> </p>Planned effort</div>
                          <input type="text" style={webStyles.textInput} value={this.state.plannedEffort} data-test-id="planned-effort" onChange={(event) => this.handleText(event, 'plannedEffort')}></input>
                      </div>
                      <div style={webStyles.fieldContainer}>
                          <div style={webStyles.labelText}><p style={webStyles.textStyle}> </p>Note</div>
                          <textarea data-test-id="note" style={webStyles.textArea} maxLength={100} value={this.state.note} onChange={(event) => this.handleText(event, 'note')}></textarea>
                      </div>

                  </div>
              </div>
              <div style={webStyles.bottomButtons}>



                  <Box sx={{ display: 'flex', justifyContent: 'end', marginTop: '20px' }}>
                      <Button type="reset" style={webStyles.cancel_button} data-test-id='modal_cancel_btn' onClick={() => this.setState({ sidePopup: false })}>
                          {configJSON.cancelBTN}
                      </Button>
                      <Button type="submit" style={webStyles.save_button} data-test-id="saveButton" onClick={() => this.save()}>Add Task</Button>
                  </Box>
              </div>
          </div>


              <div style={webStyles.overlayStyle} id="overlay"></div>

          </> : null
          }

          <EditIncidantTicket
              navigation={this.props.navigation}
              openDescModal={this.state.openDescModal}
              handleModalState={this.handleModalState}
              navigateUser={this.navigateUser}
              openFormModal={this.state.isOpenFormModal}
              handleCloseFormModal={this.handleCloseFormModal}
              data-testId={"NavigateToEditTicket"}
          />
      </ThemeProvider >
  </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles = {
  tableContainer: {
      margin: '1rem',
      display: 'flex',
      flexFlow: 'column',
      width: '100%',
      marginTop: '4rem'
  },

  fieldContainer: {
      display: 'flex',
      flexFlow: 'column',
      width: '100%',
      gap: '2px'
  },

  labelText: {
      fontFamily: 'Manrope',
      fontSize: '12px',
      fontWeight: 500,
      lineHeight: '16.39px',
  },

  textInput: {
      width: '100%',
      background: 'white',
      padding: '10px 8px',  // Set padding with shorthand notation (top/bottom: 10px, left/right: 8px)
      gap: '0px',  // Set gap between elements (if using Flexbox or Grid layout)
      borderRadius: '8px',  // Set border radius for top-left corner
      border: '1px solid #E6DEED'
  },

  textArea: {
      width: '100%',
      padding: '10px 8px',  // Set padding with shorthand notation (top/bottom: 10px, left/right: 8px)
      gap: '0px',  // Set gap between elements (if using Flexbox or Grid layout)
      borderRadius: '8px',  // Set border radius for top-left corner
      border: '1px solid #E6DEED',
      height: '188px'
  },
  textStyle: {
      fontFamily: 'Manrope',
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '22.4px',
  },

  dataContainer: {
      display: 'flex',
      width: '100%',
      height: '80%',
      flexFlow: 'row',
      gap: '20px'
  },

  leftControl: {
      display: 'flex',
      flexFlow: 'column',
      width: '50%',
  },

  rightControl: {
      display: 'flex',
      flexFlow: 'column',
      width: '50%',
  },

  // Styles for the modal overlay
  overlayStyle: {
      position: 'fixed' as CSSProperties['position'],
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black
      zIndex: 999 // Ensure it is below the modal
  },

  // Styles for the modal container
  modalStyle: {
      position: 'fixed' as CSSProperties['position'],
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: '#fff',
      padding: '20px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
      borderRadius: '8px',
      width: '70%',
      zIndex: 1000 // Ensure modal is on top
  },

  topContainer: {
      width: '100%',
      height: '32px',
      display: 'flex',
      justifyContent: 'space-between',
      gap: '0px',
      alignItems: 'center',
      marginBottom: '1.5rem'
  },
  addTitle: {
      fontFamily: 'Manrope',
      fontSize: '20px',
      fontWeight: 700 as CSSProperties['fontWeight'],
      lineHeight: '27.32px',
      // textAlign: 'left',
      color: "var(--Text-primary, #33354D)"
  },
  modalconatiner: {

      height: '712px',
      padding: '24px 0px 0px 0px',
      gap: '36px',
      borderRadius: '8px 0px 0px 0px',
      opacity: 0,
      background: "#ffffff",
      position: 'fixed' as CSSProperties['position'],
      top: 0,
      left: 0,
      width: '100%',
      zIndex: 1000
  },
  stateText: {
      fontFamily: 'Manrope',
      fontSize: '12px',
      fontWeight: 600,
      lineHeight: '16.39px',
      color: '#FFFFFF'
  },
  taskDesc: {
      color: 'var(--Text-tertiary, #A3A6C2)',
      fontFamily: 'Manrope',
      fontSize: '14px',
      fontWeight: 600 as CSSProperties['fontWeight'],
      lineHeight: '19.12px',
  },
  taskLeft: {
      width: '50%',
      display: 'flex',
      flexFlow: 'column',
      paddingLeft: '2px'
  },
  stateWrapper: {
      height: '24px',
      width: '104px',
      borderRadius: '4px',
      background: '#11A64A',
      display: 'flex',
      flexFlow: 'row',
      alignItems: 'center',
      justifyContent: 'space-around'
  },
  rightPart: {
      height: '1rem',
      width: '0.5rem',
      backgroundColor: "#E6DEED",
      borderRadius: "4px",
      padding: "10px 16px",
      fontSize: "16px",
      color: "#33354D",
      position: 'absolute' as CSSProperties['position'],
      right: 0
  },
  dropDown: {
      height: '1rem',
      width: '5rem',
      backgroundColor: "#E6DEED",
      padding: "10px 16px",
      fontSize: "16px",
      color: "#33354D",
      fontWeight: "bolder" as CSSProperties['fontWeight'],
      fontFamily: "Manrope",
      letterSpacing: "0em",
      lineHeight: "16px",
      borderRadius: "4px",
  },
  save_button: {
      color: "#FFF",
      padding: "10px 16px",
      backgroundColor: "#8760A9",
      fontSize: "16px",
      fontWeight: 600,
      fontFamily: "Manrope",
      letterSpacing: "0em",
      lineHeight: "16px",
      textTransform: "initial",
      borderRadius: "4px",
  } as const,
  bottomButtons: {
      margin: '1rem 1rem 0rem 1rem',
      display: 'flex',
      justifyContent: 'flex-end'
  },
  tableHead: {
      width: '100%',
      minHeight: '5rem',
      display: 'flex',
      flexFlow: 'row',
      borderBottom: '1px rgb(163, 166, 194) solid'
  },
  leftContainer: {
      width: "50%",
      display: 'flex',
      alignItems: 'center'
  },
  rightContainer: {
      width: '50%',
      display: 'flex',
      alignItems: 'center'
  },
  subjectText: {
      marginLeft: '2rem',
      width: 'inherit',
      color: 'grey',
      fontSize: "18px",
      fontFamily: 'Manrope',
      fontStyle: "normal",
      lineHeight: "normal",
  } as const,
  subjectDesc: {
      marginLeft: '2rem',
      width: 'inherit',
      lineHeight: "18px",
      fontSize: "18px",
      color: "#33354D",
      fontFamily: 'Manrope',
      fontStyle: "normal"
  } as const,
  requesterText: {
      width: '50%',
      lineHeight: "18px",
      fontSize: "18px",
      fontFamily: 'Manrope',
      fontStyle: "normal",
  },
  requesterDesc: {
      lineHeight: "18px",
      fontSize: "18px",
      color: "#33354D",
      fontFamily: 'Manrope',
      fontStyle: "normal"
  },
  searchWrapper: {
      height: 'auto',
      position: 'relative' as CSSProperties['position'],
      display: 'flex',
      alignItems: 'center'
  },
  rightPopUp: {
      position: 'absolute' as CSSProperties['position'],
      right: 0,
      zIndex: '9999' as CSSProperties['zIndex'],
      height: '120%',
      width: '50%',
      background: '#ffffff',
      display: 'flex',
      flexFlow: 'column',
      boxShadow: '-5px 0 8px rgba(0, 0, 0, 0.3)'
  },
  popupTitleAndCloseWrapper: {
      display: 'flex',
      flexFlow: 'row',
      justifyContent: 'space-between',
      margin: '1rem',
      alignItems: 'center'
  },
  adornment: {
      position: 'absolute' as CSSProperties['position'],
      left: 0,
      height: '20px',
      width: '20px',
      paddingLeft: '6px'
  },
  title: {
      color: "#33354D",
      fontWeight: 'bold' as CSSProperties['fontWeight'],
      fontSize: "20px",
      fontFamily: 'Manrope',
      fontStyle: "normal",
      lineHeight: "normal",
  },
  search: {
      height: "2rem",
      width: "12rem",
      borderRadius: "0.5rem",
      border: '1px grey solid',
      paddingLeft: "30px"
  },
  close: {
      alignSelf: 'flex-end',
      margin: '1rem'
  },
  main_container: {
      paddingBottom: "25px",
      margin: "15px 15px 15px 300px",
      height: "100%",
      fontFamily: "Manrope",
  } as const,
  heading: {
      lineHeight: "40px",
      fontSize: "30px",
      color: "#33354D",
      marginBottom: '0',
      marginTop: "10px",
      fontWeight: "bold",
  } as const,
  arrow_icon: {
      width: "20px",
      height: "20px",
      color: "#33354D",
  } as const,
  setting: {
      display: "flex",
      justifyItems: "center",
      alignItems: "center",
      height: "36px",
      width: "36px",
      backgroundColor: "#E6DEED",
      borderRadius: "4px",
  } as const,
  modal_button_main_box: {
      display: 'flex',
      float: 'right',
      gap: '1rem',
  } as const,
  main_content_card: {
      borderRadius: "8px",
      flex: "1",
      boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px',
  } as const,
  edit_button: {
      backgroundColor: "#E6DEED",
      padding: "10px 16px",
      fontSize: "16px",
      color: "#33354D",
      fontWeight: "bolder",
      fontFamily: "Manrope",
      letterSpacing: "0em",
      lineHeight: "16px",
      borderRadius: "4px",
      textTransform: "initial",
  } as const,
  right_box: {
      flexBasis: "30%",
      boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px',
      borderRadius: "8px",
      padding: "1rem",
  } as const,
  cancel_button: {
      fontSize: "16px",
      padding: "10px 16px",
      backgroundColor: "transparent",
      fontFamily: "Manrope",
      color: "#8760A9",
      fontWeight: 700,
      letterSpacing: "0em",
      lineHeight: "16px",
      textTransform: "initial",
      borderRadius: "4px",
  } as const,
  navigation: {
    color: '#3D405C',
    display:'flex',
    alignItems:'center',
    gap:'8px',
    fontSize: '14px',
    fontFamily: 'Manrope',
    fontWeight: 400,
    fontStyle: 'normal',
    lineHeight: 'normal',
  } as const,
  cardHead: {
      display: "flex",
      justifyContent: 'space-between',
      alignItems: "center",
      gap: "5px",
  } as const,
  buttons_style: {
      display: 'flex',
      borderRadius: '5px',
      gap: '1rem',
  } as const,
  dot_style: {
      width: '16px',
      height: '16px',
      borderRadius: '50%',
      background: '#C1BBEB',
      margin: '0 0 10px 10px'
  } as const,
  moreIconStyle: {
      width: '24px',
      height: '24px',
      color: 'black',
      padding: '5px'
  } as const,
  tab_style: {
      fontSize: '16px',
      fontStyle: 'normal',
      color: '#B49CC9',
      lineHeight: 'normal',
      fontWeight: 600,
      textTransform: 'inherit',
      minWidth: '135px'
  } as const,
  user_info_list: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '12px'
  } as const,
  user_info_main: {
      listStyle: 'mone',
      padding: '0'
  } as const,
  title_1: {
      fontSize: '14px',
      color: '#A3A6C2',
      fontWeight: 600
  } as const,
  cardHeading: {
      fontSize: '20px',
      color: '#33354D',
      fontStyle: 'normal',
      fontWeight: 700
  } as const,
  title_2: {
      color: '#33354D',
      fontSize: '14px',
      fontWeight: 700
  } as const,
  card_body: {
      marginTop: '30px',
  } as const,
  add_button: {
      padding: "10px 16px",
      color: "#33354D",
      backgroundColor: "#E6DEED",
      fontFamily: "Manrope",
      fontSize: "16px",
      fontWeight: 'bold',
      borderRadius: "4px",
      lineHeight: "100%",
  } as const,
  top_menu_items: {
      padding: '9px 20px 9px 16px',
      color: '#33354D',
      fontSize: '14px',
      fontWeight: 400,
      lineGeight: '22px',
      width: '192px'
  } as const,
  filled_button: {
      color: "#FFF",
      backgroundColor: "#8760A9",
      fontSize: "16px",
      fontWeight: 700,
      padding: "10px 16px",
      fontFamily: "Manrope",
      lineHeight: "16px",
      borderRadius: "4px",
  } as const,
  more_icon_style: {
      height: '36px',
      width: '36px',
      background: '#E6DEED',
      cursor: 'pointer',
      borderRadius: '4px'
  } as const,
  right_menu_heading: {
      fontSize: '20px',
      color: '#33354D',
      fontWeight: 700,
  } as const,
  list_heading: {
      color: '#33354D',
      fontSize: '11px',
      fontWeight: 700,
  } as const,
  labelStyle: {
      fontSize: '12px',
      display: 'flex',
      alignItems: 'center',
      fontWeight: 500,
      marginBottom: '4px',
      gap: '4px',
  } as const,
  hiddenLabelStyle: {
      fontWeight: 500,
      fontSize: '12px',
      opacity: 0,
      padding: '4px',
  } as const,
  labelIcon: {
      fontSize: '16px',
      color: '#8760A9'
  } as const,
  removeIconsStyle: {
      color: '#8760A9',
      cursor: "pointer",
      marginLeft: '10px'
  } as const,
  addButtonStyle: {
      fontSize: '14px',
      color: '#8760A9',
      fontWeight: 700,
  } as const,
  dropdownStyle: {
      height: '44px',
      marginBottom: '4px',
      maxWidth: '90%'
  } as const,
  defualtSelectedMenu: {
      fontSize: '16px',
      color: '#A3A6C2',
      fontWeight: 400
  } as const,
  cancelIoncStyle: {
      height: '30px',
      width: '30px',
      cursor: "pointer",
  } as const,
  flexWithAlign: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
  } as const,
  table_heading: {
      color: "#33354D",
      fontSize: "18px",
      fontFamily: 'Manrope',
      fontWeight: 'bold',
      fontStyle: "normal",
      lineHeight: "normal",
  } as const,
  card_profile_heading: {
      color: "#A3A6C2",
      fontWeight: 'bold',
      fontSize: "11px",
      fontStyle: "normal",
      lineHeight: "normal",
      fontFamily: 'Manrope',
      textTransform: 'uppercase'
  } as const,
  card_profile_heading2: {
      color: "#33354D",
      fontWeight: 'bold',
      fontSize: "14px",
      fontFamily: 'Manrope',
      fontStyle: "normal",
      lineHeight: "normal",
  } as const,
  card_bottom_content: {
      display: 'flex',
      marginTop: "42px",
      justifyContent: 'end'
  } as const,
  arrow: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      border: "1px solid #E6DEED",
      cursor: "pointer",
      width: "28px",
      height: "28px",
      backgroundColor: "white",
      borderRadius: "100px",
  } as const,
  viewBtnStyle: {
      fonFamily: 'Manrope',
      fontSize: '12px',
      fontStyle: 'normal',
      color: '#8760A9',
      fontWeight: 700,
      lineHeight: '18px',
      textAlign: 'end',
      cursor: "pointer",
  } as const,
  input_label: {
      marginBottom: "5px",
  } as const,
  settings_icon: {
      color: "black",
      height: "24px",
      width: "24px",
  } as const,
  rightBox: {
      width: "50%",
      display: "flex",
      position: 'relative'

  },
  textBox: {
      display: 'flex',
      flexFlow: 'column',
      marginLeft: '0.5rem'
  },

  dpartmentBox: {
      marginLeft: '1rem',
      position: '"absolute"' as CSSProperties['position'],
      right: 2,
  } as const,

  noMessage: {
      fontSize: '14px',
      color: '#33354D',
      fontWeight: 600,
      fontFamily: "Manrope"
  },
};

const rightBox = {
  width: "55%",
  display: "flex",
  alignItems: 'center'
}
// Customizable Area End
