import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData } from "../../../framework/src/Utilities";
import toast from "react-hot-toast";
interface SLAAndOLAPolicyAttributes {
  id: number;
  name: string;
  description: string;
  priority: string;
  first_response: string;
  resolution_time: string;
  ticket_type: string | null;
  active: boolean;
}

interface SLAAndOLAPolicy {
  id: string;
  type: string;
  attributes: SLAAndOLAPolicyAttributes;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  selected:any;
  policyChange:any | string,
  isMenuopeenpolicy:boolean,
  selectedpolicyValue:string,
  showNewPolicyDropdown: boolean
  policies:SLAAndOLAPolicy[]
  isPageLoading: boolean
  createAnchorEl: HTMLElement | null | undefined,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CfslamanagementlistController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
    getdatacallId:string = ""
    deleteDataApiCallId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
     selected:[],
     policyChange:null,
      isMenuopeenpolicy:false,
      selectedpolicyValue:"",
      policies:[],
      showNewPolicyDropdown: false,
      createAnchorEl: null,
      isPageLoading: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

   
    // Customizable Area Start
 
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
    
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handleTokenExpiryAndInvalidToken(responseJson);
    
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.getdatacallId) {
          this.handleSetPolicies(responseJson.data)
        }else if(apiRequestCallId === this.deleteDataApiCallId){
          toast.success("Policy deleted successfully")
          this.getData()
        }
      }
    }
    
    // Customizable Area End
  }

  txtInptWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInptMobileProps = {
    ...this.txtInptWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInptProps = this.isPlatformWeb()
    ? this.txtInptWebProps
    : this.txtInptMobileProps;

  buttonShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInptProps.secureTextEntry = !this.state.enableField;
      this.buttonShowHideImageProps.source = this.txtInptProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  buttonShowHideImageProps = {
    source: this.txtInptProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  buttonExampleProps = {
    onPress: () => this.doBtnPressed(),
  };

  doBtnPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInptValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableFields = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount() {
    this.getData();
    this.setState({ selected: new Array(this.state.policies.length).fill(false) });
  }
  handleTokenExpiryAndInvalidToken(responseJson: any) {
    if (responseJson && Array.isArray(responseJson.errors) && responseJson.errors.length > 0 &&
      (responseJson.errors[0].token === "Token has Expired" || responseJson.errors[0].token === 'Invalid token')) {
      this.goToLogin();
    }
  }
  getStateColor = (state: string) => {
    if (state) {
      const stateColorMapping: { [key: string]: string } = {
        'ticket': '#11A64A',
        'change': '#6366F1',
        'problem':'rgb(249 112 112)',
        'task':'#FFBA08'
      };
      return stateColorMapping[state]
    } else {
      return '#11A64A'
    }
  };
  goToLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  handleSetPolicies = (response: SLAAndOLAPolicy[])=>{
    this.setState({policies:response,isPageLoading: false})
  }
  handleToggleSwitch = (id: string) => {
    this.setState((prevState) => ({
      policies: prevState.policies.map((policy) =>
        policy.id === id ? { ...policy, attributes: { ...policy.attributes, active: !policy.attributes.active } } : policy
      ),
    }));
  };
  getData = async() => {
     this.setState({isPageLoading: true})
    const token1 = await getStorageData("authToken");
    const header1 = {
      "Content-Type": configJSON.subscriptionApiContentType,
      'token': token1
    };
    const requestMessage1 = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getdatacallId = requestMessage1.messageId;

    requestMessage1.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getpolicyAPIMethod
    );
    requestMessage1.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.slapoliciesnameEndpoints
    );
    requestMessage1.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header1)
    );
    runEngine.sendMessage(requestMessage1.id, requestMessage1);

  };
 handlePolicyCreation = (type: string)=>{
  sessionStorage.setItem('policyType', type)
  this.props.navigation.navigate("Cfslamanagement")
 }

  handleCreateTemplate = (event: React.MouseEvent<HTMLElement>) => this.setState({ 
    createAnchorEl: event.currentTarget,
    showNewPolicyDropdown: true 
  })

  handleCloseCreateTemplate = () => {
    this.setState({ 
      createAnchorEl: null,
      showNewPolicyDropdown: false 
    })
  }
  handleDeletePolicy = async(id: string)=>{
    this.setState({isPageLoading: true})
    const token1 = await getStorageData("authToken");
    const header1 = {
      "Content-Type": configJSON.subscriptionApiContentType,
      'token': token1
    };
    const requestMessage1 = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteDataApiCallId = requestMessage1.messageId;

    requestMessage1.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.Deletemethod
    );
    requestMessage1.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteApiEndpoint+id
    );
    requestMessage1.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header1)
    );
    runEngine.sendMessage(requestMessage1.id, requestMessage1);
  }
  // Customizable Area End
}
