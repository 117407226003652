// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';

import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';
import IncidentTicketCustomForm from '../../blocks/customform3/src/IncidentTicketCustomForm.web'
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock.web';
import Tasks from '../../blocks/tasks/src/Tasks';
import TaskList from '../../blocks/tasks/src/TaskList';
import Task from '../../blocks/tasks/src/Task';
import OrderManagement from '../../blocks/ordermanagement/src/OrderManagement';
import Filteritems from '../../blocks/filteritems/src/Filteritems';
import Filteroptions from '../../blocks/filteritems/src/Filteroptions';
import AutomaticRenewals from '../../blocks/automaticrenewals/src/AutomaticRenewals';
import Onboardingguide from '../../blocks/onboardingguide/src/Onboardingguide';
import Notificationsettings from '../../blocks/notificationsettings/src/Notificationsettings';
import PhoneNumberInput from '../../blocks/mobile-account-registration/src/PhoneNumberInput';
import AdditionalDetailForm from '../../blocks/mobile-account-registration/src/AdditionalDetailForm';
import EducationalUserProfile from '../../blocks/educational-user-profile/src/EducationalUserProfile';
import ThemeBlock from '../../blocks/themeblock/src/ThemeBlock';
import AdvancedSearch from '../../blocks/advancedsearch/src/AdvancedSearch';
import HelpCentre from '../../blocks/helpcentre/src/HelpCentre';
import HelpCentreQA from '../../blocks/helpcentre/src/HelpCentreQA';
import HelpCentreSub from '../../blocks/helpcentre/src/HelpCentreSub';
import Emailnotifications2 from '../../blocks/emailnotifications2/src/Emailnotifications2';
import Customform3 from '../../blocks/customform3/src/Customform3';
import OTPInputAuth from '../../blocks/otp-input-confirmation/src/OTPInputAuth';
import Contentmanagement3 from '../../blocks/contentmanagement3/src/Contentmanagement3';
import Cfsuperadmindashboard from '../../blocks/cfsuperadmindashboard/src/Cfsuperadmindashboard';
import VisualAnalytics from '../../blocks/visualanalytics/src/VisualAnalytics';
import Adminconsole2 from '../../blocks/adminconsole2/src/Adminconsole2';
import Demoaccount from '../../blocks/demoaccount/src/Demoaccount.web';
import Notifications from '../../blocks/notifications/src/Notifications';
import Servicespecificsettingsadmin2 from '../../blocks/servicespecificsettingsadmin2/src/Servicespecificsettingsadmin2';
import Helpdeskreporting from '../../blocks/helpdeskreporting/src/Helpdeskreporting';
import Paymentadmin2 from '../../blocks/paymentadmin2/src/Paymentadmin2';
import Cfmanagementreportgeneration from '../../blocks/cfmanagementreportgeneration/src/Cfmanagementreportgeneration';
import Customisableusersubscriptions from '../../blocks/customisableusersubscriptions/src/Customisableusersubscriptions';
import SubscriptionDetails from '../../blocks/customisableusersubscriptions/src/SubscriptionDetails';
import Subscriptionbilling2 from '../../blocks/subscriptionbilling2/src/Subscriptionbilling2';
import Cfincidentmanagementticket from '../../blocks/cfincidentmanagementticket/src/Cfincidentmanagementticket';
import CountryCodeSelector from '../../blocks/country-code-selector/src/CountryCodeSelector';
import Catalogue from '../../blocks/catalogue/src/Catalogue';
import UserProfileBasicBlock from '../../blocks/user-profile-basic/src/UserProfileBasicBlock';
import Requestdemo from '../../blocks/requestdemo/src/Requestdemo';
import Pushnotifications from '../../blocks/pushnotifications/src/Pushnotifications';
import AccountGroups from '../../blocks/accountgroups/src/AccountGroups';
import Scheduling from '../../blocks/scheduling/src/Scheduling';
import Cfautoaccountcreationuponticketcreation from '../../blocks/cfautoaccountcreationuponticketcreation/src/Cfautoaccountcreationuponticketcreation';
import Settings2 from '../../blocks/settings2/src/Settings2';
import Departments from '../../blocks/settings2/src/Departments.web';
import Agents from '../../blocks/settings2/src/Agents.web';
import UserFields from '../../blocks/settings2/src/UserFields.web'
import Requesters from '../../blocks/settings2/src/Requesters.web'
import Guestlogin2 from '../../blocks/guestlogin2/src/Guestlogin2';
import Rolesandpermissions from '../../blocks/rolesandpermissions/src/Rolesandpermissions';
import ActivityFeed from '../../blocks/activityfeed/src/ActivityFeed';
import Kanbanboard2 from '../../blocks/kanbanboard2/src/Kanbanboard2';
import Chatbot from '../../blocks/chatbot/src/Chatbot';
import CreateComment from '../../blocks/comments/src/CreateComment';
import PostCreation from '../../blocks/postcreation/src/PostCreation';
import Posts from '../../blocks/postcreation/src/Posts';
import PostDetails from '../../blocks/postcreation/src/PostDetails';
import SocialMediaAccountLoginScreen from '../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen';
import Cfslamanagement from '../../blocks/cfslamanagement/src/Cfslamanagement';
import Cfslamanagementlist from '../../blocks/cfslamanagement/src/Cfslamanagementlist.web';
import Cfchangemanagementticket from '../../blocks/cfchangemanagementticket/src/Cfchangemanagementticket';
import SocialMediaAccountRegistrationScreen from '../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen';
import EmailAccountLoginBlock from '../../blocks/email-account-login/src/EmailAccountLoginBlock.web';
import ForgotPassword from '../../blocks/forgot-password/src/ForgotPassword.web';
import ForgotPasswordOTP from '../../blocks/forgot-password/src/ForgotPasswordOTP';
import NewPassword from '../../blocks/forgot-password/src/NewPassword';
import Dashboard from '../../blocks/dashboard/src/Dashboard';
import Chat from '../../blocks/chat/src/Chat';
import ChatView from '../../blocks/chat/src/ChatView';
import Cfproblemmanagementticket from '../../blocks/cfproblemmanagementticket/src/Cfproblemmanagementticket';
import StripePayments from '../../blocks/stripepayments/src/StripePayments';
import ImportExportData from '../../blocks/importexportdata/src/ImportExportData';
import Deeplinking from '../../blocks/deeplinking/src/Deeplinking';
import TermsConditions from '../../blocks/termsconditions/src/TermsConditions';
import TermsConditionsDetail from '../../blocks/termsconditions/src/TermsConditionsDetail';
import TermsConditionsUsers from '../../blocks/termsconditions/src/TermsConditionsUsers';
import BulkUploading from '../../blocks/bulkuploading/src/BulkUploading';
import Uploadmedia3 from '../../blocks/uploadmedia3/src/Uploadmedia3';
import LandingPage from '../../blocks/landingpage/src/LandingPage';
import EmailAccountRegistration from '../../blocks/email-account-registration/src/EmailAccountRegistration.web';
import Analytics from '../../blocks/analytics/src/Analytics';
import Multilevelapproval2 from '../../blocks/multilevelapproval2/src/Multilevelapproval2';
import Categoriessubcategories from '../../blocks/categoriessubcategories/src/Categoriessubcategories';
import Automatedprioritisation from '../../blocks/automatedprioritisation/src/Automatedprioritisation';
import Timesheetmanagement2 from '../../blocks/timesheetmanagement2/src/Timesheetmanagement2';
import InventoryManagement from '../../blocks/inventorymanagement/src/InventoryManagement';
import Cfreleasemanagementticket from '../../blocks/cfreleasemanagementticket/src/Cfreleasemanagementticket';
import NavigationSideBar from '../../components/src/NavigationSideBar';
import Roles from '../../blocks/settings2/src/Roles.web'
import Cab from '../../blocks/tasks/src/Cab.web'
import CabDetails from '../../blocks/tasks/src/CabDetails.web'
import Customisableusersubscriptions2 from "../../blocks/customisableusersubscriptions/src/Customisableusersubscriptions2.web";
import AgentGroup from "../../blocks/tasks/src/AgentGroups.web";

import AgentProfile from '../../blocks/user-profile-basic/src/AgentProfile.web'
import Dashboards from '../../blocks/customform3/src/Dashboards.web';
import ServiceCatalog from '../../blocks/dashboard/src/ServiceCatalog';
import UserProfile from '../../blocks/user-profile-basic/src/UserProfile.web'
import RequesterLogin from '../../blocks/user-profile-basic/src/RequesterLogin.web';
import RequesterGroup from "../../blocks/settings2/src/RequesterGroups.web";
import RequesterGroupsMember from "../../blocks/settings2/src/RequesterGroupsMember.web";

import DepartmentFields from '../../blocks/settings2/src/DepartmentFields.web';
import RequesterTaskList from "../../blocks/tasks/src/RequesterTaskList.web"
import RequesterForgotPassword from "../../blocks/forgot-password/src/RequesterForgotPassword.web"
import AgentGroupMember from "../../blocks/tasks/src/AgentGroupMember.web"
import DepartmentInfo from '../../blocks/settings2/src/DepartmentInfo.web';
import CreateEditAdminRole from '../../blocks/rolesandpermissions/src/CreateEditAdminRole.web';
import CreateEditAgentRole from '../../blocks/rolesandpermissions/src/CreateEditAgentRole.web';
import Categories from '../../blocks/settings2/src/Categories.web';
import FormTemplates from '../../blocks/customform3/src/FormTemplates.web';
import ChangeTemplateForm from '../../blocks/customform3/src/ChangeTicketForm.web';
import TicketTemplateCustomForm from '../../blocks/customform3/src/TicketTemplateCustomForm.web';
import ServiceManagement from '../../blocks/settings2/src/ServiceManagement.web'
import { components } from 'react-select';
import EmailAccountRegistrationData from '../../blocks/email-account-registration/src/EmailAccountRegistrationData.web'
const routeMap = {
  Tasks: {
    component: Tasks,
    path: '/Tasks'
  },
  TaskList: {
    component: TaskList,
    path: '/TaskList'
  },
  RequesterTaskList: {
    component: RequesterTaskList,
    path: '/RequesterTaskList'
  },
  Task: {
    component: Task,
    path: '/Task/:id?'
  },
  ServiceManagement:{
     component: ServiceManagement,
     path: '/ServiceManagement'
  },
  OrderManagement: {
    component: OrderManagement,
    path: '/OrderManagement'
  },
  Filteritems: {
    component: Filteritems,
    path: '/Filteritems'
  },
  EmailAccountRegistrationData:{
    component:EmailAccountRegistrationData,
    path:"/registrationData"
  },
  Filteroptions: {
    component: Filteroptions,
    path: '/Filteroptions'
  },
  AutomaticRenewals: {
    component: AutomaticRenewals,
    path: '/AutomaticRenewals'
  },
  Onboardingguide: {
    component: Onboardingguide,
    path: '/Onboardingguide'
  },
  Notificationsettings: {
    component: Notificationsettings,
    path: '/Notificationsettings'
  },
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: '/PhoneNumberInput'
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: '/AdditionalDetailForm'
  },
  EducationalUserProfile: {
    component: EducationalUserProfile,
    path: '/EducationalUserProfile'
  },
  ThemeBlock: {
    component: ThemeBlock,
    path: '/ThemeBlock'
  },
  AdvancedSearch: {
    component: AdvancedSearch,
    path: '/AdvancedSearch'
  },
  HelpCentre: {
    component: HelpCentre,
    path: '/HelpCentre'
  },
  HelpCentreQA: {
    component: HelpCentreQA,
    path: '/HelpCentreQA'
  },
  HelpCentreSub: {
    component: HelpCentreSub,
    path: '/HelpCentreSub'
  },
  Emailnotifications2: {
    component: Emailnotifications2,
    path: '/Emailnotifications2'
  },
  Customform3: {
    component: Customform3,
    path: '/Customform3'
  },

  FormTemplates: {
    component: FormTemplates,
    path: '/formTemplates'
  },

  TicketTemplateCustomForm: {
    component: TicketTemplateCustomForm,
    path: '/TicketTemplateCustomForm'
  },
  ChangeTemplateForm: {
    component: ChangeTemplateForm,
    path: '/changetemplate'
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: '/OTPInputAuth'
  },
  Contentmanagement3: {
    component: Contentmanagement3,
    path: '/Contentmanagement3'
  },
  Cfsuperadmindashboard: {
    component: Cfsuperadmindashboard,
    path: '/Cfsuperadmindashboard'
  },
  VisualAnalytics: {
    component: VisualAnalytics,
    path: '/VisualAnalytics'
  },
  Adminconsole2: {
    component: Adminconsole2,
    path: '/Adminconsole2'
  },
  Demoaccount: {
    component: Demoaccount,
    path: '/Demoaccount'
  },
  Notifications: {
    component: Notifications,
    path: '/Notifications'
  },
  Servicespecificsettingsadmin2: {
    component: Servicespecificsettingsadmin2,
    path: '/Servicespecificsettingsadmin2'
  },
  Helpdeskreporting: {
    component: Helpdeskreporting,
    path: '/Helpdeskreporting'
  },
  IncidentTicketCustomForm: {
    component:IncidentTicketCustomForm,
    path:'/incidentticket'
  },
  Paymentadmin2: {
    component: Paymentadmin2,
    path: '/Paymentadmin2'
  },
  Cfmanagementreportgeneration: {
    component: Cfmanagementreportgeneration,
    path: '/Cfmanagementreportgeneration'
  },
  Customisableusersubscriptions: {
    component: Customisableusersubscriptions,
    path: '/Customisableusersubscriptions'
  },
  SubscriptionDetails: {
    component: SubscriptionDetails,
    path: '/SubscriptionDetails'
  },
  Subscriptionbilling2: {
    component: Subscriptionbilling2,
    path: '/Subscriptionbilling2'
  },
  Cfincidentmanagementticket: {
    component: Cfincidentmanagementticket,
    path: '/Cfincidentmanagementticket'
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: '/CountryCodeSelector'
  },
  Catalogue: {
    component: Catalogue,
    path: '/Catalogue'
  },
  UserProfileBasicBlock: {
    component: UserProfileBasicBlock,
    path: '/UserProfileBasicBlock'
  },
  Requestdemo: {
    component: Requestdemo,
    path: '/Requestdemo'
  },
  Pushnotifications: {
    component: Pushnotifications,
    path: '/Pushnotifications'
  },
  AccountGroups: {
    component: AccountGroups,
    path: '/AccountGroups'
  },
  Scheduling: {
    component: Scheduling,
    path: '/Scheduling'
  },
  Cfautoaccountcreationuponticketcreation: {
    component: Cfautoaccountcreationuponticketcreation,
    path: '/Cfautoaccountcreationuponticketcreation'
  },
  Settings2: {
    component: Settings2,
    path: '/Settings2'
  },
  Departments: {
    component: Departments,
    path: '/departments'
  },
  DepartmentInfo: {
    component: DepartmentInfo,
    path: '/DepartmentInfo/:id?'
  },
  Agents: {
    component: Agents,
    path: '/agents'
  },
  UserFields: {
    component: UserFields,
    path: '/userFields'
  },
  Roles: {
    component: Roles,
    path: '/roles'
  },
  Requesters: {
    component: Requesters,
    path: '/requesters'
  },

  RequesterGroupsMember: {
    component: RequesterGroupsMember,
    path: '/RequesterGroupsMember/:id?'
  },

  Cab: {
    component: Cab,
    path: '/cab',
    exact: true
  },
  CabDetails: {
    component: CabDetails,
    path: '/cab/:navigationBarTitleText',
    exact: true
  },
  AgentGroup: {
    component: AgentGroup,
    path: '/AgentGroup'
  },

  Categories: {
    component: Categories,
    path: '/Categories'
  },

  AgentGroupMember:{
    component: AgentGroupMember,
    path: '/AgentGroupMember/:id?'
  },

  AgentProfile: {
    component: AgentProfile,
    path: '/agentprofile/:navigationBarTitleText?'
  },
  Guestlogin2: {
    component: Guestlogin2,
    path: '/Guestlogin2'
  },
  Rolesandpermissions: {
    component: Rolesandpermissions,
    path: '/Rolesandpermissions'
  },
  CreateAdminRole: {
    component: CreateEditAdminRole,
    path: '/CreateAdminRole'
  },
  EditAdminRole: {
    component: CreateEditAdminRole,
    path: '/EditAdminRole/:id'
  },
  CreateAgentRole: {
    component: CreateEditAgentRole,
    path: '/CreateAgentRole'
  },
  EditAgentRole: {
    component: CreateEditAgentRole,
    path: '/EditAgentRole/:id'
  },
  ActivityFeed: {
    component: ActivityFeed,
    path: '/ActivityFeed'
  },
  Kanbanboard2: {
    component: Kanbanboard2,
    path: '/Kanbanboard2'
  },
  Chatbot: {
    component: Chatbot,
    path: '/Chatbot'
  },
  Comment: {
    component: Comment,
    path: '/Comment'
  },
  CreateComment: {
    component: CreateComment,
    path: '/CreateComment'
  },
  PostCreation: {
    component: PostCreation,
    path: '/PostCreation'
  },
  Posts: {
    component: Posts,
    path: '/Posts'
  },
  PostDetails: {
    component: PostDetails,
    path: '/PostDetails'
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: '/SocialMediaAccountLoginScreen'
  },
  Cfslamanagement: {
    component: Cfslamanagement,
    path: '/Cfslamanagement'
  },
  Cfslamanagementlist: {
    component: Cfslamanagementlist,
    path: '/Cfslamanagementlist'
  },
  
  Cfchangemanagementticket: {
    component: Cfchangemanagementticket,
    path: '/changes'
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: '/SocialMediaAccountRegistrationScreen'
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: '/EmailAccountLoginBlock'
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: '/ForgotPassword'
  },

  RequesterForgotPassword: {
    component: RequesterForgotPassword,
    path: '/RequesterForgotPassword'
  },

  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: '/ForgotPasswordOTP'
  },
  NewPassword: {
    component: NewPassword,
    path: '/NewPassword'
  },
  Dashboard: {
    component: Dashboards,
    path: '/Dashboard'
  },
  Chat: {
    component: Chat,
    path: '/Chat'
  },
  ChatView: {
    component: ChatView,
    path: '/ChatView'
  },
  Cfproblemmanagementticket: {
    component: Cfproblemmanagementticket,
    path: '/problems'
  },
  StripePayments: {
    component: StripePayments,
    path: '/StripePayments'
  },
  ImportExportData: {
    component: ImportExportData,
    path: '/ImportExportData'
  },
  Deeplinking: {
    component: Deeplinking,
    path: '/Deeplinking'
  },
  TermsConditions: {
    component: TermsConditions,
    path: '/TermsConditions'
  },
  TermsConditionsDetail: {
    component: TermsConditionsDetail,
    path: '/TermsConditionsDetail'
  },
  TermsConditionsUsers: {
    component: TermsConditionsUsers,
    path: '/TermsConditionsUsers'
  },
  BulkUploading: {
    component: BulkUploading,
    path: '/BulkUploading'
  },
  Uploadmedia3: {
    component: Uploadmedia3,
    path: '/Uploadmedia3'
  },
  LandingPage: {
    component: LandingPage,
    path: '/LandingPage'
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: '/EmailAccountRegistration'
  },
  Analytics: {
    component: Analytics,
    path: '/Analytics'
  },
  Multilevelapproval2: {
    component: Multilevelapproval2,
    path: '/Multilevelapproval2'
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: '/Categoriessubcategories'
  },
  Automatedprioritisation: {
    component: Automatedprioritisation,
    path: '/Automatedprioritisation'
  },
  Timesheetmanagement2: {
    component: Timesheetmanagement2,
    path: '/Timesheetmanagement2'
  },
  InventoryManagement: {
    component: InventoryManagement,
    path: '/InventoryManagement'
  },
  Cfreleasemanagementticket: {
    component: Cfreleasemanagementticket,
    path: '/Cfreleasemanagementticket'
  },

  // Home: {
  //   component: [EmailAccountLoginBlock, TopNav],
  //   path: '/',
  //   exact: true
  // },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: '*/AlertWeb',
    modal: true
  },
  Customisableusersubscriptions2: {
    component: Customisableusersubscriptions2,
    path: '/accountSetting'
  },
  NavigationSideBar: {
    component: NavigationSideBar,
    path: '/NavigationSideBar'
  },
  RequesterDashboard: {
    component: Dashboard,
    path: '/RequesterDashboard'
  },
  ServiceCatalog: {
    component: ServiceCatalog,
    path: '/ServiceCatalog'
  },
  UserProfile: {
    component: UserProfile,
    path: '/userprofile'
  },
  RequesterLogin: {
    component: RequesterLogin,
    path: '/RequesterLogin'
  },
  DepartmentFields: {
    component: DepartmentFields,
    path: '/DepartmentFields'
  }
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3'
});

class App extends Component {
  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <View style={{ height: '1024px' }}>
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
