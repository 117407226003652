import React from "react";

// Customizable Area Start
import {
  Button,
  Typography,
  Box,
  Grid,
  TextField,
  MenuItem,
  Select,
  ThemeProvider,
  createTheme,
  OutlinedInput,
  FormControlLabel,
  RadioGroup,
  Radio,
  InputAdornment,
  Chip,
  Input,
  Modal,
  ButtonGroup,
  Table,
  TableHead,
  TableRow,
  Checkbox,
  TableCell,
  TableBody,
  Menu,
  styled
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  ArrowDropDown,
  Close,
  Collections,
  ExpandMoreRounded,
  Search,
} from "@material-ui/icons";
import TicketTemplateCustomFormController, { AllAgentsList, AllGroupsList, CategoryList, DepartmentList, Props, configJSON } from "./TicketTemplateCustomFormController";
import NavigationSideBar from "../../../components/src/NavigationSideBar";
import Loader from "../../../components/src/Loader";
import { clipImg, layersImg } from "./assets";
import { Toaster } from "react-hot-toast";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; 
import '../assets/styles.css'

const modules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    [{ 'align': '' }, { 'align': 'center' }, { 'align': 'right' }, { 'align': 'justify' }],
    [{ 'color': [] }, { 'background': [] }],
    ['image'],
  ],
};
const CustomMenuItem = styled(MenuItem)({
  padding: '9px 16px'
})
const formats = [
  'list', 'bullet',
  'bold', 'italic', 'underline', 'strike',
  'align',
  'color', 'background',
  'image'
];
const theme = createTheme({
  palette: {
    primary: {
      main: "#8760A9",
      contrastText: "#fff",
    },
  },
  overrides: {
    MuiRadio: {
      root: {
        color: '#8760A9',
      },
      colorSecondary: {
        '&$checked': {
          color: '#8760A9',
        },
      },
    
    },
    MuiFormLabel: {
      asterisk: {
        color: '#db3131',
        '&$error': {
          color: '#db3131'
        },
      }
    },
  }
});

// Customizable Area End

export default class TicketTemplateCustomForm extends TicketTemplateCustomFormController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Box style={{position:'fixed',height:'100%',width:'100%',pointerEvents:'none',overflow:'hidden',zIndex:1000}}>
        <Loader loading={this.state.isLoading} />
        </Box>
        <NavigationSideBar active={'Settings2'} navigation={this.props.navigation} />
        
       
        <Box         data-testid={"form_modal"}
          style={styles.container}
        >
          <Box style={{ display: 'flex', 
            justifyContent: 'space-between',
             alignItems: 'center' }}>
            <Box
             style={webStyles.topheader}>
              <span 
              style={webStyles.navigation}>
                <span >{configJSON.settings}

                </span>
                <span>
                  {" > "}</span>
                <span>
                  {configJSON.formTemplate}</span>
                <span>
                  {" > "}</span>
                <span >
                  {configJSON.newticketTemplate}</span>
              </span>
            </Box>
          </Box>
          <Box style={styles.boxStyle_1}>
            <Typography style={styles.fontStyle_Head}>{this.state.editTemplate ?"Edit ticket template": "Create new ticket template"}</Typography>
          </Box>

          <Grid container spacing={4} style={{ marginTop: '2%', boxShadow: "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)",paddingBottom:'2%' }}>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <Typography style={{fontWeight:700,fontSize:18,marginBottom:15,color:"rgba(51, 53, 77, 1)"}}>{configJSON.generalTitle}</Typography>
              <Box style={{...styles.boxStyle_4,marginBottom:15}}>
                <Typography style={styles.inputLabel}>{configJSON.title}<span style={styles.textColorRed}> *</span></Typography>
                <TextField
                  variant="outlined"
                  value={this.state.title}
                  onChange={this.handleTitleChange}
                  data-testId={"changeTitle"}
                  placeholder={configJSON.titlePlaceholder}
                />
                {this.state.titleError && <Typography style={styles.textColorRed}>{this.state.titleError}</Typography>}
              </Box>
              <Box style={{...styles.boxStyle_4,marginBottom:15}}>
                <Typography style={styles.inputLabel}>{configJSON.textDescription}</Typography>
               
                <TextField
                  variant="outlined"
                  value={this.state.generalDescription}
                  onChange={this.handleGeneralDescriptionChange}
                  data-testId={"changeGeneralDescription"}
                  placeholder={configJSON.textDescriptionPlaceholder}
                />
                 {this.state.generalDescriptionError && <Typography style={styles.textColorRed}>{this.state.generalDescriptionError}</Typography>}
              </Box>
              <Box style={{ ...styles.boxStyle_4, marginTop: '15px' }}>
                <Typography style={styles.inputLabel}>{configJSON.available_for}</Typography>
                <RadioGroup
                  aria-label="available_for"
                  name="available_for"
                  value={this.state.availableFor}
                  onChange={this.handleAvailableForChange}
                  data-testid={"available_for"}
                >
                  <FormControlLabel className="available_label" style={{fontWeight:400, fontSize:14,color:"rgba(51, 53, 77, 1)"}} value="All agents" control={<Radio />} label="All agents" />
                  <FormControlLabel value="Agents in the group" control={<Radio />} style={{fontWeight:400, fontSize:14,color:"rgba(51, 53, 77, 1)"}} label="Agents in the group" />
                </RadioGroup>
              </Box>
              <Typography style={{fontWeight:700,fontSize:18,marginBottom:15,marginTop:15,color:"rgba(51, 53, 77, 1)"}}>{configJSON.detailsTitle}</Typography>
              <Box style={{...styles.boxStyle_4,marginBottom:15}}>
                <Typography style={styles.inputLabel}>{configJSON.textSubject}</Typography>
                <TextField
                  variant="outlined"
                  value={this.state.subject}
                  onChange={this.handleSubjectChange}
                  data-testid={"changeSubject"}
                  placeholder={configJSON.textEnterSubject}
                />
 {this.state.subjectError && <Typography style={styles.textColorRed}>{this.state.subjectError}</Typography>}
</Box>             
              <Box style={{ ...styles.boxStyle_4, marginTop: '15px' }}>
                <Typography style={styles.inputLabel}>{configJSON.textSource}</Typography>
                <Select
                  input={<OutlinedInput />}
                  IconComponent={ExpandMoreRounded}
                  style={styles.dropdownStyle}
                  fullWidth
                  value={this.state.selectedSource}
                  onChange={this.handleSourceChange}
                  data-testid={"change_source"}
                >
                  {this.source.map((option) => (
                    <MenuItem
                      key={option.id}
                      value={option.title}
                    >
                      {option.title}
                    </MenuItem>
                  ))}
                </Select>
            
              </Box>

              <Box style={{ ...styles.boxStyle_4, marginTop: '15px' }}>
                <Typography style={styles.inputLabel}>{configJSON.textStatus}<span style={styles.textColorRed}> *</span></Typography>
                <Select
                  input={<OutlinedInput />}
                  IconComponent={ExpandMoreRounded}
                  style={styles.dropdownStyle}
                  fullWidth
                  value={this.state.selectedStatus}
                  onChange={this.handleStatusChange}
                  data-testid={"change_status"}
                >
                  {this.status.map((option) => (
                    <MenuItem
                      key={option.id}
                      value={option.id}
                    >
                      {option.title}
                    </MenuItem>
                  ))}
                </Select>
                {this.state.statusError && <Typography style={styles.textColorRed}>{this.state.statusError}</Typography>}

              </Box>

              <Box style={{ ...styles.boxStyle_4, marginTop: '15px' }}>
                <Typography style={styles.inputLabel}>{configJSON.textPriority}</Typography>
                <RadioGroup
                  aria-label="priority"
                  name="priority"
                  value={this.state.selectedPriority}
                  onChange={this.handlePriorityChange}
                  data-testid={"change_priority"}
                >
                  <FormControlLabel value="Low" control={<Radio />} label="Low" />
                  <FormControlLabel value="Medium" control={<Radio />} label="Medium" />
                  <FormControlLabel value="High" control={<Radio />} label="High" />
                  <FormControlLabel value="Urgent" control={<Radio />} label="Urgent" />
                </RadioGroup>
              </Box>

              <Box style={{ ...styles.boxStyle_4, marginTop: '15px' }}>
                <Typography style={styles.inputLabel}>{configJSON.textGroup}</Typography>
                <Autocomplete
                disabled={this.state.hideDropdown}
                  value={this.state.editedGroup}
                  style={{background: 
                    this.getBackground()
                  }}
                  options={this.state.filteredOptions}
                  getOptionLabel={(option) => option.attributes.name}
                  onChange={(event, value, reason, details) => this.handleChangeGroup(value as AllGroupsList | null)}
                  data-testid={"select_group"}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      placeholder={configJSON.assignteam}
                      variant="outlined"
                      disabled={this.state.hideDropdown}
                      data-testid={"change_group"}
                      value={this.state.changedGroup}
                      onChange={this.handleGroupSelectionChange}
                    />
                  )}
                />
              </Box>

              <Box style={{ ...styles.boxStyle_4, marginTop: '15px' }}>
                <Typography style={styles.inputLabel}>{configJSON.textAgent}</Typography>
                <Autocomplete
                  options={this.state.filteredAgents}
                  getOptionLabel={(option) => option.attributes.full_name}
                  value={this.state.agentValue}
                  disabled={this.state.hideDropdown}
                  style={{background:
                    this.getBackground()}}
                  onChange={(event: React.ChangeEvent<{}>, value: AllAgentsList | null) => this.handleChangeAgents(value)}
                  data-testid={"select_agents"}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      disabled={this.state.hideDropdown}
                      fullWidth
                      placeholder={configJSON.agentFieldPlaceholder}
                      variant="outlined"
                      onChange={this.handleAgentsSelectionChange}
                    />
                  )}
                />
              </Box>

              <Box style={{ ...styles.boxStyle_4, marginTop: '15px' }}>
                <Typography style={styles.inputLabel}>{configJSON.textDepartment}</Typography>
                <Autocomplete
                  options={this.state.filteredDepartmentList}
                  getOptionLabel={(option) => option.attributes.department_name}
                  data-testid="select_department"
                  value={this.state.departmentValue}
                  onChange={(event: React.ChangeEvent<{}>, value: DepartmentList | null) => this.handleChangeDepartment(value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      placeholder={configJSON.agentFieldPlaceholder}
                      variant="outlined"
                      onChange={this.handleDepartmentSelectionChange}
                    />
                  )}
                />
              </Box>
            </Grid>

            <Grid item xs={6} sm={6} md={6} lg={6} spacing={2}>
              <Box style={{...styles.boxStyle_4,marginBottom:'20px'}}>
                <Typography style={{ ...styles.inputLabel, marginTop: '10px' }}>{configJSON.textDescription}<span style={styles.textColorRed}> *</span></Typography>
           
                <ReactQuill
                  data-testid="description_change"
                  placeholder="Enter description"
                  modules={modules}
                  formats={formats}
                  value={this.state.description}
                  onChange={this.handleChange}
                  style={{
                    fontStyle: 'normal',
                  }}
                />

     {this.state.descriptionError && <Typography style={styles.textColorRed}>{this.state.descriptionError}</Typography>}
              </Box>


              <Box style={{ ...styles.boxStyle_4, marginTop: '15px' }}>
                <Typography style={styles.inputLabel}>{configJSON.textCategory}</Typography>
                <Autocomplete
                  value={this.state.selectedCategoryValue}
                  options={this.state.filteredCategories}
                  disabled={this.state.hideDropdown}
                  style=
                  {{background: this.getBackground()}}
                  getOptionLabel={(option) => option.attributes.name}
                  onChange={(event: React.ChangeEvent<{}>, value: CategoryList | null) => this.handleCategoryChange(value)}
                  data-testid={"ticket_category_change"}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      placeholder={configJSON.textChooseCategory}
                      variant="outlined"
                      disabled={this.state.hideDropdown}
                      data-testid={"change_category"}
                      value={this.state.selectedCategory}
                      onChange={this.handleCategorySelectionChange}
                    />
                  )}
                />
              </Box>

              <Box style={{ marginTop: '15px' }}>
                <Typography style={styles.inputLabel}>{configJSON.textPlannedSdate}</Typography>
                <Box style={styles.startDateEndBox}>
                  <Box style={{ ...styles.boxStyle_4, width: '45%' }}>
                    <TextField
                      id="date"
                      type="date"
                      variant="outlined"
                      onChange={this.handleStartDateChange}
                      value={this.state.startDate}
                      data-testid={"start_date"}
                      InputProps={{ inputProps: { min: new Date().toISOString().split("T")[0] } }}
                    />
                    {this.state.startDateError && <Typography style={styles.textColorRed}>{this.state.startDateError}</Typography>}
                  </Box>

                  <Box style={{ ...styles.boxStyle_4, width: '45%' }}>
                    <TextField
                      id="time"
                      type="time"
                      variant="outlined"
                      onChange={this.handleStartTimeChange}
                      value={this.state.startTime}
                      data-testid={"start_time"}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        step: 300,
                      }}
                    />
                    {this.state.startTimeError && <Typography style={styles.textColorRed}>{this.state.startTimeError}</Typography>}
                  </Box>
                </Box>
              </Box>

              <Box style={{ marginTop: '15px' }}>
                <Typography style={styles.inputLabel}>{configJSON.textPlannedEdate}</Typography>
                <Box style={styles.startDateEndBox}>
                  <Box style={{ ...styles.boxStyle_4, width: '45%' }}>
                    <TextField
                      id="date"
                      type="date"
                      variant="outlined"
                      onChange={this.handleEndDateChange}
                      value={this.state.endDate}
                      data-testid={"end_date"}
                      InputProps={{ inputProps: { min: this.state.startDate || new Date().toISOString().split("T")[0] } }}
                    />
                    {this.state.endDateError && <Typography style={styles.textColorRed}>{this.state.endDateError}</Typography>}
                  </Box>

                  <Box style={{ ...styles.boxStyle_4, width: '45%' }}>
                    <TextField
                      id="time"
                      type="time"
                      variant="outlined"
                      onChange={this.handleEndTimeChange}
                      value={this.state.endTime}
                      data-testid={"end_time"}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        step: 300,
                      }}
                    />
                    {this.state.endTimeError && <Typography style={styles.textColorRed}>{this.state.endTimeError}</Typography>}

                  </Box>
                </Box>
              </Box>

              <Box style={{ ...styles.boxStyle_4, marginTop: '15px' }}>
                <Typography style={styles.inputLabel}>{configJSON.textPlannedEffort}</Typography>
                <TextField
                  variant="outlined"
                  fullWidth
                  disabled
                  placeholder={configJSON.textPlannedEffortPlaceholder}
                  data-testid={"planned_effort"}
                  value={this.state.timeDifference}
                />
              </Box>
             <Box style={{display:'flex',flexDirection:'column',gap:'5px',alignItems:'flex-start'}}>
             <Button
                component="label"
                role={undefined}
                variant="contained"
                data-testid ='asset_show_btn'
                onClick={this.handleShowAssetModal}
                style={{ background: 'none', boxShadow: 'none', textTransform: 'none', color: "rgba(135, 96, 169, 1)", fontWeight: 700, fontSize: 14, marginTop: '15px' }}
                tabIndex={-1}
                startIcon={<img src={layersImg} style={{ marginBottom: "2px" }} alt="layersImg" />}
              >
                Associate asset
               
              </Button>
                {this.state.showAssetChip && <Box border={'1px solid #E6DEED'} width={'100%'}>
                  {this.state.selectedAssets.map((asset, index) => (
                    <Chip
                      key={index}
                      style={{ background: '#E6DEED', color: '#33354D', padding: '8px', borderRadius: '4px', margin: '4px' }}
                      label={
                        <Box style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                          {asset.name}
                        </Box>
                      }
                      onDelete={() => this.removeAsset(index)}
                      size="small"
                      data-testid={`asset_${index}`}
                    />
                  ))}
                </Box>}
              <Button
                component="label"
                role={undefined}
                variant="contained"
                style={{ background: 'none', boxShadow: 'none', textTransform: 'none', color: "rgba(135, 96, 169, 1)", fontWeight: 700, fontSize: 14, }}
                tabIndex={-1}
                startIcon={<img src={clipImg} style={{ marginBottom: "2px" }} alt="clipImg" />}
              >
                Attach file
                <Input
                  inputProps={{ multiple: true }}
                  style={{
                    clip: 'rect(0 0 0 0)',
                    clipPath: 'inset(50%)',
                    height: 1,
                    overflow: 'hidden',
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    whiteSpace: 'nowrap',
                    width: 1,
                  }}
                  data-testid='file_upload'
                  type="file"
                  onChange={this.handleFileChange}
                />
              </Button>
              {this.state.fileNames.length > 0 && (
  <Box border={'1px solid #E6DEED'} width={'100%'}>
    {this.state.fileNames.map((fileName, index) => (
      <Chip
        key={index}
        data-testid={`file_${index}`}
        style={{ background: '#E6DEED', color: '#33354D', padding: '8px', borderRadius: '4px', margin: '4px' }}
        label={
          <Box style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            {fileName.toLowerCase().endsWith('.jpg') ||
            fileName.toLowerCase().endsWith('.jpeg') ||
            fileName.toLowerCase().endsWith('.png') ||
            fileName.toLowerCase().endsWith('.gif') ? (
              <Collections style={{ width: '18px', height: '18px', color: '#33354D' }} />
            ) : (
              <img src={clipImg} style={{ width: '18px', height: '18px', transform: 'rotate(90deg)' }} alt="clipImg" />
            )}
            {fileName}
          </Box>
        }
        onDelete={() => this.removeFile(index)}
        size="small"
      />
    ))}
  </Box>
)}
             </Box>
              <Box style={{ ...styles.boxStyle_4, marginTop: '15px' }}>
                <Typography style={styles.inputLabel}>{configJSON.textTags}</Typography>
                <TextField
                  variant="outlined"
                  fullWidth
                  value={this.state.inputValue}
                  onChange={this.handleInputChange}
                  onKeyDown={this.handleKeyDown}
                  data-testid={"change_tag"}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {this.state.selectedTags.map((tags, index) => (
                          <Chip
                            key={index}
                            label={tags}
                            onDelete={this.handleDeleteTag(tags)}
                            size="small"
                            data-testid={"add_tag"}
                          />
                        ))}
                      </InputAdornment>
                    )
                  }}
                />
              </Box>
            </Grid>
            <Box style={styles.buttonBoxSyle}>
                <Typography
                  style={styles.cancelButton}
                  data-testid='cancel_btn'
                  onClick={() => this.handleCancel()}
                >
                  {configJSON.textCancel}
                </Typography>
              {this.state.editTemplate ?
                <Button
                  style={styles.submitButton}
                  variant="contained"
                  onClick={this.updateSubmiit}
                  data-testid={"update_form"}
                >
                  Save
                </Button> : 
                <Button
                  style={styles.submitButton}
                  variant="contained"
                  onClick={this.onFormSubmiit}
                  data-testid={"submit_form"}
                >
                  {configJSON.textSave}
                </Button>} 

              </Box>
          </Grid>
        </Box>
        <Toaster />
        <Modal open={this.state.showAssetModal} title={'Associate assets'} style={{display:'flex',justifyContent:'center',alignItems:'center'}} onClose={this.handleCloseAssetModal}>
          <Box style={styles.modalBox}>
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
              <Typography style={{ fontWeight: 700, fontSize: 20 }}>Associate assets</Typography>
              <span onClick={this.handleCloseAssetModal} style={{cursor:'pointer'}}><Close /></span>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between',marginTop:'20px', width: '100%',alignItems:'center' }}>
              <TextField placeholder="Search assets" data-testid='search_assets' size="small" variant="outlined" onChange={this.handleSearchAssets} InputProps={{ startAdornment: <InputAdornment position="start"><Search /></InputAdornment> }}/>
              <ButtonGroup
                orientation='horizontal'
                
                variant="contained"
                style={{ textTransform: 'none',background:"#E6DEED" }}
              >
                <Button style={{ textTransform: 'none',background:"#E6DEED",fontWeight:700,fontSize:16 }}>
                  All Assets
                </Button>
                <Button
                  id="create-button"
                  aria-controls={this.state.showAssetsDropdown ? 'create-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={this.state.showAssetsDropdown ? 'true' : undefined}
                  data-testid="createNewTemplate" style={{ textTransform: 'none',background:"#E6DEED" }}
                >
                  <ArrowDropDown />
                </Button>
              </ButtonGroup>
            </div>
            <Box style={{height:'75%',overflow: 'auto'}}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      data-testid='checkbox_all'
                      style={{ color: '#8760A9' }}
                      indeterminate={this.state.selectedAssets.length > 0 && this.state.selectedAssets.length < this.state.filtered_assets.length}
                      checked={this.state.filtered_assets.length > 0 && this.state.selectedAssets.length === this.state.filtered_assets.length}
                      onChange={this.handleSelectAllClick}
                      inputProps={{ 'aria-label': 'select all assets' }}
                    />
                  </TableCell>
                  <TableCell style={{textAlign:'start',paddingLeft:'0px',color:'rgba(61, 64, 92, 1)'}}>Name</TableCell>
                  <TableCell style={{textAlign:'start',color:'rgba(61, 64, 92, 1)'}}>Asset Type</TableCell>
                  <TableCell style={{textAlign:'start',color:'rgba(61, 64, 92, 1)'}}>Used By</TableCell>
                  <TableCell style={{textAlign:'start',color:'rgba(61, 64, 92, 1)'}}>Department</TableCell>
                  <TableCell style={{textAlign:'start',color:'rgba(61, 64, 92, 1)'}}>Location</TableCell>
                  <TableCell style={{textAlign:'start',color:'rgba(61, 64, 92, 1)'}}>Asset State</TableCell>
                  <TableCell style={{textAlign:'start',color:'rgba(61, 64, 92, 1)'}}>Serial No</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.filtered_assets.map((asset, index) => {
                  const isItemSelected = this.isSelected(asset.id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      onClick={(event) => this.handleClick(event, asset.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      data-testid={`asset_list${index}`}
                      key={asset.name}
                      selected={false}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                        style={{ color: '#8760A9' }}
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row" padding="none">
                        {asset.name}
                      </TableCell>
                      <TableCell>{asset.type ? asset.type: '-'}</TableCell>
                      <TableCell>{asset.used_by? asset.used_by: '-'}</TableCell>
                      <TableCell>{asset.department? asset.department: '-'}</TableCell>
                      <TableCell>{asset.location? asset.location: '-'}</TableCell>
                      <TableCell>{asset.state? asset.state: '-'}</TableCell>
                      <TableCell>{asset.serial_no? asset.serial_no: '-'}</TableCell>
                      
                    </TableRow>
                );
              })}
              </TableBody>
            </Table>
            </Box>
            <Box  style={styles.buttonBoxSyle}>
                <Typography
                  style={styles.cancelButton}
                  onClick={() => this.handleCloseAssetModal()}
                  data-testid= 'close_modal'
                >
                  {configJSON.textCancel}
                </Typography>
                <Button
                  style={styles.submitButton}
                  variant="contained"
                  disabled={this.state.selectedAssets.length ===0}
                  onClick={this.handleSelectedAssets}
                  data-testid={"associate_assset_btn"}
                >
                  Associate assets
                </Button>
            </Box>
          </Box>
        </Modal>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles: Record<string, React.CSSProperties> = {
  modalBox:{
    background:'white',
    width:'70%',
    height:'80%',
    padding:'24px'
  },
  textButtonDisabled: {
    color: '#A9A9A9', 
    cursor: 'not-allowed',
    opacity: 0.6, 
    border: 'none', 
    background: 'transparent', 
    padding: 0, 
  },
  textButtonEnabled: {
    cursor: 'pointer',
    border: 'none', 
    background: 'transparent', 
    padding: 0, 
  },
  constainerSpacing: {
    backgroundColor: '#F9F6FB',
    marginTop: '20px'
  },
  addCircle: {
    display: 'flex',
    justifyContent: 'flex-end'
  },

  startDateEndBox: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '10px'
  },

  selectBoxStyle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '10px'
  },
  container: {
    height: "100%",
    margin: "15px 15px 15px 300px",
    paddingBottom: "25px",
    fontFamily: "Manrope",
    
  } as const,
  input: {
    fontFamily: "Manrope",
    width: "100%",
    border: "1px solid #e6deed",
    height: "40px",
    padding: "10px 10px 10px 40px",
    fontSize: "16px",
    borderRadius: "10px",
  } as const,
  boxStyle_1: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },

  fontStyle_1: {
    fontFamily: 'Manrope',
    fontSize: '20px',
    fontWeight: 700,
    color: '#33354D'
  },
  fontStyle_Head: {
    fontFamily: 'Manrope',
    fontSize: '30px',
    fontWeight: 600,
    color: '#33354D'
  },

  fontStyle_2: {
    fontFamily: 'Manrope',
    fontSize: '18px',
    fontWeight: 700,
    color: '#33354D'
  },

  fontStyle_3: {
    fontFamily: 'Manrope',
    fontSize: '16px',
    fontWeight: 400,
    color: '#33354D'
  },

  dropdownStyle: {
    color: '#A3A6C2'
  },

  buttonBoxSyle: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '20px',
    marginTop: '25px',
    width:"98%",
    border: 'none', // Ensure there's no border by default
    background: 'transparent', // Ensures no background is applied
    padding: 0, // Removes any default padding
  },

  cancelButton: {
    color: '#8760A9',
    fontFamily: 'Manrope',
    fontSize: '16px',
    fontWeight: 700,
    cursor: 'pointer'
  },

  submitButton: {
    backgroundColor: '#8760A9',
    fontFamily: 'Manrope',
    fontSize: '16px',
    fontWeight: 700,
    color: '#FFFFFF',
    textTransform: 'initial' as const,
    padding: '5px 35px 5px 35px',
  },

  textButtonStyle: {
    fontSize: '16px',
    color: '#8760A9',
    fontWeight: 700,
    marginTop: '12px',
    display: 'flex',
    alignItems: 'center',
    gap: '6px',
    cursor: "pointer"
  },

  inputLabel: {
    color: '#3D405C',
    fontSize: '12px',
    fontWeight: 500,
    marginBottom: '4px'
  },

  removeIconStyle: {
    cursor: 'pointer',
    fontSize: '24px',
    marginLeft: '5px'
  },

  boxStyle_4: {
    display: 'flex',
    flexDirection: 'column',
  },

  textColorRed: {
    color: 'red'
  }
};
const webStyles = {
  topheader: {
    display: "flex",
    justifyContent: "space-between",
    padding: '24px 0'
  } as const,
  navigation: {
    color: '#3D405C',
    display:'flex',
    alignItems:'center',
    gap:'8px',
    fontSize: '14px',
    fontFamily: 'Manrope',
    fontWeight: 400,
    fontStyle: 'normal',
    lineHeight: 'normal',
  } as const,
}
// Customizable Area End
