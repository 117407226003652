import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValues: string;
  txtSavedValues: string;
  enableFields: boolean;
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ServiceManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.handlePrivateRoute()
  }
  handlePrivateRoute = async() => {
    
    let token = await getStorageData("OrgToken")
    if(token == null){
      window.location.href = 'EmailAccountLoginBlock'
    }
  }
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.NavigationPropsMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValues: "",
      txtSavedValues: "A",
      enableFields: false,
      // Customizable Area Start
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let val = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValues + " To: " + val
      );

      this.setState({ txtSavedValues: val });
    }

    // Customizable Area Start
    // Customizable Area End
  }

  

  // Customizable Area Start

 
  redirectUser = (data: string) => {
 
  
    if (data === "Cfslamanagementlist1") {
 this.props.navigation.navigate('Cfslamanagementlist');
    }
  
  };
  
  // Customizable Area End
}
