import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import * as Yup from "yup";
import toast from "react-hot-toast";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  accountStatus: any;
  passwordRules: any;
  emailValue: any;
  enablePasswordField: Boolean;
  email: string;
  password: string;
  forgotPasswordOTP: string;
  accountType: string;
  otpSchema: any;
  phoneValue: any;
  activeStep: number;
  countryCodeSelected: any;
  token: any;
  pinError: string;
  otpVerifiedSuccessfully: boolean;
  resetPassSucMsg: string;
  resetPassSuccessfully: boolean;
  btnConfirmPasswordShowHide: Boolean;
  pin:string;
  viewPassword: boolean
  isOTPReceived:boolean;
  isLoading: boolean
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: any;
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class ForgotPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  validationAPICallId: any;
  requestEmailOtpCallId: any;
  forgotPasswordOTPCallId: any;
  requestPhoneOtpCallId: any;
  requestChangePasswordCallId: any;
  requestGoToConfirmationCallId: any;
  otpToken: any;
  resendForgotPasswordApiCallId: any;
  requestEmailOtpCallIdWeb: any;
  resetPasswordAPICallId: any;

  verifyOTPToResetPasswordCallIdWeb: any;
  //Properties from config

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start

    //otpSchema
    let otpSchema = {
      otpCode: Yup.number()
        .min(4)
        .required(configJSON.otpCodeIsRequired),
    };

    this.state = {
      activeStep: 0,
      email: "",
      password: "",
      forgotPasswordOTP: "",
      phoneValue: "",
      otpVerifiedSuccessfully: false,
      countryCodeSelected: "",
      btnConfirmPasswordShowHide: true,
      passwordRules: "",
      accountType: "sms",
      viewPassword: true,
      resetPassSuccessfully: false,
      pinError: " ",
      pin:"",
      isLoading: false,
      resetPassSucMsg: "",
      accountStatus: "ChooseAccountType",
      emailValue: "",
      otpSchema: otpSchema,
      token: "",
      isOTPReceived:false,
      enablePasswordField: true,
    };
    // Customizable Area End
  }

    // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
  }
  // Customizable Area End

    // Customizable Area Start
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const forgotPasswordAPIRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const validateOTPErrorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
  
      if (forgotPasswordAPIRequestCallId && responseJson) {
        await this.handleForgotPasswordResponse(forgotPasswordAPIRequestCallId, responseJson, validateOTPErrorResponse);
      }
    }
  }
  
  async handleForgotPasswordResponse(forgotPasswordAPIRequestCallId: string, responseJson: any, validateOTPErrorResponse: any) {
    if (forgotPasswordAPIRequestCallId === this.verifyOTPToResetPasswordCallIdWeb) {
      await this.handleVerifyOTPResponse(responseJson);
    } else if (forgotPasswordAPIRequestCallId === this.forgotPasswordOTPCallId) {
      await this.handleForgotPasswordOTPCall(responseJson);
    } else if (forgotPasswordAPIRequestCallId === this.resetPasswordAPICallId) {
      await this.handleResetPassword(responseJson);
    } else if (forgotPasswordAPIRequestCallId === this.resendForgotPasswordApiCallId) {
      await this.handleResendForgotPassword(responseJson);
    } 
  }
  
  async handleVerifyOTPResponse(responseJson: any) {
    this.setState({isLoading:false})
    if (responseJson.errors) {
      toast.error(responseJson.errors[0].pin);
    } else {
      this.handleNextStep(true);
      toast.success('OTP Verified Successfully');
      localStorage.setItem('tokenForget', JSON.stringify(responseJson.token));
    }
  }
  
  async handleForgotPasswordOTPCall(responseJson: any) {
    this.setState(
      {isLoading:false}
    )
    if (responseJson.error) {
      toast.error(responseJson.error);
    } else {
      this.handleNextStep(true);
      toast.success('OTP Sent Successfully!');
    }
  }
  
  async handleResendForgotPassword(responseJson: any) {
    if (responseJson.errors) {
      toast.error(responseJson.errors);
    } else {
      toast.success(responseJson.message);
    }
  }
  
  async handleResetPassword(responseJson: any) {
    this.setState({isLoading:
      false
    })
    if (responseJson.message) {
      toast.success(responseJson.message);
      setTimeout(() => {
        window.location.href = "EmailAccountLoginBlock"
      }, 2000);
    } else {
      toast.error('Something went wrong!');
    }
  }
  
  sendOtp(): boolean {
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };
    const attrs = {
      email: this.state.email,
    };

    const sendOTPData = {
      type: "email_account",
      attributes: attrs,
    };
    const httpBody = {
      data: sendOTPData,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.requestEmailOtpCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.emailOTPSentApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleNextStep = (isValid: boolean) => {
    if (isValid) {
      this.setState((prevState) => ({
        activeStep: prevState.activeStep + 1,
      }));
    }
  };
  ViewNewPassword = ()=>{
    this.setState({viewPassword: !this.state.viewPassword})
  }
  viewRetype = ()=>{
    this.setState({enablePasswordField: !this.state.enablePasswordField})
  }
  fogotPasswordOTP(): boolean {
    this.setState({isLoading: true})
    const header = {
      // Customizable Area Start
      // "Content-Type": configJSON.contentTypeApiAddDetail,
      'Content-Type': "application/json",
      // Customizable Area Start

    };
    const attrs = {
      email: this.state.email,
    };

    const forgotPasswodOTPData = {
      type: "email_account",
      attributes: attrs,
    };
    const fogotPasswordOTPHttpBody = {
      data: forgotPasswodOTPData,
    };
    const forgotPasswordRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.forgotPasswordOTPCallId = forgotPasswordRequestMessage.messageId;
    forgotPasswordRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.forgotPasswordOTPSentWeb
    );

    forgotPasswordRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    forgotPasswordRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(fogotPasswordOTPHttpBody)
    );

    forgotPasswordRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(
      forgotPasswordRequestMessage.id,
      forgotPasswordRequestMessage
    );
    return true;
  }

  resendFogotPasswordOTP = () => {
    const header = {
      "Content-Type": 'application/json',
    };
    const attrs = {
      email: this.state.email,
    };

    const resendForgotPassOTPData = {
      type: "email_account",
      attributes: attrs,
    };
    const fogotPasswordOTPHttpBody = {
      data: resendForgotPassOTPData,
    };
    const resendForgotPassRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.resendForgotPasswordApiCallId =
      resendForgotPassRequestMessage.messageId;
    resendForgotPassRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.resendForgotPasswordOTPSentWebApiEndPoint
    );

    resendForgotPassRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    resendForgotPassRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(fogotPasswordOTPHttpBody)
    );

    resendForgotPassRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(
      resendForgotPassRequestMessage.id,
      resendForgotPassRequestMessage
    );
    return true;
  };

  verifyFogotPasswordOTP = () => {
    this.setState({isLoading: true})
    const header = {
      "Content-Type": 'application/json',
    };
    const attrs = {
      email: this.state.email,
      pin: this.state.forgotPasswordOTP,
    };

    const verifyFogotPasswordOTPData = {
      type: "email_account",
      attributes: attrs,
    };
    const verifyForgotPasswordOTPHttpBody = {
      data: verifyFogotPasswordOTPData,
    };
    const verfiyOTPRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.verifyOTPToResetPasswordCallIdWeb = verfiyOTPRequestMessage.messageId;
    verfiyOTPRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.verifyOTPToForgotPasswordApiEndPoint
    );

    verfiyOTPRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    verfiyOTPRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(verifyForgotPasswordOTPHttpBody)
    );

    verfiyOTPRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(verfiyOTPRequestMessage.id, verfiyOTPRequestMessage);
  };

  resetPassword = () => {
    const tokeen = localStorage.getItem('tokenForget');
    const header = {
      "Content-Type": 'application/json',
      "token" :  (tokeen && typeof tokeen === 'string') ? JSON.parse(tokeen) : ""
    };
    this.setState({
      isLoading:
      true})
    const attrs = {
      create_new_password: this.state.password,
      repeat_new_password: this.state.password,
    };

    const resetPasswordData = {

      attributes: attrs,
    };
    const resetPasswordHttpBody = {
      data: resetPasswordData,
    };
    const resetPasswordRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.resetPasswordAPICallId = resetPasswordRequestMessage.messageId;
    resetPasswordRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.resetPasswordApiEndPoint
    );

    resetPasswordRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    resetPasswordRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(resetPasswordHttpBody)
    );

    resetPasswordRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePATCH
    );

    runEngine.sendMessage(
      resetPasswordRequestMessage.id,
      resetPasswordRequestMessage
    );
  };
    // Customizable Area End

  // Customizable Area Start
  // Customizable Area End
}
