import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import {
  IGroup,
  IAccount,
  IGroupAccount,
  ITask,
  Status,
  Priority,
  ITickets,
} from "./types";
import { getStorageData } from "../../../framework/src/Utilities";
import moment from "moment";
import toast from "react-hot-toast";
interface Ticket {
  id?: string;
  type?: string;
  attributes?: {
    subject: string;
    description: string;
    priority: string;
    planned_startdate: string;
    planned_enddate: string;
    planned_effort: null | string;
    requester: {
      id: string,
      name: string,
      email: string
    };
    status: string;
    source: null | string;
    impact: null | string;
    urgency: null | string;
    group: {
      id: string,
      name: string,
    };
    agent: {
      id: string,
      name: string,
      email: string
    };
    department: {
      id: string,
      name: string
    };
    category: {
      "data": {
          "id": string,
          "type": string,
          "attributes": {
              "id": string,
              "name": string,
              "description": string
          }
      }
  },
    tags: string;
    ticket_template_id: number;
    created_at: null | string;
    cc: { id: string, email: string }[];
  };
}
interface AgentAndRequester {
  id: string,
  type: string,
  attributes: {
    email: string

  }
}
interface Agent {
  id: string,
  type: string,
  attributes: {
    email: string
    id: string
    full_name: string
  }
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  id: string;
  // Customizable Area Start
  title: string;
  description: string;
  priority: string;
  status: string;
  assign_to: string;
  assign_to_type: string;
  editMode: boolean;
  token: string;
  taskList: ITask[];
  isVisibleModal: boolean;
  isVisibleAssignModal: boolean;
  dropdownGroup: boolean;
  dropdownStatus: boolean;
  dropdownAccount: boolean;
  dropdownPriority: boolean;
  groupList: IGroup[];
  statusList: Status[];
  priorityList: Priority[];
  selectedAccounts: IGroupAccount[];
  accountsData: IAccount[];

  selectedTab: number;
  ticketData: Ticket
  openDescModal: boolean;
  isLoading: boolean;
  rightPopUp: boolean;
  sidePopup: boolean;
  relatedTickets: ITickets[];
  note: string;
  titleText: string;
  plannedEffort: string;
  selectedGroup: string;
  selectedAssignee: string;
  assignDuration: string;
  plannedStartDate: string;
  plannedStartTime: string;
  plannedEndDate: string;
  plannedEndTime: string;
  childTicketsList: number[];
  agentsList: Agent[];
  allTickets: ITickets[];
  isOpenFormModal: boolean;
  ticketId: string | null
  // Customizable Area End
}

export interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class TaskController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getTasksApiCallId = "";
  postTaskApiCallId = "";
  putTaskApiCallId = "";
  deleteTaskApiCallId = "";
  getGroupsApiCallId = "";
  assignGroupApiCallId = "";
  getAccountsApiCallId = "";

  getTicketsDataApiCallId: string = ''
  getRelatedTicketsId: string = ''
  getTasksId: string = ''
  addChildTickets: string = ''
  addTasksId: string = ''
  token: string = ''
  id: string = '';
  ticketDeleteApiCallId: string = ''
  getTicketsId: string = '';
  getAllAgentsApiCallId: string = ''

  getRequesterDataAPICallId: string = "";
  getAllDepartmentAPICallId: string = "";
  updateTicketDataAPICallId: string = "";
  getAllGroupsAPICallId: string = "";
  getAllAgentsListAPICallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      id: "",
      title: "",
      description: "",
      priority: "",
      status: "",
      assign_to: "",
      assign_to_type: "",
      editMode: false,
      token: "",
      taskList: [],
      isVisibleModal: false,
      isVisibleAssignModal: false,
      dropdownGroup: false,
      dropdownStatus: false,
      dropdownAccount: false,
      dropdownPriority: false,
      groupList: [],
      selectedAccounts: [],
      accountsData: [],
      statusList: [
        { id: 1, name: "to_do" },
        { id: 2, name: "in_progress" },
        { id: 3, name: "complete" },
      ],
      priorityList: [
        { id: 1, name: "low" },
        { id: 2, name: "medium" },
        { id: 3, name: "high" },
      ],
      selectedTab: 0,
      ticketData: {},
      openDescModal: false,
      isLoading: false,
      rightPopUp: false,
      sidePopup: false,

      note: '',
      titleText: '',
      plannedEffort: '',
      selectedGroup: '',
      selectedAssignee: '',
      assignDuration: '',
      agentsList: [],
      plannedStartDate: '',
      plannedStartTime: '',
      plannedEndDate: '',
      plannedEndTime: '',
      childTicketsList: [],
      isOpenFormModal: false,
      relatedTickets: [],
      allTickets: [],
      ticketId: null
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.token = await getStorageData('authToken')
    this.id = this.props.navigation.getParam("navigationBarTitleText")
    const { navigation } = this.props
    const ticketID = navigation.getParam('id', 0);
    this.setState({ ticketId: ticketID })
    this.getTicketData();
    this.getToken();
    this.getAllTickets();
    this.getRelatedTicket(ticketID)
    this.setState({ token: this.token, id: ticketID }, () => {
      this.getAllAgents()
      this.getTasks(this.token);
      this.setState({ token: this.token }, () =>
        this.getAllAgents())
    }
    )
  }

  save() {
    if (this.state.titleText.trim()) {
      this.addTask();
    }
  }

  openRightPopUp() {

    let list: ITickets[] = [];

    this.state.allTickets.forEach((item: ITickets) => {
      item.isSelected = false
      list.push(item)
    })
    this.checkUI(false)
    this.setState({ allTickets: list, rightPopUp: true })
  }

  globalCheckBoxClick(event: React.ChangeEvent<HTMLInputElement>) {
    let list = this.state.allTickets
    this.state.allTickets.forEach(item => {
      item.isSelected = event.target.checked
    })
    this.checkUI(event.target.checked)
    this.setState({ allTickets: list })
  }

  checkUI(value: boolean) {
    // let elementList = document.getElementsByClassName('checkbox')
    // for(let i = 0; i < elementList.length; i++)
    // {
    //   let element = elementList[i] as HTMLInputElement
    //   element.checked = value;
    // }
  }

  checkBoxHandle(event: React.ChangeEvent<HTMLInputElement>, index: number) {
    let list = this.state.allTickets;
    this.state.allTickets.forEach(item => {
      if (item.id == index) {
        item.isSelected = event.target.checked
      }
    })
    this.setState({ allTickets: list })
  }

  addTickets() {
    let ids: number[] = [];
    let list = this.state.allTickets.filter(x => x.isSelected);
    list.forEach((item) => {
      ids.push(Number(item.id))
    })
    if (ids.length > 0) {
      this.addTicketsApi(ids);
    }
  }

  addTicketsApi = (list: number[]) => {

    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token
    };
    const httpBody = {
      "child_ticket_ids": list
    }
    this.setState({ rightPopUp: false })
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addChildTickets = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getRelatedTicketsEndPoint + this.state.id + '/add_child_tickets'
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  };

  handleDate(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, type: string) {
    switch (type) {
      case 'start':
        this.setState({ plannedStartDate: event.target.value })
        break;
      case 'end':
        this.setState({ plannedEndDate: event.target.value })
        break;
    }
  }

  handleTime(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, type: string) {
    switch (type) {
      case 'start':
        this.setState({ plannedStartTime: event.target.value })

        break;

      case 'end':
        this.setState({ plannedEndTime: event.target.value })
    }
  }

  handleText(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, type: string) {
    switch (type) {
      case 'title':
        this.setState({ titleText: event.target.value })
        break;

      case 'plannedEffort':
        this.setState({ plannedEffort: event.target.value })
        break;

      case 'note':
        this.setState({ note: event.target.value })
        break;
    }
  }

  handleSelect(event: any, type: string) {
    switch (type) {
      case 'group':
        this.setState({ selectedGroup: event.target.value })
        break;
      case 'status':
        this.setState({ status: event.target.value })
        break;
      case 'assignee':
        this.setState({ selectedAssignee: event.target.value })
        break;
      case 'duration':
        this.setState({ assignDuration: event.target.value })
        break;
    }
  }

  async getAllAgents() {
    this.setState({ isLoading: true });
    const header = {
      "Content-Type": configJSON.getAllAgentsApiContentType,
      token: await getStorageData("authToken")
    };
    let requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAllAgentsApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllAgentsApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getAllAgentsApiCallId = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getRelatedTicket = (index: any) => {
    this.setState({ isLoading: false })
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getRelatedTicketsEndPoint + index + '/related_tickets'
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    this.getRelatedTicketsId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getAllTickets = async () => {
    this.setState({ isLoading: true })
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: await getStorageData("authToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllTicketsApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    this.getTicketsId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  receive = async (from: string, message: Message) => {
    runEngine.debugLog("Message Received", message);
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const apiRequestId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    )
    this.handleIsTokenValid(responseJson)

    this.handleApiRequestID(apiRequestId, message, responseJson);

    this.handleFetchGroupList(message);

    this.handleAssignTaskGroup(message);

    this.handleFetchAccountList(message)
  };

  handleDeleteTicketApiCall = (ticketID: any) => {
    this.setState({ isLoading: true });

    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTicketDataApiEndPoint + ticketID
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );
    this.ticketDeleteApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleApiRequestID = (apiRequestId: any, message: Message, responseJson: any) => {
    switch (apiRequestId) {
      case this.getTicketsDataApiCallId:
        this.handleTicketDataResponse(responseJson);
        break;
      case this.getTasksApiCallId:
        this.handleTasksResponse(message);
        break;
      case this.putTaskApiCallId:
        this.handlePutTaskResponse(message);
        break;
      case this.deleteTaskApiCallId:
        this.handleDeleteTaskResponse(message);
        break;
      case this.ticketDeleteApiCallId:
        this.handleDeleteTicketResponse(message);
        break;
      case this.getAllAgentsApiCallId:
        this.handleAgentsData(responseJson)
        break;
      case this.postTaskApiCallId:
        this.handleAddTask(responseJson)
        break;
      case this.addChildTickets:
        this.setState({ isLoading: false })
        this.handleAddRealtedTicketsResponse(responseJson);
        break;
      case this.getTicketsId:
        this.handleTicketsData(responseJson)
        break;
      case this.getRelatedTicketsId:
        this.handleRelatedTicketsData(responseJson)
        break;
      default:
        break;
    }
  }

  handleAddRealtedTicketsResponse(response: any) {
    if (!response.errors) {
      this.getRelatedTicket(this.state.id)
    }
  }

  clearState() {
    this.setState({
      titleText: '',
      selectedGroup: '',
      selectedAssignee: '',
      assignDuration: '',
      plannedEffort: '',
      plannedStartDate: '',
      plannedStartTime: '',
      plannedEndDate: '',
      plannedEndTime: '',
      note: ''
    })
  }

  openModal() {
    this.clearState();
    this.setState({ sidePopup: true })
  }

  handleAddTask(resppnse: { data: ITask[] }) {
    if (resppnse) {
      this.setState({ sidePopup: false },
        () => this.getTasks(this.token))
    }
  }

  handleAgentsData(response: { data: Agent[] }) {
    this.setState({ agentsList: response.data })
  }

  handleRelatedTicketsData(responseJson: { data: ITickets[] }) {
    this.setState({ relatedTickets: responseJson.data })
  }

  handleTicketsData(responseJson: {tasks : { data: ITickets[] }}) {
    let list: ITickets[] = [];

    responseJson?.tasks?.data.forEach((item: ITickets) => {
      item.isSelected = false;
      list.push(item)
    })
    this.setState({ allTickets: list })
  }

  handleTasksResponse = (message: Message) => {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (!responseJson.errors && responseJson?.tasks.data) {
        this.setState({
          taskList: responseJson?.tasks.data.sort(
            (a: ITask, b: ITask) => parseInt(a.id) - parseInt(b.id)
          ),
        });
      } else {
        this.showAlert("Alert", "No Data", "");
        this.setState({ taskList: [] });

        const errorResponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );

        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
  }

  handlePostTaskResponse = (message: Message) => {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (!responseJson.errors && responseJson.data) {
        this.getTasks(this.state.token);
        this.setState({ isVisibleModal: false });
      } else {
        const errorResponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorResponse);
      }
    } else if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      runEngine.debugLog("Message Received", message);

      const token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token });
      this.getGroups(token);
    }
  }

  handlePutTaskResponse = (message: Message) => {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (!responseJson.errors && responseJson.data) {
        this.getTasks(this.state.token);
        this.setState({ isVisibleModal: false });
      } else {
        const errorResponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
  }

  handleDeleteTaskResponse = (message: Message) => {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (!responseJson.errors && responseJson.message) {
        this.getTasks(this.state.token);
      } else {
        const errorResponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
  }


  handleIsTokenValid = (responseJson: any) => {
    // This will rerdirect user to login page if token is not valid
    if (responseJson && Array.isArray(responseJson.errors) && responseJson.errors.length > 0 && (responseJson.errors[0].token === "Token has Expired" || responseJson.errors[0].token === 'Invalid token')) { this.goToLogin(); }
  }

  handleFetchGroupList = (message: Message) => {
    // Condition to fetch group list.
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getGroupsApiCallId != null &&
      this.getGroupsApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (!responseJson.errors && responseJson.data) {
        this.setState({
          groupList: responseJson.data.sort(
            (a: IGroup, b: IGroup) => parseInt(a.id) - parseInt(b.id)
          ),
        });
      } else {
        this.setState({ groupList: [] });
      }
    }
  }

  handleAssignTaskGroup = (message: Message) => {
    // Condition to assign task to the groups/accounts.
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.assignGroupApiCallId != null &&
      this.assignGroupApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (!responseJson.errors && responseJson.data) {
        this.getTasks(this.state.token);
        this.setState({ isVisibleAssignModal: false });
      } else {
        const errorResponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
  }

  handleFetchAccountList = (message: Message) => {
    if (this.isAccountsResponse(message)) {
      this.handleAccountsResponse(message);
    }
  }

  isAccountsResponse = (message: Message) => (
    getName(MessageEnum.RestAPIResponceMessage) === message.id &&
    this.getAccountsApiCallId != null &&
    this.getAccountsApiCallId ===
    message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
  );

  handleAccountsResponse = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (!responseJson.errors && responseJson.data) {
      const data = responseJson.data.map((account: IGroupAccount) => {
        const isSelected = this.state.selectedAccounts.some(
          (gAccount: IGroupAccount) => gAccount.id === account.id
        );
        return { ...account, isSelected };
      });
      this.setState({ accountsData: data });
    } else {
      this.showAlert("Alert", "No Data", "");
      this.setState({ accountsData: [] });

      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      this.parseApiCatchErrorResponse(errorResponse);
    }
  }

  handleDeleteTicketResponse = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson.data.message) {
      toast.success(responseJson.data.message);
      this.setState({ isLoading: false })
      setTimeout(() => {
        this.props.navigation.navigate('TaskList');
      }, 2000);
    } else {
      toast.error('Something went wrong!')
      this.setState({ isLoading: false })
    }

  }
  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  isStringNullOrBlank = (str: string) => {
    return str === null || str.length === 0;
  };

  hideModal = () => {
    this.setState({ isVisibleModal: false });
  };

  hideAssignModal = () => {
    this.setState({ isVisibleAssignModal: false });
  };

  handleInputTitle = (title: string) => {
    this.setState({ title });
  };

  handleInputDescription = (description: string) => {
    this.setState({ description });
  };

  toggleAssignModal = () => {
    this.getAccounts(this.state.token);
    this.getGroups(this.state.token);
    this.setState({
      dropdownAccount: false,
      dropdownGroup: false,
      isVisibleAssignModal: !this.state.isVisibleAssignModal,
      editMode: true,
    });
  };

  showAddModal = () => {
    this.setState({
      title: "",
      description: "",
      priority: "",
      status: "",
      isVisibleModal: !this.state.isVisibleModal,
      editMode: false,
    });
  };

  showEditModal = () => {
    this.setState({
      isVisibleModal: !this.state.isVisibleModal,
      editMode: true,
    });
  };

  expandGroupView = () => {
    this.setState({
      dropdownGroup: !this.state.dropdownGroup,
    });
  };

  expandStatusView = () => {
    this.setState({
      dropdownStatus: !this.state.dropdownStatus,
    });
  };

  expandPriorityView = () => {
    this.setState({
      dropdownPriority: !this.state.dropdownPriority,
    });
  };

  expandAccountsView = () => {
    this.setState({ dropdownAccount: !this.state.dropdownAccount });
  };

  handleAccountSelect = (dataId: string) => {
    let newData = this.state.accountsData.map((account: IAccount) => {
      if (account.id === dataId) {
        return { ...account, isSelected: !account.isSelected };
      }
      return account;
    });
    this.setState({ accountsData: newData });
  };

  handleSelectPriority = (item: Priority) => {
    this.setState({ priority: item.name, dropdownPriority: false });
  };

  handleSelectStatus = (item: Status) => {
    this.setState({ status: item.name, dropdownStatus: false });
  };

  handleAssignToModal = (item: ITask) => {
    this.setState({
      id: item.id,
      title: item.attributes.title,
      description: item.attributes.note,
      priority: item.attributes.priority,
      status: item.attributes.status,
    });
    if (item.attributes.assigned_to) {
      this.setState({
        assign_to:
          item.attributes.assigned_to.type === "group"
            ? item.attributes.assigned_to.attributes.name
            : item.attributes.assigned_to.attributes?.first_name || "",
        assign_to_type: item.attributes.assigned_to.type,
      });
    }
    this.toggleAssignModal();
  };

  handleEditTask = (item: ITask) => {
    this.setState({
      id: item.id,
      title: item.attributes.title,
      description: item.attributes.note,
      priority: item.attributes.priority,
      status: item.attributes.status,
    });
    this.showEditModal();
  };

  // Function to fetch tasks list from the API
  getTasks = (token: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTasksApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.tasksApiEndPoint}` + this.state.id + '/tasks'
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Function to add new task and send it to API
  addTask = async () => {


    const header = {
      "Content-Type": configJSON.apiContentType,
      token: await getStorageData('authToken')
    };

    const httpBody = {
      "task": {
        "title": this.state.titleText,
        "planned_startdate": this.state.plannedStartDate,
        "planned_enddate": this.state.plannedEndDate,
        "planned_effort": this.state.plannedEffort,
        // "notify_before": "15 minutes", 
        "status": this.state.status,
        "note": this.state.note,
        "agents_and_requester_id": Number(this.state.selectedAssignee) != 0 ? Number(this.state.selectedAssignee) : ""
      }
    }
    this.setState({ sidePopup: false })
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postTaskApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.tasksApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.tasksApiEndPoint + this.state.id + '/tasks'
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  };

  // Function to update task details and send it to API
  editTask = (taskId: string) => {
    if (
      this.isStringNullOrBlank(this.state.title) ||
      this.isStringNullOrBlank(this.state.description)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory,
        ""
      );
    } else {
      const header = {
        "Content-Type": configJSON.apiContentType,
        token: this.state.token,
      };
      const httpBody = {
        title: this.state.title,
        description: this.state.description,
        status: this.state.status,
        priority: this.state.priority,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.putTaskApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.tasksApiEndPoint + "/" + `${taskId}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.putApiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  // Function to delete task and send it to API
  deleteTask = (taskId: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteTaskApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.tasksApiEndPoint + "/" + `${taskId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Function to fetch groups list from the API
  getGroups = (token: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getGroupsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.groupsApiEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Function to assign task to groups/accounts and sent it to the API
  handleAssignTo = (
    taskId: string,
    assignToType: string,
    assignToId: string
  ) => {
    if (
      this.isStringNullOrBlank(taskId) ||
      this.isStringNullOrBlank(assignToId)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory,
        ""
      );
    } else {
      const header = {
        "Content-Type": configJSON.apiContentType,
        token: this.state.token,
      };
      const httpBody = {
        assigned_to_type: assignToType,
        assigned_to_id: assignToId,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.assignGroupApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.tasksApiEndPoint + "/" + `${taskId}` + "/assign"
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postApiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  // Function to fetch accounts list from the API
  getAccounts = (token: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAccountsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.tasksAccountsApiEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getTicketData = () => {
    this.setState({ isLoading: true })
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTicketDataApiEndPoint + this.state.ticketId
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    this.getTicketsDataApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goToLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  handleTabChange = (_: null | unknown, newValue: number) => { this.setState({ selectedTab: newValue }) };

  handleDescModal = (value: boolean) => this.setState({ openDescModal: value })

  handleModalState = () => {
    this.setState({ openDescModal: false })
  }

  navigateUser = () => {
    const navigateToLoginMessage: Message = new Message(
      getName(MessageEnum.NavigationRequestersMessage)
    );
    navigateToLoginMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    navigateToLoginMessage.addData(getName(MessageEnum.UrlPathParamMessage), 'createRequester');
    this.send(navigateToLoginMessage);
  }

  handleOpenFormModal = () => {
    this.setState({ isOpenFormModal: true })
  }

  a11yProps(index: number) {
    return {
      id: `scrollable-force-tab-${index}`,
      'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
  }

  getStateColor = (state: string | undefined) => {
    if (state) {
      const stateColorMapping: { [key: string]: string } = {
        'Open': '#11A64A',
        'Pending': '#F59E0B',
        'Resolved': '#11A64A',
        'Closed': '#DC2626',

        'Medium': '#11A64A',
        'Low': '#F59E0B',
        'High': '#DC2626',
        'Urgent': '#DC2626'
      };

      return stateColorMapping[state]
    } else {
      return '#11A64A'
    }
  };

  convertStringToDate = (date: string | undefined) => {
    if (date) {

      const momentDate = moment(date);

      const formattedDate = momentDate.format("DD MMM YYYY");
      const formattedTime = momentDate.format("HH:mm:ss");


      const converted = {
        date: formattedDate,
        time: formattedTime
      };

      return converted
    } else {
      return {
        date: undefined,
        time: undefined,
      }
    }
  }

  handleInvalidText = (text: string | undefined | null) => text ? text : '-'

  handleTicketDataResponse = (responseJson: { data: Ticket }) => {
    if (responseJson) {
      this.setState({ isLoading: false })
    }
    if (responseJson.data) {
      this.setState({
        ticketData: responseJson.data,
      })
    }
  }

  handleCloseFormModal = () => {
    this.setState({ isOpenFormModal: false }, () => this.getTicketData())
  }
  // Customizable Area End
}
