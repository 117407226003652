import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData } from "../../../framework/src/Utilities";
import toast from "react-hot-toast";

interface AssociateAssets{
  id: string
  name: string,
  type: string,
  used_by:string
  department: string
  location: string
  serial_no:string
  state: string
}
interface AssetAttributes {
  id: number;
  display_name: string;
  description: string;
  asset_type: string;
}

interface Asset {
  id: string;
  type: string;
  attributes: AssetAttributes;
}
interface TicketTemplate {
  id: string;
  type: string;
  attributes: {
      ticket_template_attachments: any | null;
    title: string;
    description: string;
    available_for: string;
    ticket: {
      data: {
        id: string;
        type: string;
        attributes: {
          associate_assets:{data:Asset[]}
          subject: string;
          description: string;
          priority: string;
          planned_startdate: string;
          planned_enddate: string;
          planned_start_time: string | null;
          planned_end_time: string | null;
          planned_effort: string;
          status: string;
          source: string;
          ticket_template_id: number;
          created_at: string;
          impact: string | null;
          urgency: string | null;
          category: string;
          tags: string[];
          requester: { id: number | null;
              full_name: string | null;
              email: string | null;};
          cc: any[];
          ticket_attachments: any | null; 
          group: { name: string | null;
            id: number | null; };
          department: {
            name: string | null; id: number | null;};
          agent: { id: number | null;full_name: string | null;email: string | null;};
         
        };
      };
    };
   
  };
}

interface ManagedByGroup {
  id: number | null;
  name: string | null;
}

interface UsedBy {
  id: number | null;
  name: string | null;
  email: string | null;
}

interface Department {
  id: number | null;
  name: string | null;
}

interface ManagedBy {
  id: number | null;
  name: string | null;
  email: string | null;
}

interface AssetAttributes {
  id: number;
  display_name: string;
  description: string;
  impact: string;
  asset_type: string;
  asset_tag: string;
  end_of_life: string;
  location: string;
  model: string | null;
  serial_no: string | null;
  received_by: string | null;
  purchase_date: string | null;
  dealer_id: string | null;
  managed_by_group: ManagedByGroup;
  used_by: UsedBy;
  department: Department;
  managed_by: ManagedBy;
  assigned_on: string;
  asset_files: string | null;
}

interface Asset {
  id: string;
  type: string;
  attributes: AssetAttributes;
}
interface Agent {
  id: number;
  email: string;
  agent_type: string;
  full_name: string;
}
export interface AllGroupsList {
  id: string,
  type: string,
  attributes: {
      name: string,
      category: {
          data: {
              id: number | null,
          }},
      agents: Agent[]
  }
}
export interface AllAgentsList {
  id: string,
  type: string,
  attributes: {
      full_name: string
  }
}

export interface DepartmentList {
  id: string,
  type: string,
  attributes: {
      department_name: string
  }
}
interface TicketTemplate {
  title: string;
  index: string;
}
interface FileData {
  name: string;
  lastModified: number;
  size: number;
  type: string
}
export interface CategoryList {
  id: string;
  type: string;
  attributes: {
      name: string;
  }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
    requestersList: any[];
    isAddCcDisabled: boolean;
    selectedCategoryValue: CategoryList
    isLoading: boolean,
    txtInputValue: string;
    filteredCategories: CategoryList[]
    templateList: TicketTemplate[];
    subject: string;
    showAssetChip: boolean
    selectedTemplate: string | number;
    subjectError: string,
    sourceError : string;
    title: string;
    fileData: FileData[] | null;
    fileName: string
    fileNames: string[]
    titleError:string;
    generalDescription:string;
    generalDescriptionError:string;
    statusError : string;
    agentValue: AllAgentsList;
    selectedType: string,
    selectedStatus: string,
    selectedPriority: string,
    availableFor: string,
    departmentValue: DepartmentList
    allGroupList: AllGroupsList[],
    selectedGroup: string,
    filteredOptions: AllGroupsList[];
    allAgentsList: AllAgentsList[],
    filteredAgents: AllAgentsList[],
    association_assests:AssociateAssets[]
    allDepartmentList: DepartmentList[],
    filteredDepartmentList: DepartmentList[],
    description: string;
    descriptionError: string;
    categoryList: CategoryList[];
    dueDate: string;
    dueTime: string;
    dueDateError: string;
    dueTimeError: string;
    typeError: string;
    riskError: string
    startDate: string;
    startTime: string;
    endDate: string;
    endTime: string;
    timeDifference: string;
    inputValue: string;
    selectedTags: string[];
    requesterError: string;
    isValidationError: boolean;
    startDateError : string;
    ticket_id: string;
    endDateError : string;
    changedDepartment: string;
    changedAgent: string;
    changedGroup: string;
    changedRequester: string;
    startTimeError:string;
    endTimeError: string;
    filtered_assets: AssociateAssets[]
    toastOpen: boolean;
    selectedAssets: AssociateAssets[]
    editTemplate: boolean
    template_id: string;
    randomstate: string
    createAnchorEl: HTMLElement | null | undefined,
    showAssetsDropdown: boolean
    showAssetModal: boolean
    selectedCategory: string
    disableDropdowns: boolean
    selectedRisk: string
    showDropdownMenu: boolean
    risk: {id: string,name: string}[]
    editedGroup: {id: string, type: string, attributes: {name: string,category: {data: {id: number | null}}}};
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ChangeTicketFormController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  createTicketApiCallId: string = ''
  getSingalTemplateDataApiCallId: string = ''
  token: string = ''
  status: { title: string, id: string }[] = [
    {
      id: 'Open',
      title: 'Open',
    },
    {
      id: 'Closed',
      title: 'Closed',
    },
    {
      id: 'Pending',
      title: 'Pending',
    },
    {
      id: 'Resolved',
      title: 'Resolved'
    }
  ]
  type = [
    {
      id: '1',
      title: 'Hardware'
    },
    {
      id: '2',
      title: 'Software'
    },
    {
      id: '3',
      title: 'Network'
    },
    {
      id: '4',
      title: 'Process_change'
    },
  ]
  getRequesterDataAPICallId: string = "";
  updateFormApiId: string = ''
  getAllGroupsAPICallId: string = "";
  getAllAgentsListAPICallId: string = "";
  getAllDepartmentAPICallId: string = "";
  submitFormAPICallId: string = "";
  getAllCategoryAPICallId: string = '';
  getAllAssetsApiCallId: string = ''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      requestersList: [],
      isAddCcDisabled: false,
      selectedCategoryValue: { id: '', type: '', attributes: { name: '' } },
      isLoading: false,
      txtInputValue: '',
      typeError:'',
      riskError:'',
      filteredCategories: [],
      templateList: [],
      subject: '',
      showAssetChip: false,
      editTemplate:false,
      selectedTemplate: '',
      subjectError: '',
      sourceError: '',
      title: '',
      showDropdownMenu: false,
      fileData: null,
      fileName: '',
      fileNames: [],
      disableDropdowns: false,
      selectedRisk: '',
      risk:[{id:'1',name:'Low'},{id:'2',name:'Medium'},{id:'3',name:'High'}],
      titleError: '',
      generalDescription: '',
      generalDescriptionError: '',
      statusError: '',
      agentValue: { id: '', type: '', attributes: { full_name: '' } },
      selectedType: "",
      selectedStatus: this.status[0].id,
      randomstate:'',
      selectedPriority: "Low",
      availableFor: '',
      departmentValue: { id: '', type: '', attributes: { department_name: '' } },
      allGroupList: [],
      selectedGroup: '',
      filteredOptions: [],
      allAgentsList: [],
      filteredAgents: [],
      association_assests: [],
      allDepartmentList: [],
      filteredDepartmentList: [],
      description: '',
      descriptionError: '',
      categoryList: [],
      dueDate: '',
      dueTime: '',
      dueDateError: '',
      dueTimeError: '',
      startDate: "",
      startTime: "",
      endDate: "",
      endTime: "",
      timeDifference: "",
      inputValue: '',
      selectedTags: [],
      requesterError: '',
      isValidationError: false,
      startDateError: '',
      ticket_id: '',
      endDateError: '',
      changedDepartment: '',
      changedAgent: '',
      changedGroup: '',
      changedRequester: '',
      startTimeError: '',
      endTimeError: '',
      filtered_assets: [],
      toastOpen: false,
      selectedAssets: [],
      template_id: '',
      createAnchorEl: null,
      showAssetsDropdown: false,
      showAssetModal: false,
      selectedCategory: '',
      editedGroup: { id: '', type: '', attributes: { name: '', category: { data: { id: null } } } },
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
      )
      this.setState({
          isLoading: false
      })
      if (responseJson && Array.isArray(responseJson.errors) && responseJson.errors.length > 0 &&
          (responseJson.errors[0].token === "Token has Expired" || responseJson.errors[0].token === 'Invalid token')) {
          this.goToLogin();
      } 
      if(responseJson && Array.isArray(responseJson.errors) && responseJson.errors[0].length>0){
          toast.error(responseJson.errors[0])
      }
      switch (apiRequestCallId) {
     

          case this.getAllGroupsAPICallId:
              this.handleGroupApiDataResponse(responseJson);
              break;
          case this.getAllDepartmentAPICallId:
              this.handleDepartmentListResponse(responseJson);
              break;

          case this.submitFormAPICallId:
              this.handleCreateTicketResponse(responseJson);
              break;
          case this.getAllCategoryAPICallId:
              this.handleCategoryListResponse(responseJson);
              break;
          case this.getAllAssetsApiCallId:
              this.handleAssetsresponse(responseJson);
              break;
        case this.updateFormApiId:
          this.handleUpdateTicketResponse(responseJson);
          break;
      }
  }
    // Customizable Area End
  }

  txtInputWebProps = {
    secureTextEntry: false,
  };

  txtInputProps = this.txtInputWebProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  // web events
  
  // Customizable Area Start

  async componentDidMount() {
    this.token = await getStorageData('authToken')
    // this.getTemplateData()
    this.getAllGroups()
    this.getAllCategoryList()
    this.setState({ filteredOptions: this.state.allGroupList });
    this.getAllAssets()
    this.setState({ filteredAgents: this.state.allAgentsList });
    this.getAllDepartment()
    this.setState({filteredCategories: this.state.categoryList})
    this.setState({ filteredDepartmentList: this.state.allDepartmentList })
    this.handleGetDataFromSessionStorage()
  }

  // API integration
  goToLogin() {
    const messages: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    messages.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(messages);
  }
  getAllAssets = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllAssetsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllAssetsApiEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getAllCategoryList = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllCategoryAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllCategoriesApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  getAllGroups = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllGroupsAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllGroupsApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  someRandomFunction(){
    this.setState({randomstate:'something'})
  }

  getAllDepartment = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllDepartmentAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllDepartmentsApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getDisabledBackground(){
      return  this.state.disableDropdowns ?
       "#e9e9e9":""
  }
  handleGroupApiDataResponse = (responseJson: { data: AllGroupsList[] }) => {
    if (responseJson.data) {
        const groupData = responseJson.data.map((group) => ({
            id: group.id,
            type: 'someType',
            attributes: {
                name: group.attributes.name || '',
                agents: group.attributes.agents ,
                category: 
                group.attributes.category 
                || '',
               
            }
        }));
        this.setState({
            allGroupList: groupData,
            filteredOptions: groupData
        })
    }
}

  handleAgentsListResponse = (responseJson: { data: AllAgentsList[] }) => {
    const agentData = responseJson.data.map((agents) => ({
      id: agents.id,
      type: agents.type,
      attributes: {
        full_name: agents.attributes.full_name || ''
      }
    }));
    this.setState({
      allAgentsList: agentData,
      filteredAgents: agentData
    })

  }

  handleDepartmentListResponse = (responseJson: { departments: { data: DepartmentList[] } }) => {

    const departmentData = responseJson.departments.data.map((department) => ({
      id: department.id,
      type: 'someType',
      attributes: {
        department_name: department.attributes.department_name || ''
      }
    }));
    this.setState({
      allDepartmentList: departmentData,
      filteredDepartmentList: departmentData
    })

  }

  handleCreateTicketResponse = (responseJson: { data: TicketTemplate }) => {
    if (responseJson.data) {
      toast.success(configJSON.successTicketTemplateCreateMessage)
      this.setState({
        isValidationError: false,
      })
      setTimeout(() => {
        this.props.navigation.navigate("FormTemplates")
      }, 3000);
    }
  }

  handleGetDataFromSessionStorage = async()=>{
    let data = sessionStorage.getItem('changeTemplate')
    if (data) {
      let parsedData = JSON.parse(data);
      this.setState({
        editTemplate:true,
        title: parsedData.attributes.title,
        generalDescription: parsedData.attributes.description,
        availableFor: parsedData.attributes.available_for,
        subject: parsedData.attributes.change_request.data.attributes.subject,
        description: parsedData.attributes.change_request.data.attributes.description,
        selectedPriority: parsedData.attributes.change_request.data.attributes.priority,
        selectedRisk: parsedData.attributes.change_request.data.attributes.risk ?  parsedData.attributes.change_request.data.attributes.risk: this.state.selectedRisk,
        selectedTags: parsedData.attributes.change_request.data.attributes.tags && parsedData.attributes.change_request.data.attributes.tags.length > 0  ? parsedData.attributes.change_request.data.attributes.tags.split(',') : this.state.selectedTags,
        startDate: parsedData.attributes.change_request.data.attributes.planned_startdate ? parsedData.attributes.change_request.data.attributes.planned_startdate.split('T')[0] : this.state.startDate,
        startTime: parsedData.attributes.change_request.data.attributes.planned_startdate ? parsedData.attributes.change_request.data.attributes.planned_startdate.split('T')[1].split('.')[0] : this.state.startTime,
        endDate: parsedData.attributes.change_request.data.attributes.planned_enddate? parsedData.attributes.change_request.data.attributes.planned_enddate.split('T')[0] : this.state.endDate,
        endTime: parsedData.attributes.change_request.data.attributes.planned_enddate? parsedData.attributes.change_request.data.attributes.planned_enddate.split('T')[1].split('.')[0] : this.state.endTime,
        timeDifference: parsedData.attributes.change_request.data.attributes.planned_effort,
        selectedType: parsedData.attributes.change_request.data.attributes.change_type,
        selectedCategory: parsedData.attributes.change_request.data.attributes.category,
        changedGroup: parsedData.attributes.change_request.data.attributes.group.id || '',
        selectedGroup: parsedData.attributes.change_request.data.attributes.group.name || '', 
        changedAgent: parsedData.attributes.change_request.data.attributes.agent.id || '',
        changedDepartment: parsedData.attributes.change_request.data.attributes.department.id || '',
        departmentValue: {
          id: parsedData.attributes.change_request.data.attributes.department?.id ? String(parsedData.attributes.change_request.data.attributes.department.id) : '',
          type: parsedData.attributes.change_request.data.attributes.department?.name || '',
          attributes: {
            department_name: parsedData.attributes.change_request.data.attributes.department?.name || ''
          }
        },
        agentValue: {
          id: parsedData.attributes.change_request.data.attributes.agent?.id ? String(parsedData.attributes.change_request.data.attributes.agent.id) : '',
          type: parsedData.attributes.change_request.data.attributes.agent.name || '',
          attributes: {
            full_name: parsedData.attributes.change_request.data.attributes.agent?.name || ''
          }
        }
        ,fileNames: parsedData.attributes.change_request_template_attachments&& parsedData.attributes.change_request_template_attachments.length > 0?
                 parsedData.attributes.change_request_template_attachments.map((attachment: {id: string,url: string}) => {
                    const urlParts = attachment.url.split('/');
                    return urlParts[urlParts.length - 1];
                  }): this.state.fileNames,
                  fileData: parsedData.attributes.change_request_template_attachments&& parsedData.attributes.change_request_template_attachments.length > 0? parsedData.attributes.change_request_template_attachments.map((attachment: {id: string,url: string}) => {
                    return attachment
                  }): this.state.fileData   ,
        editedGroup: {
          id: parsedData.attributes.change_request.data.attributes.group?.id ? String(parsedData.attributes.change_request.data.attributes.group.id) : '',
          type: parsedData.attributes.change_request.data.attributes.group?.name || '',
          attributes: {
            name: parsedData.attributes.change_request.data.attributes.group?.name || '',
            category: {
              data: {
                id: Number(parsedData.attributes.change_request.data.attributes.category)
              }
            }
          }
        },
        template_id: parsedData.id,
        ticket_id: parsedData.attributes.change_request.data.id,
        selectedCategoryValue:parsedData.attributes.change_request.data.attributes.category?  await this.getCategory(parsedData.attributes.change_request.data.attributes.category): this.state.selectedCategoryValue,
        selectedAssets:parsedData.attributes.change_request.data.attributes.associate_assets.data && parsedData.attributes.change_request.data.attributes.associate_assets.data.length >0 ? this.mapAssets(parsedData.attributes.change_request.data.attributes.associate_assets.data) : this.state.selectedAssets,
      });
      if(parsedData.attributes.available_for === 'All agents'){
        this.setState({disableDropdowns:true})
      }
    }
  }
  mapAssets = (data: Asset[])=>{
    const newData = data.map((item) => ({
       id: item.id,
       name: item.attributes.display_name,
       type: item.attributes.asset_type,
       used_by: item.attributes.used_by?.name || '',
       department: item.attributes.department?.name || '',
       location: item.attributes.location || '',
       serial_no: item.attributes.serial_no || '',
       state: item.attributes.impact || '',
     }));
     if(newData.length >0) this.setState({showAssetChip: true})
     return newData
}
  getCategory = async (id: string): Promise<CategoryList> => {
    return new Promise((resolve) => {
      setTimeout(() => {
        const category = this.state.filteredCategories.filter(agents =>
          agents.id === id
        );
        resolve(category.length > 0 ? category[0] : { id: '7', type: '', attributes: { name: '' } });
      }, 2000);
    });
  }
  handleSubjectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const subjectValue = event.target.value;
    const subjectRegex = /^[a-zA-Z][a-zA-Z0-9\s]*$/;
    const isValidChars = subjectRegex.test(subjectValue);
    const isValidLength = subjectValue.length <= 50;
    const isValid = (isValidChars && isValidLength) || subjectValue === '';
  
    let subjectError = '';
    if (!isValidChars) {
      subjectError = 'Subject must start with letters and can only contain letters and numbers.';
    } else if (!isValidLength) {
      subjectError = 'Subject must be less than 50 characters.';
    }
  
    this.setState({
      subject: subjectValue,
      subjectError: isValid ? '' : subjectError,
    });
  }

  handleChangeType = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ selectedType: event.target.value as string,typeError:'' });
  };
  handleRiskChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ selectedRisk: event.target.value as string,riskError:'' });
  };

  handleStatusChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ selectedStatus: event.target.value as string });
  }

  handlePriorityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ selectedPriority: event.target.value });
  };

  handleGroupSelectionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event?.target?.value;
    const filteredOptions = this.state.allGroupList.filter(group =>
      group.attributes?.name?.toLowerCase().includes(inputValue?.toLowerCase())
    );
    this.setState({ filteredOptions });
  };


  handleAgentsSelectionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event?.target?.value;
    const filteredAgents = this.state.allAgentsList.filter(agents =>
      agents.attributes?.full_name?.toLowerCase().includes(inputValue?.toLowerCase())
    );
    this.setState({ filteredAgents });
  }

  handleDepartmentSelectionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event?.target?.value;
    const filteredDepartmentList = this.state.allDepartmentList.filter(department =>
      department.attributes?.department_name?.toLowerCase().includes(inputValue?.toLowerCase())
    );
    this.setState({ filteredDepartmentList });
  }

  handleStartDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedDate = event.target.value;
    const { endDate } = this.state;
    if (endDate && selectedDate > endDate) {
      this.setState({ startDate: selectedDate, startDateError: configJSON.startDateErrorValidation });
    } else {
      this.setState({ startDate: selectedDate, endDateError: '', startDateError: '', startTimeError: '', endTimeError: '' }, () => {
        this.calculateTimeDifference();
      });
    }
  };

  handleStartTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedTime = event.target.value;
    if (!selectedTime) {
      this.setState({ startTime: selectedTime, startTimeError: configJSON.startTimeError });
    } else {
      this.setState({ startTime: selectedTime, endDateError: '', startDateError: '', startTimeError: '', endTimeError: '' }, () => {
        this.calculateTimeDifference();
      });
    }
  };

  handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedDate = event.target.value;

    if (selectedDate < this.state.startDate) {
      this.setState({ endDate: selectedDate, endDateError: configJSON.endDateError });
    } else {
      this.setState({ endDate: selectedDate, endDateError: '', startDateError: '', startTimeError: '', endTimeError: '' }, () => {
        this.calculateTimeDifference()
      });
    }

  };

  handleEndTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedEndTime = event.target.value;
    const { startDate, endDate, startTime } = this.state;
    if (!selectedEndTime) {
      this.setState({ endTime: selectedEndTime, endTimeError: configJSON.endTimeError });
    } else {
      let endTimeError = '';
      if (startDate === endDate) {
        if (selectedEndTime < startTime)
          endTimeError = 'End time cannot be earlier than start time on the same date.';
        else if (selectedEndTime === startTime) {
          endTimeError = "The end time must be different from the start time on the same date."
        }
      }

      this.setState({ endTime: selectedEndTime, endDateError: '', startDateError: '', startTimeError: '', endTimeError }, () => {
        if (!endTimeError) {
          this.calculateTimeDifference();
        }
      });
    }
  };

  calculateTimeDifference = () => {
    const { startDate, startTime, endDate, endTime } = this.state;
    if (endDate && endTime && startDate && startTime) {
      const startDateTime = new Date(startDate + "T" + startTime);
      const endDateTime = new Date(endDate + "T" + endTime);
      const difference = endDateTime.getTime() - startDateTime.getTime();

      const hours_Difference = Math.floor
      (difference / 1000 / 60 / 60) 
      ?? 0;
      const minutes_Difference = Math.floor
      ((difference / 1000 / 60) % 60) 
      ?? 0;

      this.setState({
        timeDifference: `${hours_Difference} hours ${minutes_Difference} minutes`,
      });
    }
  };

  handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    this.setState(prevState => ({
      fileData: [...(prevState.fileData || []), file].filter(Boolean) as FileData[],
      fileNames: [...(prevState.fileNames || []), file ? file.name : '']
    }));
  }
  removeAsset = (index: number) => {
    this.setState(prevState => {
      const assets = [...(prevState.selectedAssets || [])];
      assets.splice(index, 1);
      return { selectedAssets: assets };
    });
  }

  handleCategoryChange = (value: CategoryList | null) => {
    if (value) {
      const selectedCategory = value.id.toString();
      const associatedGroup = this.state.allGroupList.find(
        (group) => group.attributes.category.data !== null && String(group.attributes.category.data.id) === selectedCategory
      ) || { id: '', type: '', attributes: { name: '', category: { data: { id: null } } } };
      this.setState({ selectedCategoryValue: value, selectedCategory, editedGroup: associatedGroup, changedGroup: associatedGroup.id });
    } else {
      this.setState({ filteredCategories: this.state.categoryList, selectedCategoryValue: { id: '', type: '', attributes: { name: '' } }, selectedCategory: '', editedGroup: { id: '', type: '', attributes: { name: '', category: { data: { id: null } } } }, changedGroup: '' });
    }
  };
  handleCategoryListResponse = (responseJson: { data: CategoryList[] }) => {
    if (responseJson.data)
      this.setState({ categoryList: responseJson.data, filteredCategories: responseJson.data })
  }
  removeFile = (index: number) => {
    this.setState(prevState => {
      const fileData = [...(prevState.fileData || [])];
      const fileNames = [...prevState.fileNames];
      fileData.splice(index, 1);
      fileNames.splice(index, 1);
      return { fileData, fileNames };
    });
  }
  handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const titleValue = event.target.value;
    const minLength = 5;
    const maxLength = 50;

    const titleRegex = /^[a-zA-Z][a-zA-Z0-9\s]*$/;

    const isValidLength = titleValue.length >= minLength && titleValue.length <= maxLength;
    const isValidChars = titleRegex.test(titleValue);
    const isValid = isValidLength && isValidChars || titleValue === '';

    this.setState({
      title: titleValue,
      titleError: isValid
        ? ''
        : titleValue.length < minLength
          ? `Title must be at least ${minLength} characters.`
          : titleValue.length > maxLength
            ? `Title cannot exceed ${maxLength} characters.`
            : !isValidChars
              ? 'Title must start with a letter and can only contain letters and numbers'
              : '',
    });
  }

  handleAvailableForChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(event.target.value === 'All agents'){
      this.setState({
        changedAgent: "",
        changedGroup: "",
        selectedCategory: "",
        disableDropdowns: true,
        availableFor: event.target.value,
        selectedCategoryValue: {
          id: '',
          type: '',
          attributes: {
            name: ''
          }
        },
        editedGroup: {
          id: '',
          type: '',
          attributes: {
            name: '',
            category: {
              data: {
                id: null
              }
            }
          }
        },
        agentValue: {
          id: '',
          attributes: {
            full_name: ''
          },
          type: ''
        }
      }); 
    }else{
      this.setState({ availableFor: event.target.value,disableDropdowns: false });
    }
   
  }

  handleCancel = () => {
    this.props.navigation.navigate("FormTemplates")
  }
  handleChangeDepartment = (value: DepartmentList | null) => {
    if (value)
      this.setState({ departmentValue: value })
    else this.setState({ departmentValue: { id: "", type: '', attributes: { department_name: '' } } });
    this.setState({ changedDepartment: value ? value.id : '', });
  }

  handleChangeAgents = (value: AllAgentsList | null) => {
    if (value) {
      this.setState({ agentValue: value })
    } else this.setState({ agentValue: { id: "", type: '', attributes: { full_name: '' } } });
    this.setState({ changedAgent: value ? value.id : '' })
  }
  handleCategorySelectionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event?.target?.value;
    const filteredCategories = this.state.categoryList.filter(agents =>
      agents.attributes?.name?.toLowerCase().includes(inputValue?.toLowerCase())
    );
    this.setState({ filteredCategories });
  }
  handleShowAssetModal = () => {
    this.setState({ showAssetModal: true })
  }
  handleCloseAssetModal = () => {
    this.setState({ showAssetModal: false })
  }

  onFormSubmiit = () => {
    const { title, description, selectedStatus, selectedType, selectedRisk } = this.state;
    let isError = false;
    if (!title.trim()) {
      this.setState({ titleError: configJSON.titleReq });
      isError = true;
    } else if (title.length < 5) {
      this.setState({ titleError: configJSON.titleCharcter });
      isError = true;
    } else {
      this.setState({ titleError: '' });
    }
    if (!selectedStatus.trim()) {
      this.setState({ statusError: configJSON.statusReq });
      isError = true;
    } else {
      this.setState({ statusError: '' });
    }

    if (!selectedType.trim()) {
      this.setState({ typeError: configJSON.typeReq });
      isError = true;
    } else {
      this.setState({ typeError: '' });
    }
    if (!selectedRisk.trim()) {
      this.setState({ riskError: configJSON.riskReq });
      isError = true;
    } else {
      this.setState({ riskError: '' });
    }

    // Validate description
    if (!description.trim()) {
      this.setState({ descriptionError: configJSON.descriptionRequired });
      isError = true;
    } else if (description.length < 10) {
      this.setState({ descriptionError: configJSON.descriptionCharcter });
      isError = true;
    } else {
      this.setState({ descriptionError: '' });
    }

    if (isError) {
      this.setState({ isValidationError: true });
      return;
    }

    this.setState({ isValidationError: false });

    // If no validation errors, proceed with the form submission
    this.createTicket();
  };
  
  createTicket = () => {
    const header = {token: this.token};
    const startDateString = this.state.startDate;
    const startTimeString = this.state.startTime;
    const endDateString = this.state.endDate;
    const endTimeString = this.state.endTime;
    let apiData = new FormData();
    apiData.append("change_request_template[available_for]", this.state.availableFor)
    apiData.append("change_request_template[description]", this.state.generalDescription.trim());
    apiData.append("change_request_template[change_requests_attributes][0][description]", this.state.description)
    apiData.append("change_request_template[change_requests_attributes][0][priority]", this.state.selectedPriority);
    apiData.append("change_request_template[change_requests_attributes][0][planned_effort]", this.state.timeDifference);
    apiData.append(`change_request_template[change_requests_attributes][0][group_id]`, this.state.changedGroup);
    apiData.append("change_request_template[change_requests_attributes][0][subject]", this.state.subject.trim());
    apiData.append("change_request_template[title]", this.state.title.trim())
    apiData.append("change_request_template[change_requests_attributes][0][tags]", 
      this.state.selectedTags.join(','));
    apiData.append("change_request_template[change_requests_attributes][0][risk]",this.state.selectedRisk)
    if (this.state.selectedAssets.length > 0) {
      for (let i = 0; i < this.state.selectedAssets.length; i++) {
        apiData.append("change_request_template[change_requests_attributes][0][associate_assets][]",
          (this.state.selectedAssets[i].id))
      }
    }
    apiData.append(`change_request_template[change_requests_attributes][0][department_id]`, this.state.changedDepartment);
    apiData.append("change_request_template[change_requests_attributes][0][category]", this.state.selectedCategory);
    apiData.append(`change_request_template[change_requests_attributes][0][agent_id]`, this.state.changedAgent);
    apiData.append("change_request_template[change_requests_attributes][0][change_type]",this.state.selectedType.trim())
    apiData.append('change_request_template[change_requests_attributes][0][risk]',this.state.selectedRisk)
    
    if (this.state.fileData && this.state.fileData.length > 0) {
      for (let i = 0; i < this.state.fileData.length; i++) {
        apiData.append("change_request_template[change_request_template_attachments][]", this.state.fileData[i] as unknown as Blob);
      }
    }
    if (endDateString && endTimeString) {
      const endDateTimeString = `${endDateString}T${endTimeString}`;
      const endDateTime = new Date(endDateTimeString);
      const endDateTimeISOString = endDateTime.toISOString();
      apiData.append("change_request_template[change_requests_attributes][0][planned_enddate]", endDateTimeISOString);
    }
    if (startDateString && startTimeString) {
      const startDateTimeString = `${startDateString}T${startTimeString}`;
      const startDateTime = new Date(startDateTimeString);
      const startDateTimeISOString = startDateTime.toISOString();
      apiData.append("change_request_template[change_requests_attributes][0][planned_startdate]", startDateTimeISOString);
    }
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.submitFormAPICallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.changeTemplateApiEndpoint
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.postApiMethod
  );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
      apiData
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  onUpdateForm = () => {
    const { title, description, selectedStatus, selectedType, selectedRisk } = this.state;
    let isError = false;
    const validations = [
      { field: title, minLength: 5, errorField: 'titleError', requiredMessage: configJSON.titleReq, lengthMessage: configJSON.titleCharcter },
      { field: selectedStatus, minLength: 1, errorField: 'statusError', requiredMessage: configJSON.statusReq },
      { field: selectedType, minLength: 1, errorField: 'typeError', requiredMessage: configJSON.typeReq },
      { field: selectedRisk, minLength: 1, errorField: 'riskError', requiredMessage: configJSON.riskReq },
      { field: description, minLength: 10, errorField: 'descriptionError', requiredMessage: configJSON.descriptionRequired, lengthMessage: configJSON.descriptionCharcter },
    ];
  
    validations.forEach(({ field, minLength, errorField, requiredMessage, lengthMessage }) => {
      if (!field.trim()) {
        this.setState({ [errorField]: requiredMessage } as Pick<S, keyof S>);
        isError = true;
      } else if (field.length < minLength) {
        this.setState({ [errorField]: lengthMessage } as Pick<S, keyof S>);
        isError = true;
      } else {
        this.setState({ [errorField]: '' } as unknown as Pick<S, keyof S>);
      }
    });
  
    if (isError) {
      this.setState({ isValidationError: true });
      return;
    }
  
    this.setState({ isValidationError: false });
    this.updateTicket();
  };
  updateTicket = () => {
    const header = {
      token: this.token
    };

    let formdata = new FormData();
    formdata.append("change_request_template[title]", this.state.title.trim())
    formdata.append("change_request_template[available_for]", this.state.availableFor)
    formdata.append("change_request_template[description]", this.state.generalDescription.trim());
    formdata.append("change_request_template[change_requests_attributes][0][subject]", this.state.subject.trim());
    formdata.append("change_request_template[change_requests_attributes][0][description]", this.state.description)
    formdata.append("change_request_template[change_requests_attributes][0][priority]", this.state.selectedPriority);
    formdata.append("change_request_template[change_requests_attributes][0][planned_effort]", this.state.timeDifference);
    formdata.append(`change_request_template[change_requests_attributes][0][group_id]`, this.state.changedGroup);
    formdata.append(`change_request_template[change_requests_attributes][0][agent_id]`, this.state.changedAgent);
    formdata.append(`change_request_template[change_requests_attributes][0][department_id]`, this.state.changedDepartment);
    formdata.append("change_request_template[change_requests_attributes][0][category]", this.state.selectedCategory);
    formdata.append("change_request_template[change_requests_attributes][0][change_type]",this.state.selectedType.trim())
    formdata.append("change_request_template[change_requests_attributes][0][risk]",this.state.selectedRisk)
    formdata.append("change_request_template[change_requests_attributes][0][id]", this.state.ticket_id);
    formdata.append("change_request_template[change_requests_attributes][0][status]",this.state.selectedStatus)
    formdata.append("change_request_template[change_requests_attributes][0][tags]", this.state.selectedTags.join(','));

    if (this.state.selectedAssets.length > 0) {
      for (const asset of this.state.selectedAssets) {
        formdata.append("change_request_template[change_requests_attributes][0][associate_assets][]", asset.id);
      }
    }

    if (this.state.fileData && this.state.fileData.length > 0) {
      for (const file of this.state.fileData) {
        formdata.append("change_request_template[change_request_template_attachments][]", file as unknown as Blob);
      }
    }

    const startDateString = this.state.startDate;
    const startTimeString = this.state.startTime;
    if (startDateString && startTimeString) {
      const startDateTimeString = `${startDateString}T${startTimeString}`;
      const startDateTime = new Date(startDateTimeString);
      const startDateTimeISOString = startDateTime.toISOString();
      formdata.append("change_request_template[change_requests_attributes][0][planned_startdate]", startDateTimeISOString);
    }

    const endDateString = this.state.endDate;
    const endTimeString = this.state.endTime;
    if (endDateString && endTimeString) {
      const endDateTimeString = `${endDateString}T${endTimeString}`;
      const endDateTime = new Date(endDateTimeString);
      const endDateTimeISOString = endDateTime.toISOString();
      formdata.append("change_request_template[change_requests_attributes][0][planned_enddate]", endDateTimeISOString);
    }
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.updateFormApiId = requestMessage.messageId;

    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.updateChangeTemplateApiEndpoint + this.state.template_id
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
        formdata
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.patchApiMethod
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleInputChange = (event: { target: { value: string } }) => {
    this.setState({ inputValue: event.target.value });
  };

  handleAddTag = (tags: string) => {
    if (tags && !this.state.selectedTags.includes(tags)) {
      this.setState({
        inputValue: '',
        selectedTags: [...this.state.selectedTags, tags]
      })
    }
  };
  handleUpdateTicketResponse = (responseJson: { data: TicketTemplate }) => {
    if (responseJson.data) {
        toast.success(configJSON.successTicketTemplateUpdateMessage)
        this.setState({
            isValidationError: false,
            isLoading:true
        })
        setTimeout(() => {
            this.props.navigation.navigate("FormTemplates") 
        }, 3000);
    }else{
        toast.error(configJSON.errorTicketTemplateUpdate    )
    }
}
  handleDeleteTag = (tagToDelete: string) => () => {
    this.setState((tags) => {
      return {
        selectedTags: tags.selectedTags.filter((tags: string) => tags !== tagToDelete)
      }
    });
  };
  handleSearchAssets = (event: React.ChangeEvent<HTMLInputElement>) => {
    const filteredAssets = this.state.association_assests.filter(asset =>
      asset.name.toLowerCase().includes(event.target.value.toLowerCase())
    );
    this.setState({ filtered_assets: filteredAssets })
  }
  handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = this.state.association_assests;
      this.setState({ selectedAssets: newSelecteds });
      return;
    }
    this.setState({ selectedAssets: [] });
  };

  handleClick = (event: React.MouseEvent<HTMLElement>, name: string) => {
    const selectedIndex = this.state.selectedAssets.findIndex((item) => item.id === name);
    let newSelected: AssociateAssets[] = [];

    if (selectedIndex === -1) {
        const selectedItem = this.state.association_assests.find((item) => item.id === name);
        if (selectedItem) {
            newSelected = newSelected.concat(this.state.selectedAssets, selectedItem);
        }
    } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(this.state.selectedAssets.slice(1));
    } else if (selectedIndex === this.state.selectedAssets.length - 1) {
        newSelected = newSelected.concat(this.state.selectedAssets.slice(0, -1));
    } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
            this.state.selectedAssets.slice(0, selectedIndex),
            this.state.selectedAssets.slice(selectedIndex + 1)
        );
    }

    this.setState({ selectedAssets: newSelected });
};
  handleAssetsresponse = (response: { data: Asset[] }) => {
    if (response.data) {
      const association_assests: AssociateAssets[] = response.data.map(asset => ({
        id: asset.id,
        name: asset.attributes.display_name,
        type: asset.attributes.asset_type,
        used_by: asset.attributes.used_by.name || '',
        department: asset.attributes.department.name || '',
        location: asset.attributes.location || '',
        serial_no: asset.attributes.serial_no || '',
        state: asset.attributes.impact || '',
      }));

      this.setState({ association_assests: association_assests, filtered_assets: association_assests });
    }
  }
  handleSelectedAssets = () => {
    this.setState({ showAssetModal: false, showAssetChip: true })
  }
  isSelected = (name: string): boolean => this.state.selectedAssets.some((asset) => asset.id === name);
  handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === configJSON.textEnter) {
      this.handleAddTag(this.state.inputValue);
    }
  };
  stripHtmlTags = (html: string): string => {
    const div = document.createElement('div');
    div.innerHTML = html;
    return div.textContent || div.innerText || '';
  };
  handleChange = (value: string) => {
    const descriptionValue = value || ''
    const plainTextDescription = this.stripHtmlTags(descriptionValue);
    const isDescriptionValid = plainTextDescription.length >= 10;
    const isDescriptionEmpty = descriptionValue?.trim() === '';
    let descriptionError = '';

    if (isDescriptionEmpty) {
      descriptionError = configJSON.descriptionRequired;
    } else if (!isDescriptionValid) {
      descriptionError = configJSON.descriptionCharcter;
    }

    this.setState({
      description: descriptionValue,
      descriptionError: descriptionError
    });
  };
  handleChangeGroup = (value: AllGroupsList | null) => {
    if (value) {
      const associatedCategory = value.attributes.category.data !== null && value.attributes.category.data.id !== null ? value.attributes.category.data.id.toString() : '';
      let data: CategoryList[] = [];
      if (value.attributes.category.data && value.attributes.category.data.id !== null) {
        data = this.state.categoryList.filter((item) => item?.id === String(value.attributes.category.data.id));
      }
      this.setState({
        editedGroup: value,
        changedGroup: value.id,
        selectedCategory: associatedCategory,
        selectedCategoryValue: data.length > 0 ? data[0] : { id: '', attributes: { name: '' }, type: '' },
        allAgentsList: value.attributes.agents.length > 0 ? value.attributes.agents.map((item)=> {
          return {
              id: String(item.id),
              type: item.agent_type,
              attributes: {
                  full_name: item.full_name
              }
          }
      }): [],
      filteredAgents: value.attributes.agents.length > 0 ? value.attributes.agents.map((item)=> {
          return {
              id: String(item.id),
              type: item.agent_type,
              attributes: {
                  full_name: item.full_name
              }
          }
      }): []
      });
    } else {
      this.setState({
        editedGroup: { id: "", type: '', attributes: { name: '', category: { data: { id: null } } } },
        changedGroup: '',
        selectedCategory: '',
        selectedCategoryValue: { id: '', attributes: { name: '' }, type: '' },
        allAgentsList:[],
        filteredAgents:[]
      });
    }
  }
  handleGeneralDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const descValue = event.target.value;
    const descRegex = /^[a-zA-Z0-9][\w\s@$!%*?&()-+=[\]{}|;:'",.<>\/]*$/;
    const isValidChars = descRegex.test(descValue);
    const isValid = isValidChars || descValue === '';

    this.setState({
      generalDescription: descValue,
      generalDescriptionError: isValid
        ? ''
        : !isValidChars
          ? 'Description must start with a letter or numbers'
          : '',
    });
  }

  // Customizable Area End
}
