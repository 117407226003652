import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData } from "../../../framework/src/Utilities";
import toast from "react-hot-toast";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  txtInputnameValue:string;
  prioritycheckValue:string;
  firstresponseValue:string;
  resolutiontimeValue:string;
  description:string;
  descriptionError: string
  showToast:boolean;
  showToastMessage:string,
  toastSeverity:string,
  policyType: string,
  anchorEl:any | string,
  createAnchorEle: HTMLElement | null | undefined,
  isMenuOpenPolicy:boolean,
  selectedpolicyValue:string,
  selectedValue:string,
  showDropdown: boolean
  name: string,
  nameError: string
  selectedFirstResponse: string
  isValidationError: boolean
  isLoading: boolean
  priorityError: string
  firstResponseError: string
  resolutiontimeError: string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CfslamanagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  slapolicyApiCallId:string = ""
  timeOptions = [
    { value: 0.25, label: "15 Min" },
    { value: 0.5, label: "30 Min" },
    { value: 0.75, label: "45 Min" },
    { value: 1, label: "1 Hr" },
    { value: 2, label: "2 Hr" },
    { value: 3, label: "3 Hr" },
    { value: 5, label: "5 Hr" },
    { value: 8, label: "8 Hr" },
    { value: 12, label: "12 Hr" },
    { value: 16, label: "16 Hr" },
    { value: 20, label: "20 Hr" },
    { value: 24, label: "24 Hr" },
    { value: 36, label: "1.5 Day" },
    { value: 48, label: "2 Day" },
    { value: 72, label: "3 Day" },
    { value: 96, label: "4 Day" },
  ];
  typeList = [
    'Ticket','Task','Change','Problem'
  ]
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      txtInputnameValue:"",
      showDropdown: false,
      prioritycheckValue:"",
      firstresponseValue:"",
      resolutiontimeValue:"",
      description:"",
      descriptionError:"",
      policyType: '',
      showToast:false,
      isLoading: false,
      isValidationError: true,
      showToastMessage:"",
      toastSeverity:"",
      anchorEl:null ,
      name: '',
      nameError:'',
      isMenuOpenPolicy:false,
      selectedpolicyValue:"",
      selectedValue:"",
      createAnchorEle: null,
      selectedFirstResponse: '',
      resolutiontimeError: '',
      firstResponseError:'',
      priorityError:''
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handleTokenExpiryAndInvalidTokenFunction(responseJson);
      if (apiRequestCallId && responseJson) {
        this.setState({isLoading:false})
        if (apiRequestCallId === this.slapolicyApiCallId) {
          if (responseJson.data) {
            toast.success('Policy created successfully')
            this.handleCancel()
          } else {
            toast.success('Error while creating the policy')
          }
        }
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount() {
    this.getPolicyType()
  }
  getPolicyType= ()=>{
    let type = sessionStorage.getItem('policyType')
    if(type){
      this.setState({policyType:type})
    }
  }
  handleTokenExpiryAndInvalidTokenFunction(responseJson: any) {
    if (responseJson
      &&
      Array.isArray(responseJson.errors)
      &&
      responseJson.errors.length > 0 &&
      (responseJson.errors[0].token === "Token has Expired"
        || responseJson.errors[0].token === 'Invalid token')) {
      this.goToLoginFunction();
    }
  }
  goToLoginFunction() {
    const msg: Message = new Message(getName(MessageEnum.NavigationEmailLogInMessage));
    msg.addData(getName(MessageEnum.NavigationPropsMessage),
     this.props);
    this.send(msg);
  }
  handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const titleValue = event.target.value;
    const minLength = 4;
    const maxLength = 50;

    const titleRegex = /^[a-zA-Z][a-zA-Z0-9\s]*$/;

    const isValidLength = titleValue.length >= minLength && titleValue.length <= maxLength;
    const isValidChars = titleRegex.test(titleValue);
    const isValid = isValidLength && isValidChars || titleValue === '';

    this.setState({
      name: titleValue,
      nameError: isValid
        ? ''
        : titleValue.length < minLength
          ? `Name must be at least ${minLength} characters.`
          : titleValue.length > maxLength
            ? `Name cannot exceed ${maxLength} characters.`
            : !isValidChars
              ? 'Name must start with a letter and can only contain letters and numbers'
              : '',
    });
  }
  handleFirstResponseChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const sourceValue = event.target.value as string;
    this.setState({
      selectedFirstResponse: sourceValue,firstResponseError:''
    });
  }
  handleResoltionTimeChange= (event: React.ChangeEvent<{ value: unknown }>) => {
    const sourceValue = event.target.value as string;
    this.setState({
      resolutiontimeValue: sourceValue,resolutiontimeError:''
    });
  }
  stripHtmlTags = (html: string): string => {
    const div = document.createElement('div');
    div.innerHTML = html;
    return div.textContent || div.innerText || '';
  };
     handleChange = (value: string) => {
          const descriptionValue = value || ''
          const plainTextDescription = this.stripHtmlTags(descriptionValue);
          const isDescriptionValid = plainTextDescription.length >= 10;
          const isDescriptionEmpty = descriptionValue?.trim() === '';
          let descriptionError = '';
  
          if (isDescriptionEmpty) {
              descriptionError = "Description is required";
          } else if (!isDescriptionValid) {
              descriptionError = "Description cannot be less than 10 characters";
          }
  
          this.setState({
              description: descriptionValue,
              descriptionError: descriptionError
          });
        };
  handleSubmit = async () => {
    const { name, description,prioritycheckValue,selectedFirstResponse,resolutiontimeValue } = this.state;
    let isError = false;

    if (!name.trim()) {
      this.setState({ nameError: "Name is required" });
      isError = true;
    } else if (name.length < 4) {
      this.setState({ nameError: "Name cannot be less than 4 characters" });
      isError = true;
    } else {
      this.setState({ nameError: '' });
    }

    if (!description.trim()) {
      this.setState({ descriptionError: "Description is required" });
      isError = true;
    } else if (description.length < 10) {
      this.setState({ descriptionError: "Description cannot be less than 5 characters" });
      isError = true;
    } else {
      this.setState({ descriptionError: '' });
    }
    if(!prioritycheckValue){
      isError= true
      this.setState({priorityError:"Priority is required"})
    }else{
      this.setState({priorityError:""})
    }

    if(!selectedFirstResponse){
      isError=true
      this.setState({firstResponseError: "First response is required"})
    }else this.setState({firstResponseError: ""})

    if(!resolutiontimeValue){
      isError= true
      this.setState({resolutiontimeError:"Resolution time is required"})
    }else this.setState({resolutiontimeError:""})
    if (isError) {
      this.setState({ isValidationError: true });
      return;
    }

    this.setState({ isValidationError: false });
    this.createTicket()
  };
  createTicket = async ()=>{
    this.setState({isLoading: true})
    const token1 = await getStorageData("authToken");
    const { name, description,prioritycheckValue,selectedFirstResponse,resolutiontimeValue ,policyType} = this.state;
    const header1 = {
      "Content-Type": configJSON.subscriptionApiContentType,
      'token': token1
    };
    const requestMessage1 = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const raw1 = {
      sla_and_ola_policy: {
        name: name,
        description: description,
        priority:prioritycheckValue,
        first_response: selectedFirstResponse.toString(),
        resolution_time: resolutiontimeValue.toString(),
        ticket_type: policyType.toLowerCase()
      }
    };

    this.slapolicyApiCallId = requestMessage1.messageId;

    requestMessage1.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    requestMessage1.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.slapolicyAPiEndPoint
    );
    requestMessage1.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header1)
    );
    requestMessage1.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),

      JSON.stringify(raw1)
    );

    runEngine.sendMessage(requestMessage1.id, requestMessage1);
  }
  handlePriorityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ prioritycheckValue: event.target.value,priorityError:'' });
};
handleCancel = ()=>{
  this.props.navigation.navigate("Cfslamanagementlist")
}
  // Customizable Area End
}
