import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import * as Yup from 'yup';
import toast from "react-hot-toast";

export const configJSON = require("./config");
export interface Agents {
  id: string;
  type: string;
  attributes: AgentAttributes;
}

interface AgentAttributes {
  [key: string]: string | boolean | null;
}
interface InitialValues {
  name: string;
  description: string;
}

interface CabData {
  id: string;
  type: string;
  attributes: {
    name: string;
    description: string;
    agents: CabAgent[];
  };
}

interface CabAgent {
  id: number;
  full_name: string;
  email: string;
}
export interface NavigationItem {
  title: string;
  list?: NavigationItem[];
  href?: string;
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  id: string;
  // Customizable Area Start
  isLoading: boolean;
  txtInputValue: string;
  openListIndex: number;
  showModal: boolean;
  agents: Agents[];
  selectedMembers: Agents[]
  isEditMode: boolean;
  initialValues: InitialValues;
  cabData: CabData;
  // Customizable Area End
}

export interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class CabDetailsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getAllAgentsApiCallId: string = ''
  getCabDetailsApiCallId: string = ''
  updateCabDetailsApiCallId: string = ''
  updateMembersApiCallId: string = ''
  navigationList: NavigationItem[] = [
    { title: 'Account settings', list: [] },
    {
      title: 'User management',
      list: [
        { title: 'Agents',href: '/user-management/agents'},
        { title: 'Roles', href: '/user-management/roles'},
        { title: 'Departments', href: '/user-management/departments'},
        { title: 'Department Fields', href: '/user-management/department-fields',},
        { title: 'Requesters', href: '/user-management/requesters'},
        { title: 'User Fields', href: '/user-management/user-fields'},
        { title: 'CAB', href: '/user-management/cab'},
        { title: 'Agent groups', href: '/user-management/agent-groups'},
      ],},
    { title: 'Channels', list: [], },
    { title: 'Service management', list: [], },
    { title: 'Automation and productivity', list: [] },
    { title: 'Asset management', list: [] },
    { title: 'IT operations management', list: [], },
    { title: 'Project and workload management', list: [] },
  ];
  token: string = ''
  id: string = ''
  pathname = "/user-management/cab";
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      id: "",
      isLoading: false,
      txtInputValue: '',
      openListIndex: -1,
      showModal: false,
      agents: [],
      selectedMembers: [this.createEmptyAgent()],
      isEditMode: false,
      initialValues: { description: '', name: '' },
      cabData: {
        id: '',
        type: 'cab',
        attributes: {
          name: '',
          description: '',
          agents: []
        }
      }
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  receive = async (from: string, message: Message) => {
    runEngine.debugLog("Message Received", message);
    // Customizable Area Start
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const apiRequestId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    )
    this.setState({
      isLoading: false
    })

    switch (apiRequestId) {
      case this.getAllAgentsApiCallId:
        this.handleAgentsApiDataResponse(responseJson)
        break;
      case this.getCabDetailsApiCallId:
        this.handlegetCabDetailsResponse(responseJson)
        break;
      case this.updateCabDetailsApiCallId:
        this.handleUpdateCabDetailsResponse(responseJson)
        break;
      case this.updateMembersApiCallId:
        this.handleUpdateMembersResponse(responseJson)
        break;
      default:
        break;
    }

    // Customizable Area End
  };
  // Customizable Area Start
  async componentDidMount() {
    this.token = await getStorageData('authToken')
    this.id = this.props.navigation.getParam("navigationBarTitleText")

    this.getAllAgents()
    this.getCabDetailsById()
  }

  getCabDetailsById = () => {
    this.setState({ isLoading: true });
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.token
    };
    let requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCabApiEndPoint + this.id
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getCabDetailsApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getAllAgents() {
    this.setState({ isLoading: true });
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.token
    };
    let requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllAgentsApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getAllAgentsApiCallId = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  setInputValue = (text: string) => this.setState({ txtInputValue: text });

  handleModalOpen(value: boolean) {
    this.setState({
      showModal: value,
    })

    if (this.state.isEditMode && !value) {
      this.setState({ isEditMode: false })
    }
  }

  returnModalTitle = () => {
    return this.state.isEditMode ? configJSON.textEditDetails : configJSON.textAddMembers
  }

  checkValue = (value: string) => !value || value.trim().length > 0

  validationSchema = Yup.object({
    name: Yup.string().trim().required('This field is required').min(4, 'Please enter at least 4 characters'),
    description: Yup.string().test('not-only-whitespace', 'This field cannot be only whitespace', this.checkValue)
  });


  returnSubmitBtnText = () => {
    return this.state.isEditMode ? configJSON.textSaveChanges : configJSON.addNewCabLabel
  }

  handleSubmit = (value: InitialValues) => {
    if (this.state.cabData.id) {

      this.setState({ isLoading: true });

      const header = {
        "Content-Type": configJSON.apiContentType,
        token: this.token
      };

      const httpBody = {
        cab: {
          ...value
        }
      }

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.updateCabDetailsApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.updateCabDetailsApiEndPoint + this.state.cabData.id
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.putApiMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }

  }

  handleSelectMember = (index: number, id: string) => {
    const selectedMember = this.state.agents.find(member => member.id === id);
    if (selectedMember) {
      let newSelectedMembers = [...this.state.selectedMembers];
      newSelectedMembers[index] = selectedMember;
      this.setState({
        selectedMembers: newSelectedMembers
      })
    }
  };

  isMemberSelected = (memberId: string) => {
    return this.state.selectedMembers?.some(member => member.id === memberId);
  };

  handleDeleteMember = (index: number) => {
    let newSelectedMembers = [...this.state.selectedMembers];
    newSelectedMembers.splice(index, 1);
    this.setState({
      selectedMembers: newSelectedMembers
    })
  };


  handleAddMember = () => {
    const lastSelected = this.state.selectedMembers[this.state.selectedMembers.length - 1];
    if (this.state.selectedMembers.length === 0 || lastSelected && lastSelected.id) {
      this.setState({
        selectedMembers: [...this.state.selectedMembers, this.createEmptyAgent()]
      })
    } 
  };

  handleSave = () => {
      this.setState({ isLoading: true });
      const body = this.state.selectedMembers.length > 0 ? this.state.selectedMembers.filter(Boolean).map((member) => +member.id) : []

      const header = {
        "Content-Type": configJSON.apiContentType,
        token: this.token
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.updateMemberApiEndPoint + this.state.cabData.id + configJSON.updateMemberApiEndPoint2
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify({agent_ids :body})
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postApiMethod
      );
      this.updateMembersApiCallId = requestMessage.messageId

      runEngine.sendMessage(requestMessage.id, requestMessage);
   
  };

  handleEditDetailsClick = () => { this.setState({ isEditMode: true, showModal: true }) }

  handleText = (text: string) => text ? text : '-'

  createEmptyAgent = (): Agents => {
    return {
      id: "",
      type: "",
      attributes: {},
    };
  };

  returnMenuColor = (text: string | number) => {
    return  text === '' ? '#A3A6C2' : '#33354D' 
  }

  // It will handle agent list response
  handleAgentsApiDataResponse = (responseJson: { data: Agents[] }) => {
    if (responseJson?.data) {
      this.setState({
        agents: responseJson.data,
      })
    }
  }

  // It will handle update cab details response
  handleUpdateCabDetailsResponse = (responseJson: { data?: Agents, error: { message: string } }) => {
    if (responseJson?.data) {
      toast.success(configJSON.textCabUpdate)
      this.getCabDetailsById()
      this.setState({
        showModal: false,
        isEditMode: false
      })
    } else if (responseJson?.error?.message) {
      toast.error(responseJson?.error?.message)
    }
  }

  // It will handle get cab details response
  handlegetCabDetailsResponse = (responseJson: { data: CabData }) => {
    if (responseJson?.data) {
      // Convert the already selected agents to the expected format
      const alreadySelectedAgents = responseJson.data.attributes?.agents?.map(agent => ({
        id: agent.id.toString(),
        type: "agent",
        attributes: {
          full_name: agent.full_name,
          email: agent.email
        }
      }));


      // Update the state
      this.setState({
        cabData: responseJson.data,
        initialValues: {
          name: responseJson.data.attributes.name,
          description: responseJson.data.attributes.description
        },
        selectedMembers: alreadySelectedAgents.length > 0 ? alreadySelectedAgents : [this.createEmptyAgent()],
      });
    }
  }

  // It will handle update membesr response
  handleUpdateMembersResponse = (responseJson: { data: { message: string }, errors: { message: string } }) => {
    if (responseJson?.data?.message ) {
      this.setState({
        showModal: false
      })
      this.getCabDetailsById()
      toast.success(configJSON.textUpdateMessage)
    } else if (responseJson?.errors?.message) {
      toast.error(responseJson?.errors?.message)
    }
  }

  // Customizable Area End
}
