import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import toast from 'react-hot-toast';
interface FieldAttributes {
  name: string;
  title: string | null;
  column_type: 'input' | 'email' | 'number' | 'select' | 'textarea';
  mandatory: boolean;
  form_type: string;
  status: boolean;
  optional: boolean;
}

export interface DynamicField {
  id: string;
  type: 'dynamic_field';
  attributes: FieldAttributes;
}


// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoading: boolean,
  txtInputValue: string;
  openListIndex: number;
  requesterFields: DynamicField[];
  departmentFields: any;
  isInfoModalOpen: boolean;
  anchorEl: HTMLElement | null | undefined;
  openEditModal: boolean;
  fieldsData: any;
  selectedUser: 'departmentFields' | "";
  fields: DynamicField[] | [] | any,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

  // Customizable Area Start
  // Customizable Area End

export default class DepartmentFieldsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  pathname = "/userFields";
  getFieldDataApiCallId: string = '';
  updateFieldDataApiCallId: string = '';
  getDepartmentFieldsApiCallId: string = ""
  token: string = ''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    // Customizable Area End
    this.state = {
      // Customizable Area Start
      txtInputValue: "",
      openListIndex: -1,
      isInfoModalOpen: false,
      openEditModal: false,
      isLoading: false,
      anchorEl: null,
      selectedUser: '',
      requesterFields: [],
      departmentFields: [],
      fieldsData: [],
      fields: []
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const apiRequestId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    )
    this.setState({
      isLoading: false
    })

    if (apiRequestId === this.getFieldDataApiCallId) {
      this.handleFieldsDataResponse(responseJson)
    }

    if (apiRequestId === this.updateFieldDataApiCallId) {
      this.handleFieldUpdateResponse(responseJson)
    }


    // Customizable Area End
  }



  // Customizable Area Start
  async componentDidMount() {
    this.token = await getStorageData('authToken')
    this.getFields()
  }

  getFields = () => {
    this.setState({ isLoading: true });
    const header = {
      "Content-Type": configJSON.fieldsApiContentType,
      token: this.token
    };
    let Getfields = new Message(getName(MessageEnum.RestAPIRequestMessage));
    Getfields.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.fieldsApiMethod
    );
    Getfields.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_department/dynamic_department_fields"
    );
    Getfields.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getFieldDataApiCallId = Getfields.messageId;

    runEngine.sendMessage(Getfields.id, Getfields);
  }

  openAccordian = (index: number) => {
    this.setState((prevState) => ({
      openListIndex: prevState.openListIndex === index ? -1 : index,
    }));
  };

  handelInfoModal(value: boolean) {
    this.setState({
      isInfoModalOpen: value
    })
  }

  isPathExist = (item: { href: string }[]) => {
    return item.some((item) => item.href === this.pathname);
  }

  returAgentFieldsLength = () => this.state.departmentFields.filter((agent : any)=> agent.attributes.status === true).length
 
   handleRequiredChange = (index: number, checked: boolean) => {
    const fieldsKey =  'departmentFields';
    const updatedFields = this.state[fieldsKey].map((field: DynamicField, idx: number) =>
      idx === index ? { ...field, attributes: { ...field.attributes, status: checked } } : field
    );
    this.updateFieldState(updatedFields)
  };

  handleMandatoryChange = (index: number, value: string) => {
    const fieldsKey = 'departmentFields';
    const updatedFields = this.state[fieldsKey].map((field: DynamicField, idx: number) =>
      idx === index ? { ...field, attributes: { ...field.attributes, optional: value === 'no' ? false : true } } : field
    );
    this.updateFieldState(updatedFields)
  };

  updateFieldState(updatedFields: DynamicField[]) {
    this.setState({ departmentFields: updatedFields, fieldsData: updatedFields });
  }

  handleCloseEditModal = (value: boolean) => {
    this.setState({
      openEditModal: value,
      selectedUser: ''
    })
  }

  handleEditToggle = (fieldType: 'departmentFields') => {
    this.setState({
      selectedUser: fieldType,
      openEditModal: true,
      fieldsData: this.state.departmentFields
    })
  };

  handleInfoIconClick = (event: any) => {
    this.setState({
      isInfoModalOpen: true,
      anchorEl: event.currentTarget,
    });
  };
  handleSaveData = () => {
    const fieldsKey = 'departmentFields';
    const updatedData = this.findUpdatedElements(this.state.fields, this.state[fieldsKey]);
    if (updatedData.ids.length > 0|| updatedData.optional_ids.length > 0) {
      const header = {
        "Content-Type": configJSON.updateFieldApiContentType,
        token: this.token
      };
      let requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.updateFieldApiMethod
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        "/bx_block_department/dynamic_department_fields/update_user_field"
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(updatedData)
      );

      this.updateFieldDataApiCallId = requestMessage.messageId;
      runEngine.sendMessage(requestMessage.id, requestMessage);
    } else {
      toast.error('No data to update.')
    }
  }
  handleCancelButton = () => {
    this.getFields()
    this.setState({
      openEditModal: false,
    })
  }

  handleDepartmentFieldsNavigation = (path:string)=>{
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), path);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message)
  }

  findUpdatedElements(mainArray: DynamicField[], updatedArray: DynamicField[]) {
    const statusChangedIds: string[] = [];
    const optionalChangedIds: string[] = [];

    mainArray.forEach(mainItem => {
      const updatedItem = updatedArray.find(item => item.id === mainItem.id);

      if (updatedItem) {
        if (mainItem.attributes.status !== updatedItem.attributes.status) {
          statusChangedIds.push(mainItem.id);
        }
        if (mainItem.attributes.optional !== updatedItem.attributes.optional) {
          optionalChangedIds.push(mainItem.id);
        }
      }
    });

    return {ids :statusChangedIds, optional_ids: optionalChangedIds}
  }

  handleFieldsDataResponse = (responseJson: { data: DynamicField[] }) => {
    if (responseJson && responseJson.data) {

      

      this.setState({
        departmentFields: responseJson.data,
        fields: responseJson.data,
      })
    }
  }

  handleFieldUpdateResponse = (responseJson: any) => {
    if (responseJson && responseJson.message === "Successfully updated" ) {
      toast.success(responseJson.message)
      this.setState({
        openEditModal: false,
        fieldsData: [],
      })
      this.getFields()
    }
  }


  // Customizable Area End
}