import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import toast from "react-hot-toast";

interface AgentAndRequester {
    id: string,
    type: string,
    attributes: {
        email: string
    }
}
interface DefaultOption {
    attributes: {
      title: string;
    };
  }
interface Dropdown {
    id: string;
    email: string;
  }
  
  interface State {
    ccDropdowns: Dropdown[];
    requestersList: any[];
    isAddCcDisabled: boolean;
  }
export interface AllGroupsList {
    id: string,
    type: string,
    attributes: {
        name: string,
        category: {
            data: {
                id: number | null,
            }
        }
    }
}
export interface AllAgentsList {
    id: string,
    type: string,
    attributes: {
        full_name: string
    }
}

export interface DepartmentList {
    id: string,
    type: string,
    attributes: {
        department_name: string
    }
}

export interface CategoryList {
    id: string;
    type: string;
    attributes: {
        name: string;
    }
  }
  interface AssociateAssets{
    id: string
    name: string,
    type: string,
    used_by:string
    department: string
    location: string
    serial_no:string
    state: string
  }
  interface FileData {
    name: string;
    lastModified: number;
    size: number;
    type: string
  }
  interface AssetAttributes {
    id: number;
    display_name: string;
    description: string;
    impact: string;
    asset_type: string;
    asset_tag: string;
    end_of_life: string;
    location: string;
    model: string | null;
    serial_no: string | null;
    received_by: string | null;
    purchase_date: string | null;
    dealer_id: string | null;
    managed_by_group: ManagedByGroup;
    used_by: UsedBy;
    department: Department;
    managed_by: ManagedBy;
    assigned_on: string;
    asset_files: string | null;
  }
  interface ManagedByGroup {
    id: number | null;
    name: string | null;
  }
  
  interface UsedBy {
    id: number | null;
    name: string | null;
    email: string | null;
  }
  
  interface Department {
    id: number | null;
    name: string | null;
  }
  
  interface ManagedBy {
    id: number | null;
    name: string | null;
    email: string | null;
  }
  
  interface Asset {
    id: string;
    type: string;
    attributes: AssetAttributes;
  }
  interface TicketAttributes {
    subject: string;
    description: string;
    priority: string;
    planned_startdate: string;
    planned_enddate: string;
    planned_effort: string;
    status: string;
    source: string;
    impact: string | null;
    urgency: string | null;
    category: string;
    tags: string;
    associate_assets:{data:Asset[]}
    ticket_template_id: number;
    created_at: string;
    planned_start_time: string | null;
    planned_end_time: string | null;
    cc: any[];
    group: {
      id: number | null;
      name: string | null;
    };
    requester: {
      id: number | null;
      full_name: string | null;
      email: string | null;
    };
    department: {
      id: number | null;
      name: string | null;
    };
    agent: {
      id: number | null;
      full_name: string | null;
      email: string | null;
    };
    ticket_attachments: any | null;
  }
  
  interface TicketTemplateAttributes {
    title: string;
    description: string;
    available_for: string;
    ticket: {
      data: {
        id: string;
        type: string;
        attributes: TicketAttributes;
      };
    };
    ticket_template_attachments: null | any[];
  }
  interface TicketTemplate {
    id: string;
    type: string;
    attributes: TicketTemplateAttributes;
  }
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    showModal : boolean;
    closeModal : (value: boolean)=> void;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    ccDropdowns: Dropdown[];
    requestersList: any[];
    isAddCcDisabled: boolean;
    isLoading: boolean,
    txtInputValue: string;
    templateList: TicketTemplate[];
    subject: string;
    departmentValue: DepartmentList
    filteredCategories: CategoryList[]
    categoryList: CategoryList[];
    selectedCategoryValue: CategoryList
    selectedTemplate: TicketTemplate | null;
    subjectError: string,
    sourceError : string;
    showAssetModal: boolean,
    statusError : string;
    selectedSource: string,
    selectedStatus: string,
    selectedPriority: string,
    allGroupList: AllGroupsList[],
    selectedGroup: string,
    fileData: FileData[] | null;
    fileName: string
    fileNames: string[]
    filteredOptions: AllGroupsList[];
    allAgentsList: AllAgentsList[],
    filteredAgents: AllAgentsList[],
    allDepartmentList: DepartmentList[],
    filteredDepartmentList: DepartmentList[],
    description: string;
    descriptionError: string;
    startDate: string;
    dueBy: string
    dueByError: string
    startTime: string;
    dueByTime:string
    endDate: string;
    endTime: string;
    timeDifference: string;
    inputValue: string;
    selectedTags: string[];
    selectedCCTags: string[]
    requesterError: string;
    isValidationError: boolean;
    startDateError : string;
    endDateError : string;
    changedDepartment: string;
    changedAgent: string;
    changedGroup: string;
    changedRequester: string;
    startTimeError:string;
    endTimeError: string;
    filtered_assets: AssociateAssets[]
    selectedAssets: AssociateAssets[]
    association_assests:AssociateAssets[]
    showAssetChip: boolean
    showAssetsDropdown: boolean
    template_id: string;
    hideDropdown: boolean
    editTemplate: boolean
    titleError:string;
    generalDescription:string;
    generalDescriptionError:string;
    selectedCategory: string,
    agentValue: AllAgentsList;
    availableFor: string,
    ccValue: string,
    hideCc: boolean
    title: string
    showCcInput: boolean
    selectedTemplateTitle: string
    editedGroup: {id: string, type: string, attributes: {name: string,category: {data: {id: number | null}}}};
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class IncidentTicketCustomFormController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    createTicketApiCallId: string = ''
    getSingalvalueApiCallId: string = ''
    token: string = ''
    status: { title: string, id: string }[] = [
        {
            id: 'Open',
            title: 'Open',
        },
        {
            id: 'Closed',
            title: 'Closed',
        },
        {
            id: 'Pending',
            title: 'Pending',
        },
        {
            id: 'Resolved',
            title: 'Resolved'
        }
    ]
    source = [
        {
            id: '1',
            title: 'Email'
        },
        {
            id: '2',
            title: 'Service Desk'
        },
        {
            id: '3',
            title: 'Phone'
        },
    ]
    getRequesterDataAPICallId: string = "";
    getAllGroupsAPICallId: string = "";
    getAllAgentsListAPICallId: string = "";
    getAllDepartmentAPICallId: string = "";
    submitFormAPICallId: string = "";
    getAllAssetsApiCallId: string = ""
    getAllCategoryAPICallId: string = ""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            ccDropdowns: [{ id: "", email: "" }], 
            requestersList: [], 
            isAddCcDisabled: false,
            isLoading: false,
            txtInputValue: '',
            ccValue:'',
            subject: '',
            dueByError: '',
            showCcInput: false,
            filtered_assets:[],
            departmentValue:{id:'',type:'',attributes:{department_name:''}},
            templateList: [],
            fileData: [],
            availableFor:'',
            editedGroup:{id:'',type:'',attributes:{name:'',category:{data:{id:null}}}},
            fileName:'',
            dueBy:'',
            selectedCCTags:[],
            fileNames:[],
            generalDescription:"",
            title:"",
            dueByTime:'',
            generalDescriptionError:"",
            titleError:"",
            selectedTemplate: null,
            subjectError: '',
            selectedTemplateTitle: '',
            sourceError : "",
            statusError:"",
            selectedAssets: [],
            filteredCategories:[],
            showAssetChip: false,
            showAssetModal: false,
            showAssetsDropdown: false,
            hideDropdown: false,
            template_id:'',
            association_assests:[],
            editTemplate: false,
            selectedSource: this.source[0].id,
            selectedStatus: this.status[0].id,
            agentValue: {id: '',attributes: {full_name: ''}, type: ''},
            selectedPriority: "Low",
            allGroupList: [],
            selectedGroup: '',
            filteredOptions: [],
            allAgentsList: [],
            filteredAgents: [],
            allDepartmentList: [],
            filteredDepartmentList: [],
            description: '',
            descriptionError: '',
            categoryList: [],
            selectedCategoryValue: {id:'',type:'',attributes:{name:''}},
            startDate: "",
            startTime: "",
            endDate: "",
            endTime: "",
            timeDifference: "",
            selectedCategory: "",
            inputValue: "",
            selectedTags: [],
            requesterError: '',
            isValidationError: false,
            startDateError : '',
            endDateError : '',
            changedDepartment: '',
            changedAgent: '',
            changedGroup: '',
            changedRequester: '',
            startTimeError:'',
            hideCc:false,
            endTimeError: ''
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            )
            this.setState({
                isLoading: false
            })

            // This will rerdirect user to login page if token is not valid 
            if (responseJson && Array.isArray(responseJson.errors) && responseJson.errors.length > 0 &&
                (responseJson.errors[0].token === "Token has Expired" || responseJson.errors[0].token === 'Invalid token')) {
                this.goToLogin();
            }
            switch (apiRequestCallId) {
                case this.getSingalvalueApiCallId:
                    this.handlevalueResponse(responseJson);
                    break;

                case this.getRequesterDataAPICallId:
                    this.handleRequestersApiDataResponse(responseJson);
                    break;

                case this.getAllGroupsAPICallId:
                    this.handleGroupApiDataResponse(responseJson);
                    break;

                case this.getAllAgentsListAPICallId:
                    this.handleAgentsListResponse(responseJson);
                    break;
                case this.getAllCategoryAPICallId:
                    this.handleCategoryListResponse(responseJson);
                    break;
                case this.getAllDepartmentAPICallId:
                    this.handleDepartmentListResponse(responseJson);
                    break;

                case this.submitFormAPICallId:
                    this.handleCreateTicketResponse(responseJson);
                    break;
                case this.getAllAssetsApiCallId:
                    this.handleAssetsresponse(responseJson);
                    break;
            }
        }
        // Customizable Area End
    }



    // Customizable Area Start
    async componentDidMount() {
        this.token = await getStorageData('authToken')
        this.getvalue()
        this.getAllRequesterList()
        this.getAllGroups()
        this.getAllAssets()
        this.getAllCategoryList()
        this.setState({ filteredOptions: this.state.allGroupList });
        this.getAllAgentsList()
        this.setState({ filteredAgents: this.state.allAgentsList });
        this.getAllDepartment()
        this.setState({ filteredDepartmentList: this.state.allDepartmentList })
    }


    getAllCategoryList = () => {
        const header = {
            "Content-Type": configJSON.apiContentType,
            token: this.token
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getAllCategoryAPICallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getAllCategoriesApiEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);

    }
    getAllAssets = () => {
        const header = {
            "Content-Type": configJSON.apiContentType,
            token: this.token
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getAllAssetsApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getAllAssetsApiEndpoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    goToLogin() {
        const messages: Message = new Message(
            getName(MessageEnum.NavigationEmailLogInMessage)
        );
        messages.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(messages);
    }

    addCcDropdown = () => {
        this.setState({showCcInput: true});
    };
    hideCC = ()=>{
        this.setState({ hideCc: true})
    }   
    showCC =()=>{
        this.setState({hideCc:false})
    }
    handleAssetsresponse = (response: { data: Asset[] }) => {
        if (response.data) {
          const association_assests: AssociateAssets[] = response.data.map(asset => ({
            id: asset.id,
            name: asset.attributes.display_name,
            type: asset.attributes.asset_type,
            used_by: asset.attributes.used_by.name || '',
            department: asset.attributes.department.name || '',
            location: asset.attributes.location || '',
            serial_no: asset.attributes.serial_no || '',
            state: asset.attributes.impact || '',
          }));
      
          this.setState({ association_assests:association_assests,filtered_assets:association_assests });
        }
      }

    handleCategoryListResponse = (responseJson: { data: CategoryList[] }) => {
        if (responseJson.data)
            this.setState({ categoryList: responseJson.data, filteredCategories: responseJson.data })
    }

    handleCcChange = (index: number, value: string) => {
        this.setState((prevState) => {
            const updatedDropdowns = [...prevState.ccDropdowns];
            updatedDropdowns[index].id = value;   
            return {
                ccDropdowns: updatedDropdowns,
                isAddCcDisabled: true,
            };
        });
    };

    handleSourceChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const sourceValue = event.target.value as string;
        const isSourceEmpty = sourceValue.trim() === '';
        this.setState({
            selectedSource: sourceValue,
            sourceError: isSourceEmpty ? configJSON.sourceReq : ''
        });
    };


    handleSubjectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const subjectValue = event.target.value;
        const isSubjectEmpty = subjectValue.trim() === '';
        const isValidLength = subjectValue.length <= 30;
        const isValidSubject = /^[A-Za-z][A-Za-z0-9]*$/.test(subjectValue);
    
        let subjectError = '';
        if (isSubjectEmpty) {
            subjectError = configJSON.subjectReq;
        } else if (!isValidLength) {
            subjectError = 'Subject must be up to 30 characters long.';
        } else if (!isValidSubject) {
            subjectError = 'Subject must start with a letter and contain only letters and numbers.';
        }
    
        this.setState({
            subject: subjectValue,
            subjectError: subjectError
        });
    }

    handleStatusChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const statusValue = event.target.value as string;
        const isStatusEmpty = statusValue.trim() === '';
        this.setState({
            selectedStatus: statusValue,
            statusError: isStatusEmpty ? configJSON.subjectReq : ''
        });
    }

    handlePriorityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ selectedPriority: event.target.value });
    };

    handleGroupSelectionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event?.target?.value;
        const filteredOptions = this.state.allGroupList.filter(group =>
            group.attributes?.name?.toLowerCase().includes(inputValue?.toLowerCase())
        );
        this.setState({ filteredOptions });
    };

    handleAgentsSelectionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event?.target?.value;
        const filteredAgents = this.state.allAgentsList.filter(agents =>
            agents.attributes?.full_name?.toLowerCase().includes(inputValue?.toLowerCase())
        );
        this.setState({ filteredAgents });
    }

    handleDepartmentSelectionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event?.target?.value;
        const filteredDepartmentList = this.state.allDepartmentList.filter(department =>
            department.attributes?.department_name?.toLowerCase().includes(inputValue?.toLowerCase())
        );
        this.setState({ filteredDepartmentList });
    }

    handleInputChange = (event: { target: { value: string } }) => {
        this.setState({ inputValue: event.target.value });
    };
    handleCCInputChange = (event: { target: { value: string } }) => {
        this.setState({ ccValue: event.target.value });
    };

    handleAddTag = (tags: string) => {
        if (tags && !this.state.selectedTags.includes(tags)) {
            this.setState({
                inputValue: '',
                selectedTags: [...this.state.selectedTags, tags]
            })
        }
    };
    handleCCAddTag = (tags: string) => {
            this.setState({
                ccValue: '',
                selectedCCTags: [...this.state.selectedCCTags, tags]
            })
    };
    handleDeleteTag = (tagToDelete: string) => () => {
        this.setState((tags) => {
            return {
                selectedTags: tags.selectedTags.filter((tags: string) => tags !== tagToDelete)
            }
        });
    };
    handleDeleteCCTag = (tagToDelete: string) => () => {
        this.setState((tags) => {
            return {
                selectedCCTags: tags.selectedCCTags.filter((tags: string) => tags !== tagToDelete)
            }
        });
    };

    handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === configJSON.textEnter) {
            this.handleAddTag(this.state.inputValue);
        }
    };
    handleCCKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === configJSON.textEnter) {
            this.handleCCAddTag(this.state.ccValue);
        }
    };

    navigateUser = () => {
        const navigateToLoginMessage: Message = new Message(
            getName(MessageEnum.NavigationRequestersMessage)
        );
        navigateToLoginMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        navigateToLoginMessage.addData(getName(MessageEnum.UrlPathParamMessage), 'createRequester');
        this.send(navigateToLoginMessage);
    }

  handleChangeDepartment = (value: DepartmentList | null) => {
        if(value)
            this.setState({departmentValue:value})
        else this.setState({ departmentValue: {id:"",type:'',attributes:{department_name:''}} });
        this.setState({ changedDepartment: value ? value.id : '', });
    }

    handleChangeAgents = (value: AllAgentsList | null) => {
        if(value){
            this.setState({agentValue: value})
        }else this.setState({ agentValue: {id:"",type:'',attributes:{full_name:''}} });
        this.setState({ changedAgent: value ? value.id : '' })
    }

    handleChangeGroup = (value: AllGroupsList | null) => {
        if (value) {
            const associatedCategory = value.attributes.category.data !== null && value.attributes.category.data.id !== null ? value.attributes.category.data.id.toString() : '';
            let data: CategoryList[] = [];
            if (value.attributes.category.data && value.attributes.category.data.id !== null) {
                data = this.state.categoryList.filter((item) => item?.id === String(value.attributes.category.data.id));
            }
            this.setState({
                editedGroup: value,
                changedGroup: value.id,
                selectedCategory: associatedCategory,
                selectedCategoryValue: data.length > 0 ? data[0] : {id:'',attributes:{name:''},type:''}
            });
        } else {
            this.setState({
                editedGroup: { id: "", type: '', attributes: { name: '', category: { data: { id: null } } } },
                changedGroup: '',
                selectedCategory: '',
                selectedCategoryValue: {id:'',attributes:{name:''},type:''}
            });
        }
    }

    isTicketTemplate = (value: any): value is TicketTemplate => {
        return value && value.id && value.attributes;
      };
      handleTemplateInputChange = (event: React.ChangeEvent<HTMLInputElement>,) => {
        this.setState({ selectedTemplateTitle: event.target.value });
      };
      
      setThetemplates = async (value: TicketTemplate | DefaultOption | null) => {
        if (value && this.isTicketTemplate(value)) {
          this.setState({
          selectedTemplateTitle:  value.attributes.title,
            editTemplate: true,
            template_id: value.id,
            title: value.attributes.title || this.state.title,
            generalDescription: value.attributes.description || this.state.generalDescription,
            subject: value.attributes.ticket.data?.attributes?.subject || this.state.subject,
            selectedStatus: value.attributes.ticket?.data?.attributes?.status || this.state.selectedStatus,
            selectedSource: value.attributes.ticket?.data?.attributes?.source || this.state.selectedSource,
            selectedPriority: value.attributes.ticket?.data?.attributes?.priority || this.state.selectedPriority,
            availableFor: value.attributes.available_for || this.state.availableFor,
            selectedGroup: value.attributes.ticket?.data?.attributes?.group?.name || this.state.selectedGroup,
            selectedTags: value.attributes.ticket.data.attributes.tags && value.attributes.ticket.data.attributes.tags.length > 0 
              ? value.attributes.ticket.data.attributes.tags.split(',')
              : this.state.selectedTags,
            selectedCategory: value.attributes.ticket?.data?.attributes?.category || this.state.selectedCategory,
            description: value.attributes.ticket?.data?.attributes?.description || this.state.description,
            startDate: value.attributes.ticket.data.attributes.planned_startdate
              ? new Date(value.attributes.ticket.data.attributes.planned_startdate).toISOString().split('T')[0]
              : this.state.startDate,
            selectedAssets: value.attributes.ticket.data.attributes.associate_assets.data.length > 0
              ? this.mapAssets(value.attributes.ticket.data.attributes.associate_assets.data)
              : this.state.selectedAssets,
            startTime: value.attributes.ticket?.data?.attributes?.planned_startdate
              ? new Date(value.attributes.ticket?.data?.attributes?.planned_startdate).toISOString().split('T')[1].split('.')[0]
              : this.state.startTime,
            selectedCategoryValue: value.attributes.ticket.data.attributes.category
              ? await this.getCategory(value.attributes.ticket.data.attributes.category)
              : this.state.selectedCategoryValue,
            endTime: value.attributes.ticket?.data?.attributes?.planned_enddate
              ? new Date(value.attributes.ticket?.data?.attributes?.planned_enddate).toISOString().split('T')[1].split('.')[0]
              : this.state.endTime,
            endDate: value.attributes.ticket?.data?.attributes?.planned_enddate
              ? new Date(value.attributes.ticket?.data?.attributes?.planned_enddate).toISOString().split('T')[0]
              : this.state.endDate,
            timeDifference: value.attributes.ticket?.data?.attributes?.planned_effort || this.state.timeDifference,
            changedDepartment: value.attributes.ticket?.data?.attributes?.department?.id
              ? String(value.attributes.ticket?.data?.attributes?.department?.id)
              : this.state.changedDepartment,
            changedAgent: value.attributes.ticket?.data?.attributes?.agent?.id
              ? String(value.attributes.ticket?.data?.attributes?.agent?.id)
              : this.state.changedAgent,
            changedGroup: value.attributes.ticket?.data?.attributes?.group?.id
              ? String(value.attributes.ticket?.data?.attributes?.group?.id)
              : this.state.changedGroup,
            requesterError: this.state.requesterError,
            inputValue: this.state.inputValue,
            departmentValue: {
              id: value.attributes.ticket?.data?.attributes?.department?.id
                ? String(value.attributes.ticket?.data?.attributes?.department?.id)
                : '',
              type: value.attributes.ticket.data?.attributes?.department?.name || '',
              attributes: { department_name: value.attributes.ticket?.data?.attributes?.department?.name || '' },
            },
            agentValue: {
              id: value.attributes.ticket?.data?.attributes?.agent?.id
                ? String(value.attributes.ticket?.data?.attributes?.agent?.id)
                : '',
              type: value.attributes.ticket.data?.attributes?.agent?.full_name || '',
              attributes: { full_name: value.attributes.ticket?.data?.attributes?.agent?.full_name || '' },
            },
            editedGroup: {
              id: value.attributes.ticket?.data?.attributes?.group?.id
                ? String(value.attributes.ticket?.data?.attributes?.group?.id)
                : '',
              type: value.attributes.ticket.data?.attributes?.group?.name || '',
              attributes: {
                name: value.attributes.ticket?.data?.attributes?.group?.name || '',
                category: { data: { id: Number(value.attributes.ticket?.data?.attributes?.category) } },
              },
            },
            fileNames: value.attributes.ticket_template_attachments && value.attributes.ticket_template_attachments.length > 0
              ? value.attributes.ticket_template_attachments.map((attachment: { id: string; url: string }) => {
                  const urlParts = attachment.url.split('/');
                  return urlParts[urlParts.length - 1];
                })
              : this.state.fileNames,
            fileData: value.attributes.ticket_template_attachments && value.attributes.ticket_template_attachments.length > 0
              ? value.attributes.ticket_template_attachments.map((attachment: { id: string; url: string }) => {
                  return {
                    name: attachment.url.split('/').pop() || '',
                    lastModified: 0,
                    size: 0,
                    type: '',
                  };
                })
              : this.state.fileData,
          });
        } else {
          this.setState({
            editTemplate: false,
            template_id: '',
            title: '',
            generalDescription: '',
            subject: '',
            selectedStatus: '',
            selectedSource: '',
            selectedPriority: '',
            availableFor: '',
            selectedGroup: '',
            selectedTags: [],
            selectedCategory: '',
            description: '',
            startDate: '',
            selectedAssets: [],
            startTime: '',
            selectedCategoryValue: {id:'',type:'',attributes:{name:''}},
            endTime: '',
            endDate: '',
            timeDifference: '',
            changedDepartment: '',
            changedAgent: '',
            changedGroup: '',
            requesterError: '',
            inputValue: '',
            departmentValue: { id: '', type: '', attributes: { department_name: '' } },
            agentValue: { id: '', type: '', attributes: { full_name: '' } },
            editedGroup: { id: '', type: '', attributes: { name: '', category: { data: { id: 0 } } } },
            fileNames: [],
            fileData: [],
          });
        }
      };

        getCategory = async (id: string): Promise<CategoryList> => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    const category = this.state.filteredCategories.filter(agents =>
                        agents.id === id
                    );
                    resolve(category.length > 0 ? category[0] : { id: '7', type: '', attributes: { name: '' } });
                }, 2000);
            });
        }
        mapAssets = (data: Asset[])=>{
            const newData = data.map((item) => ({
               id: item.id,
               name: item.attributes.display_name,
               type: item.attributes.asset_type,
               used_by: item.attributes.used_by?.name || '',
               department: item.attributes.department?.name || '',
               location: item.attributes.location || '',
               serial_no: item.attributes.serial_no || '',
               state: item.attributes.impact || '',
             }));
             if(newData.length >0) this.setState({showAssetChip: true})
             return newData
       }
    // API Integration
    getvalue = () => {
        this.setState({isLoading:true})
        const header = {
            "Content-Type": configJSON.apiContentType,
            token: this.token
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getSingalvalueApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getAllTemplatesApiEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    getAllRequesterList = () => {
        const header = {
            "Content-Type": configJSON.apiContentType,
            token: this.token
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getRequesterDataAPICallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getAllRequestersApiEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    getAllGroups = () => {
        const header = {
            "Content-Type": configJSON.apiContentType,
            token: this.token
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getAllGroupsAPICallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getAllGroupsApiEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    getAllAgentsList = () => {
        const header = {
            "Content-Type": configJSON.apiContentType,
            token: this.token
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getAllAgentsListAPICallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getAllAgentsApiEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    getAllDepartment = () => {
        const header = {
            "Content-Type": configJSON.apiContentType,
            token: this.token
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getAllDepartmentAPICallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getAllDepartmentsApiEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    onFormSubmiit = () => {
        const {subject, description, ccDropdowns, dueBy, dueByTime} = this.state;
        let isError = false;
        // Validate requester
        if (ccDropdowns.length < 1 || !ccDropdowns[0].id) {
            this.setState({ requesterError: configJSON.requesterReq });
            isError = true;
        } else {
            this.setState({ requesterError: '' });
        }
       
        if (!dueBy && !dueByTime) {
            isError = true;
            this.setState({ dueByError: 'Due by date and time are required' });
          } else if (!dueBy) {
            isError = true;
            this.setState({ dueByError: 'Due by date is required' });
          } else if (!dueByTime) {
            isError = true;
            this.setState({ dueByError: 'Due by time is required' });
          } else {
            this.setState({ dueByError: '' });
          }
        // Validate subject
        if (!subject.trim()) {
            this.setState({ subjectError: configJSON.subjectReq });
            isError = true;
        } else {
            this.setState({ subjectError: '' });
        }

        // Validate description
        if (!description.trim()) {
            this.setState({ descriptionError: configJSON.descriptionRequired });
            isError = true;
        } else if (description.length < 10) {
            this.setState({ descriptionError: configJSON.descriptionCharcter });
            isError = true;
        } else {
            this.setState({ descriptionError: '' });
        }


        if (isError) {
            this.setState({ isValidationError: true });
            return;
        }

        this.setState({ isValidationError: false });

        // If no validation errors, proceed with the form submission
        this.createTicket();
    };

    createTicket = () => {
        this.setState({ isLoading: true });
        const header = {
            token: this.token
        };
    
        let formdata = new FormData();
        formdata.append("ticket[subject]", this.state.subject.trim());
        formdata.append("ticket[description]", this.state.description.trim());
        formdata.append("ticket[priority]", this.state.selectedPriority);
        formdata.append("ticket[planned_effort]", this.state.timeDifference);
        formdata.append("ticket[status]", this.state.selectedStatus);
        formdata.append("ticket[source]", this.state.selectedSource);
        formdata.append("ticket[group_id]", this.state.changedGroup);
        formdata.append("ticket[agent_id]", this.state.changedAgent);
        formdata.append("ticket[department_id]", this.state.changedDepartment);
        formdata.append("ticket[category_id]", this.state.selectedCategoryValue.id);
        formdata.append("ticket[tags]", this.state.selectedTags.join(','));
        formdata.append("ticket[requester_id]",this.state.ccDropdowns[0].id)

        if (this.state.selectedAssets.length > 0) {
            for (const asset of this.state.selectedAssets) {
                formdata.append("ticket[associate_assets][]", asset.id);
            }
        }
    
        if (this.state.fileData && this.state.fileData.length > 0) {
            for (const file of this.state.fileData) {
                formdata.append("ticket[ticket_attachments][]", file as unknown as Blob);
            }
        }
        const convertTo12HourFormat = (timeString: string) => {
            const [hours, minutes] = timeString.split(':');
            const date = new Date();
            date.setHours(parseInt(hours, 10));
            date.setMinutes(parseInt(minutes, 10));
            return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
          };
        const startDateString = this.state.startDate;
        const startTimeString = this.state.startTime;
        const formattedStartTime = convertTo12HourFormat(startTimeString)
        if (startDateString && startTimeString) {
            const startDateTimeString = `${startDateString}T${startTimeString}`;
            const startDateTime = new Date(startDateTimeString);
            const startDateTimeISOString = startDateTime.toISOString();
            formdata.append("ticket[planned_startdate]", startDateString);
            formdata.append("ticket[planned_start_time]",formattedStartTime)
        }
        const due_date = this.state.dueBy
        const due_time = this.state.dueByTime
        const formattedDueTime = convertTo12HourFormat(due_time)
        if(due_date && due_time){
            formdata.append("ticket[due_date]",due_date)
            formdata.append("ticket[due_time]",formattedDueTime)
        }
        formdata.append("ticket[cc][]", "");
        const endDateString = this.state.endDate;
        const endTimeString = this.state.endTime;
        const formattedEndTime = convertTo12HourFormat(endTimeString)
        if (endDateString && endTimeString) {
            const endDateTimeString = `${endDateString}T${endTimeString}`;
            const endDateTime = new Date(endDateTimeString);
            const endDateTimeISOString = endDateTime.toISOString();
            formdata.append("ticket[planned_enddate]", endDateString);
            formdata.append("ticket[planned_end_time]",formattedEndTime)
        }
    
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    
        this.submitFormAPICallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.createTicketApiEndPoint);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formdata);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.postApiMethod);
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    // API Responses
    handlevalueResponse = (responseJson: { data: TicketTemplate[] }) => {
        this.setState({isLoading:false,})
        if (responseJson.data) {
            this.setState({
                templateList: responseJson.data
            })
        }
    }
    handleCreateTicketTemplate= ()=>{
        this.props.navigation.navigate("FormTemplates")
    }
    handleRequestersApiDataResponse = (responseJson: { data: AgentAndRequester[] }) => {
        if (responseJson.data) {
            const requesterData = responseJson.data?.map((requester) => ({
                id: requester.id,
                type: 'someType',
                attributes: {
                    email: requester.attributes.email || ''
                }
            }));
            this.setState({
                requestersList: requesterData
            });
        }
    };

  handleGroupApiDataResponse = (responseJson: { data: AllGroupsList[] }) => {
         if (responseJson.data) {
             const groupData = responseJson.data.map((group) => ({
                 id: group.id,
                 type: 'someType',
                 attributes: {
                     name: group.attributes.name || '',
                     category: group.attributes.category || ''
                 }
             }));
             this.setState({
                 allGroupList: groupData,
                 filteredOptions: groupData
             })
         }
     }
 stripHtmlTags = (html: string): string => {
        const div = document.createElement('div');
        div.innerHTML = html;
        return div.textContent || div.innerText || '';
      };
    handleChange = (value: string) => {
        const descriptionValue = value || ''
        const plainTextDescription = this.stripHtmlTags(descriptionValue);
        const isDescriptionValid = plainTextDescription.length >= 10;
        const isDescriptionEmpty = descriptionValue?.trim() === '';
        let descriptionError = '';

        if (isDescriptionEmpty) {
            descriptionError = configJSON.descriptionRequired;
        } else if (!isDescriptionValid) {
            descriptionError = configJSON.descriptionCharcter;
        }

        this.setState({
            description: descriptionValue,
            descriptionError: descriptionError
        });
      };
    handleAgentsListResponse = (responseJson: { data: AllAgentsList[] }) => {
        if (responseJson.data) {
            const agentData = responseJson.data.map((agents) => ({
                id: agents.id,
                type: 'someType',
                attributes: {
                    full_name: agents.attributes.full_name || ''
                }
            }));
            this.setState({
                allAgentsList: agentData
            })
        }
    }

    handleDepartmentListResponse = (responseJson: { data: DepartmentList[] }) => {
        if (responseJson.data) {
            const departmentData = responseJson.data.map((department) => ({
                id: department.id,
                type: 'someType',
                attributes: {
                    department_name: department.attributes.department_name || ''
                }
            }));
            this.setState({
                allDepartmentList: departmentData
            })
        }
    }

    handleCreateTicketResponse = (responseJson: { data: TicketTemplate }) => {
        if (responseJson.data) {
            toast.success(configJSON.successTicketCreateMessage)
            this.props.navigation.navigate("TaskList")
        }
    }
    handleShowAssetModal = ()=>{
        this.setState({showAssetModal:true})
    }
    handleCloseAssetModal = ()=>{
        this.setState({showAssetModal:false})
    }
        handleStartDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            const selectedDate = event.target.value;
            const { endDate } = this.state;
            if (endDate && selectedDate > endDate) {
                this.setState({ startDate: selectedDate, startDateError: configJSON.startDateErrorValidation });
            } else {
                this.setState({ startDate: selectedDate,  endDateError: '',startDateError:'',startTimeError:'',endTimeError:'' }, () => {
                    this.calculateTimeDifference();
                });
            }
        };
        handleDueByChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            const selectedDate = event.target.value;
           this.setState({dueBy:selectedDate,dueByError:''}) 
        };

        handleDueByTimechange = (event: React.ChangeEvent<HTMLInputElement>) => {
            const selectedTime = event.target.value;
            if (!selectedTime) {
                this.setState({ dueByTime: selectedTime, dueByError: "Due by time is required" });
            } else {
                this.setState({ dueByTime: selectedTime, dueByError: ''})
            }   
        };
    
        handleStartTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            const selectedTime = event.target.value;
            if (!selectedTime) {
                this.setState({ startTime: selectedTime, startTimeError: configJSON.startTimeError });
            } else {
                this.setState({ startTime: selectedTime, endDateError: '',startDateError:'',startTimeError:'',endTimeError:'' }, ()=>{
                    this.calculateTimeDifference();
                });
            }   
        };
    
        handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            const selectedDate = event.target.value;
            
            if (selectedDate < this.state.startDate) {
                this.setState({ endDate: selectedDate, endDateError: configJSON.endDateError });
            } else {
                this.setState({ endDate: selectedDate, endDateError: '',startDateError:'',startTimeError:'',endTimeError:'' },()=>{
                    this.calculateTimeDifference()
                });
            }
    
        };
    
        handleEndTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            const selectedEndTime = event.target.value;
           
            const { startDate, endDate, startTime } = this.state;
            if (!selectedEndTime) {
                this.setState({ endTime: selectedEndTime, endTimeError: configJSON.endTimeError });
            } else {
                let endTimeError = '';
                if (startDate === endDate ) {
                    if(selectedEndTime < startTime)
                        endTimeError = 'End time cannot be earlier than start time on the same date.';
                    else if(selectedEndTime === startTime){
                        endTimeError ="The end time must be different from the start time on the same date."
                    }
                }
        
                this.setState({ endTime: selectedEndTime, endDateError: '', startDateError: '', startTimeError: '', endTimeError }, () => {
                    if (!endTimeError) {
                        this.calculateTimeDifference();
                    }
                });
            }
        };
    
        calculateTimeDifference = () => {
            const { startDate, startTime, endDate, endTime } = this.state;
            if(startDate && startTime && endDate && endTime){
                const startDateTime = new Date(startDate + "T" + startTime);
                const endDateTime = new Date(endDate + "T" + endTime);
                const difference = endDateTime.getTime() - startDateTime.getTime();
        
                const hoursDifference = Math.floor(difference / 1000 / 60 / 60) ?? 0;
                const minutesDifference = Math.floor((difference / 1000 / 60) % 60) ?? 0;
        
                this.setState({
                    timeDifference: `${hoursDifference} hours ${minutesDifference} minutes`,
                });
            }
           
        };
        handleSelectedAssets = ()=>{
            this.setState({showAssetModal:false,showAssetChip:true})
        }
        handleClick = (event: React.MouseEvent<HTMLElement>, name: string) => {
            const selectedIndex = this.state.selectedAssets.findIndex((item) => item.id === name);
            let newSelected: AssociateAssets[] = [];
        
            if (selectedIndex === -1) {
                const selectedItem = this.state.association_assests.find((item) => item.id === name);
                if (selectedItem) {
                    newSelected = newSelected.concat(this.state.selectedAssets, selectedItem);
                }
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(this.state.selectedAssets.slice(1));
            } else if (selectedIndex === this.state.selectedAssets.length - 1) {
                newSelected = newSelected.concat(this.state.selectedAssets.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    this.state.selectedAssets.slice(0, selectedIndex),
                    this.state.selectedAssets.slice(selectedIndex + 1)
                );
            }
        
            this.setState({ selectedAssets: newSelected });
        };
        isSelected = (name: string): boolean => this.state.selectedAssets.some((asset) => asset.id === name);
        handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
            if (event.target.checked) {
              const newSelecteds = this.state.association_assests;
              this.setState({ selectedAssets: newSelecteds });
              return;
            }
            this.setState({ selectedAssets: [] });
          };
          handleSearchAssets = (event: React.ChangeEvent<HTMLInputElement>)=>{
            const filteredAssets = this.state.association_assests.filter(asset =>
                asset.name.toLowerCase().includes(event.target.value.toLowerCase())
              );
              this.setState({filtered_assets:filteredAssets})
        }
        handleCancel = ()=>{
            this.props.navigation.navigate("FormTemplates")
        }
        handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            const file = event.target.files?.[0];
            this.setState(prevState => ({
                fileData: [...(prevState.fileData || []), file].filter(Boolean) as FileData[], 
                fileNames: [...(prevState.fileNames || []), file ? file.name : '']
              }));
        }
        
        removeFile = (index: number) => {
            this.setState(prevState => {
                const fileData = [...(prevState.fileData || [])];
                const fileNames = [...prevState.fileNames];
                fileData.splice(index, 1);
                fileNames.splice(index, 1);
                return { fileData, fileNames };
            });
        }
        removeAsset = (index: number) => {
            this.setState(prevState => {
                const assets = [...(prevState.selectedAssets || [])];
                assets.splice(index, 1);
                return { selectedAssets: assets };
            });
        }
        handleCategorySelectionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            const inputValue = event?.target?.value;
            const filteredCategories = this.state.categoryList.filter(agents =>
                agents.attributes?.name?.toLowerCase().includes(inputValue?.toLowerCase())
            );
            this.setState({ filteredCategories });
        }
       handleCategoryChange = (value: CategoryList | null) => {
            if (value) {
                const selectedCategory = value.id.toString(); 
                const associatedGroup = this.state.allGroupList.find(
                    (group) => group.attributes.category.data !== null && String(group.attributes.category.data.id) === selectedCategory
                ) || { id: '', type: '', attributes: { name: '', category: { data: { id: null } } } };
                this.setState({selectedCategoryValue:value, selectedCategory, editedGroup: associatedGroup, changedGroup: associatedGroup.id });
            } else {
                this.setState({filteredCategories:this.state.categoryList, selectedCategoryValue:{id:'',type:'',attributes:{name:''}},selectedCategory: '', editedGroup: { id: '', type: '', attributes: { name: '', category: { data: { id: null } } } }, changedGroup: '' });
            }
        };
    // Customizable Area End
}
