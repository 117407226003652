import React from "react";

import {
  Container,
  Box,
  Button,
  Typography,
  IconButton,
  // Customizable Area Start
  ButtonGroup,
  MenuItem,
  Menu,
  styled,
  Switch,
  Chip
  // Customizable Area End
} from "@material-ui/core";
import { Edit, Delete, FileCopy } from "@material-ui/icons";
// Customizable Area Start
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import NavigationSideBar from "../../../components/src/NavigationSideBar";
import {copySvg,editSvg,deleteSvg} from '../src/assets'
import {ArrowDropDown} from "@material-ui/icons"
import { Toaster } from "react-hot-toast";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
const CustomSwitch = withStyles({
  switchBase: {
    padding: 5,
    paddingLeft:10, 
    "&$checked": {
      color: "#FFFFFF",
      transform: 'translateX(15px)', 
      "& + $track": {
        backgroundColor: "#B49CC9",
      },
    },
  },
  checked: {},
  track: {
    backgroundColor: "#808080",
    borderRadius: 10 / 2, 
    opacity: 1,
    height: 10,
    width: 24, 
    marginTop: 2,
  },
  thumb: {
    width: 8,
    height: 8, 
    marginTop: 10,
    boxShadow: 'none', 
  },
})(Switch);
const CustomMenuItem = styled(MenuItem)({
  width:'200px',
  padding: '9px 16px'
})
const CustomChip = styled(Chip)({
  color: '#FFF',
  fontFamily: 'Manrope',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
  borderRadius: '4px !important',
  border: '0',
  "& .MuiChip-clickable.MuiChip-outlined:focus": {
    backgroundColor: '#11A64A'
  }
})
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

import CfslamanagementlistController, {
  Props,
  configJSON,
} from "./CfslamanagementlistController";




export default class Cfslamanagementlist extends CfslamanagementlistController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderPolicy = ()=>{
    const {policies} = this.state
    return (
     <Box>
      {policies.map((item,index)=>(
        <Box style={{display:'flex',alignItems:'center',justifyContent:'space-between',borderBottom:'1px solid #E6DEED',padding:'12px'}} key={index}>
          <Box style={{display:'flex',alignItems:'center'}}>
          <CustomSwitch data-testid={`custom-switch${index}`} checked={item.attributes.active} onChange={()=> this.handleToggleSwitch(item.id)}></CustomSwitch>
            <Typography style={webStyle.policy}>{item.attributes.name}</Typography>
          </Box>
          <div style={webStyle.policyContainer}>
            {item.attributes.ticket_type && this.returnChip(item.attributes.ticket_type)}
            <img src={copySvg} alt="copy-icon" style={{ cursor: 'pointer' }} />
            <img data-testid={`delete_icon${index}`} src={deleteSvg} onClick={()=> this.handleDeletePolicy(item.id)} alt="delete-icon" style={{ cursor: 'pointer' }} />
            <img src={editSvg} alt="edit-icon" style={{ cursor: 'pointer' }} />
          </div>
        
        </Box>
      ))}

     </Box>
    )
  }
  returnChip = (status: string) => {
    return <CustomChip
      label={status}
      size="medium"
      variant="outlined"
      style={{ textTransform:'capitalize', background: this.getStateColor(status) , width: "5rem",marginRight:'20px' }}
    />
  }
  // Customizable Area End

  render() {

    return (
      // Customizable Area Start
      <>
        <ThemeProvider theme={theme}>
           <Toaster />
          <Box
            style={{
              position: 'fixed'
              , height: '100%',
              width: '100%',
              pointerEvents: 'none',
              overflow: 'hidden',
              zIndex: 1000
            }}>
            <Loader loading={this.state.isPageLoading} />
          </Box>
          <NavigationSideBar active="tickets" navigation={this.props.navigation} />
          <Box
            style={webStyle.container}
            data-testid={"form_modal"}
          >
            <Box style={{ display: 'flex', 
              alignItems: 'center',
               justifyContent: 'space-between' }}>
              <Box style={webStyle.topheader}>
                <span style={webStyle.navigation}> <span >Settings</span> <span>{" > "}</span><span>Service management</span> <span>{" > "}</span><span >SLA  Policies</span>
                </span>
              </Box>
              <ButtonGroup
              style={{ textTransform: 'none',
                 background: "#8760A9" }}
                orientation='horizontal'
                variant="contained"
                
              >
                <Button style={{ textTransform: 'none',
                   color: 'white',
                    background: "#8760A9", 
                    fontWeight: 700, 
                    fontSize: 16 }}  onClick={this.handleCreateTemplate}>
                  Create new policy
                </Button>
                <Button
                aria-controls={this.state.showNewPolicyDropdown ? 'create-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={this.state.showNewPolicyDropdown ? 'true' : undefined}
                  onClick={this.handleCreateTemplate}
                  id="create-button"
                  
                  data-testid="create-policy"
                   style={{ textTransform: 'none',
                     color: "white", 
                     background: "#8760A9" }}
                >
                  <ArrowDropDown />
                </Button>
                <Menu
                  id="create-menu"
                  data-testid="createMenu"
                  anchorEl={this.state.createAnchorEl}
                  open={this.state.showNewPolicyDropdown}
                  disableScrollLock
                  onClose={this.handleCloseCreateTemplate}
                  MenuListProps={{
                    'aria-labelledby': 'create-button',
                  }}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                  getContentAnchorEl={null}
                >
                  <CustomMenuItem data-testid='ticket-policy' onClick={()=>this.handlePolicyCreation('Ticket')} >
                    Ticket
                  </CustomMenuItem>
                  <CustomMenuItem data-testid='task-policy' onClick={()=>this.handlePolicyCreation('Task')}>
                    Task
                  </CustomMenuItem>
                  <CustomMenuItem data-testid='change-policy' onClick={()=>this.handlePolicyCreation('Change')}>
                    Change
                  </CustomMenuItem>
                  <CustomMenuItem data-testid='problem-policy' onClick={()=>this.handlePolicyCreation('Problem')}>
                    Problem
                  </CustomMenuItem>

                </Menu>
              </ButtonGroup>
            </Box>
            <Typography style={webStyle.mainHeading}>SLA and OLA Policies</Typography>
            {this.renderPolicy()}
          </Box>
        </ThemeProvider >
      </>
      // Customizable Area End
    );
  }
}
        
// Customizable Area Start
const webStyle = {
  policyContainer:{
    display:'flex',
    alignItems:'center',
    gap:"8px"
  },
  container: {
    minHeight: "fit-content",
    maxHeight:'100%',
    margin: "15px 15px 15px 300px",
    paddingBottom: "25px",
    fontFamily: "Manrope",
  } as const,
  mainHeading: {
    fontFamily: 'Manrope',
    color: '#33354',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    fontSize: '30px',
    padding: '40px 0'
  } as const,
  topheader: {
    display: "flex",
    justifyContent: "space-between",
    padding: '24px 0'
  } as const,
  navigation: {
    color: '#3D405C',
    display:'flex',
    alignItems:'center',
    gap:'8px',
    fontSize: '14px',
    fontFamily: 'Manrope',
    fontWeight: 400,
    fontStyle: 'normal',
    lineHeight: 'normal',
  } as const,
  top_header:{
    display:"flex",
    justifyContent:"space-between"
  },
  policiesName:{
    marginTop:"60px ",
    fontStyle:"Manrope",
    fontWeight:600,
    fontSize:"30px",
    lineHeight:"40px",
    color:"#33354D"
  },
  policy:{
   color:"#33354D",
   fontSize:"14px",
    fontStyle:"Manrope",
    lineHeight:"20px",
    fontWeight:600,
  },
  taskContainer:{
    margin:"30px 40px 30px 40px",
    display:"flex",
    flexDirection:"column",
  },
  navigations:{
    display:"flex"
  },
  button_group: {
    borderRadius: 8,
    overflow: "hidden",
  },
  btn: {
    backgroundColor: "#8E5DBA",
    color: "white",
    "&:hover": {
      backgroundColor: "#7A4CA6",
  }
},
  dropdown_button: {
    backgroundColor: "#8E5DBA",
    color: "white",
    minWidth: "40px",
    "&:hover": {
      backgroundColor: "#7A4CA6",
    },
  }
};
// Customizable Area End
