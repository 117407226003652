
// Customizable Area Start
import React, { ChangeEvent } from "react";
import CategoriesController, { configJSON, Props } from "./CategoriesController.web";
import NavigationSideBar from "../../../components/src/NavigationSideBar";
import { Toaster } from "react-hot-toast";
import Loader from "../../../components/src/Loader.web";
import RightSidebar from "../../../components/src/RightSidebar";
import { Box, Button, Dialog, Grid, Popover, styled, TextField, Typography } from "@material-ui/core";
import { KeyboardArrowRight, MoreVert } from "@material-ui/icons";
import { cancelIcon, infoIcon, infoIconOutlined, editIcon, deleteIcon } from "./assets";
// Customizable Area End

export default class Categories extends CategoriesController {
    constructor (props: Props) {
      super(props)
      // Customizable Area Start
      // Customizable Area End
    }

    // Customizable Area Start
    rednderBreadcrumbs = () => {
      return (
        <Box style={styles.breadcrumb_wrapper}>
          <span style={styles.navigation}>
            {configJSON.settingLabel} {<KeyboardArrowRight />} {configJSON.userManagementLabel} {<KeyboardArrowRight />} {configJSON.categoriesTitle}
          </span>
          <Box style={styles.morevert_wrapper}>
            <span style={styles.morevert_span}>
              <MoreVert />
            </span>
          </Box>
        </Box>
      )
    }

    renderCategoriesInfoIcon() {
      const icon = this.state.isInfoModalOpen ? infoIcon : infoIconOutlined;
      return (
        <img
          src={icon}
          aria-describedby="infoIcon"
          style={styles.infoIconsStyle}
          onClick={(e: React.MouseEvent<HTMLElement>) => this.handleInfoIconClick(e)}
          data-testid='openCategoriesInfoModal'
        />
      );
    }

    renderCategoriesInfoModal = () => {
      return (
        <Popover
          data-testid="infoModal"
          id="infoIcon"
          open={this.state.isInfoModalOpen}
          anchorEl={this.state.anchorEl}
          onClose={() => this.handelCategoriesInfoModal(false)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          PaperProps={{
            style: { padding: '15px', borderRadius: 8 },
          }}
        >
          <Box style={styles.modal_main_div}>
            <Box style={styles.modal_head}>
              <Typography style={styles.head_title}>{configJSON.categoriesInfoTitle}</Typography>
              <img src={cancelIcon} alt="cancel" style={styles.head_icon} data-tesid="infoCancelIcon" onClick={() => this.handelCategoriesInfoModal(false)} />
            </Box>
            <Box style={styles.modal_body}>
              <Typography style={styles.body_text}>{configJSON.categoriesInfoDescrition}</Typography>
            </Box>
            <Box>
              <Button style={styles.closeBtn} data-testid="cancelInfoModal" onClick={() => this.handelCategoriesInfoModal(false)}>{configJSON.closeBtnLabel}</Button>
            </Box>
          </Box>
        </Popover>
      )
    }

    renderAddCategoryModal () {
      const {editModal} = this.state
      return (
      <Dialog
      open={this.state.addModalOpen}
      onClose={() => {this.handleNewCategoryModal(false) 
        this.setState({editModal:false})
      }}
      fullWidth={true}
      maxWidth="sm"
      data-testid={"create-dialog"}
    >
      <Box p={"2rem"}>
        <Box display={"flex"} justifyContent={"space-between"} width={"100%"}>
          <Typography style={styles.modalHeading}>{editModal ? configJSON.editCategory : configJSON.addCategory}</Typography>
          <span onClick={() => this.handleNewCategoryModal(false)} data-testid="cancelIcon">
            <img src={cancelIcon} alt={configJSON.cancelBTN} />
          </span>
        </Box>
        <Grid container spacing={4} style={{ marginTop: '1%' }}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box style={styles.boxStyle_4}>
              <Typography style={styles.inputLabel}>{configJSON.categoryNameText}<span style={styles.textColorRed}> *</span></Typography>
              <TextField
                variant="outlined"
                fullWidth
                name="name"
                placeholder={configJSON.categoryNamePlaceholder}
                value={this.state.categoryData.name}
                onChange={(event: ChangeEvent<HTMLInputElement>) => this.handleChangeCategoryData(event)}
                data-testid="categoryName"
              />
              {this.state.categoryDataError && <Typography style={styles.error} data-testid="categoryNameError">{this.state.categoryDataError}</Typography>}
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box style={styles.boxStyle_4}>
              <Typography style={styles.inputLabel}>{configJSON.cateogryDescriptionText}</Typography>
              <TextField
                variant="outlined"
                name="description"
                placeholder={configJSON.cateogryDescriptionPlaceholder}
                fullWidth
                minRows={11}
                multiline
                data-testid="description"
                value={this.state.categoryData.description}
                onChange={(event: ChangeEvent<HTMLInputElement>) => this.handleChangeCategoryData(event)}
              />
            </Box>
          </Grid>

          <Grid item container xs={12} sm={12} md={12} lg={12} justifyContent="flex-end">
            <Box style={styles.buttonBox}>
              <Button onClick={() => this.handleNewCategoryModal(false)} data-testid='close_modal' style={styles.cancelButton}>{configJSON.cancelBTN}</Button>
              <Button onClick={() => this.state.categoryData.id ? this.updateCategories() : this.addCategories() } disabled={this.state.categoryDataError} data-testid="add_category" variant="contained" style={styles.submitButton}>
                {this.state.categoryData.id ? configJSON.saveBTN : configJSON.addCategory}</Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Dialog>)
    }
    // Customizable Area End

    render () {
      // Customizable Area Start
      // Customizable Area End
      return (
        // Customizable Area Start
        <React.Fragment>
          <NavigationSideBar active={'Settings'} navigation={this.props.navigation} />
            <Toaster />
            <Loader loading={this.state.isLoading} />
            <Box style={styles.container}>
              {this.rednderBreadcrumbs()}
              <Box style={styles.main_heading}>
                <h1 style={styles.heading}>{configJSON.categoriesTitle}</h1>
                {this.renderCategoriesInfoIcon()}
              </Box>
              <Box style={styles.categoriesListWrapper}>
                <Box style={styles.categoriesGroupList}>
                  <Typography style={{ ...styles.modalHeading, marginBottom: 24}}>{configJSON.categoriesTitle}</Typography>
                  <Scrollable>
                  {this.state.categories?.length === 0 && <Typography style={styles.typographyFont}>{configJSON.noDataFound}</Typography> }
                  {this.state.categories?.map((category) => (
                    <Box key={category.id} display="flex" style={styles.listBox}>
                      <Typography style={styles.listText}>{category.attributes.name}</Typography>
                      <Box style={{ display: 'flex', gap: '10px',alignItems: 'center' }}>
                        <img data-testid="editCategory" style={{ cursor: 'pointer' }} src={editIcon} onClick={() => this.handleNewCategoryModal(true, category.id)} />
                        <img data-testid="deleteCategory" style={{ cursor: 'pointer' }} src={deleteIcon} onClick={() => this.deleteCategories(category.id) }/>
                      </Box>
                    </Box>
                  ))}
                  </Scrollable>
                  <Box style={styles.addNewButtonWrapper}>
                  <Button style={styles.addNew}
                    onClick={() => {
                      this.handleNewCategoryModal(true)
                      this.setState({editModal:false})
                    }}
                    data-testid="openNewModal">
                    {configJSON.addCategory}
                  </Button>
                </Box>
                </Box>
                <RightSidebar data-testid="rightSidebar" pathName="Categories" handleNavigation={this.handleNavigation} />
              </Box>
              {this.renderCategoriesInfoModal()}
              {this.renderAddCategoryModal()}
            </Box>
        </React.Fragment>
        // Customizable Area End
      )
    }
}

// Customizable Area Start
const Scrollable = styled(Box)({
  marginBottom: "32px",
  display: "flex",
  flexDirection: "column",
  gap: '16px',
  "&::-webkit-scrollbar": {
    width: "10px",
    scrollPadding: "10px"
  },
  "&::-webkit-scrollbar-thumb": {
    background: "gray"
  }
});

const styles: Record<string, React.CSSProperties> = {  
  container: {
    height: "100%",
    margin: "15px 15px 15px 300px",
    paddingBottom: "125px",
    fontFamily: "Manrope",
  },

  breadcrumb_wrapper: {
    display: "flex",
    justifyContent: "space-between"
  },

  morevert_wrapper: {
    background: "#e6deed",
    borderRadius: "5px"
  },

  modalHeading: {
    fontFamily: "Manrope",
    fontSize: 20,
    fontWeight: 700,
    lineHeight: "27.32px",
    color: '#33354D'
  },

  morevert_span: {
    width: "36px",
    height: "36px"
  },

  typographyFont: {
    color: "#33354D",
    fontFamily: "Manrope",
    fontSize: "16px",
    fontWeight: 700,
    textAlign: 'center'
  },

  navigation: {
    fontFamily: "Manrope",
    fontSize: "14px",
    lineHeight: "19px",
    display: "flex"
  },

  listBox: {
    justifyContent: "space-between",
    alignItems: "center",
    border: "1px solid #9B7BB7",
    padding: 12,
    borderRadius: 8
  },
  textColorRed: {
    color: '#F22A2A'
  },

  error: {
    fontSize: 12,
    fontFamily: 'Manrope',
    color: "#F22A2A"
  },

  infoIconsStyle: {
    cursor: "pointer",
    color: "#8760A9",
    marginLeft: "10px",
    marginTop: 5
   },

   modal_main_div: {
    maxWidth: "460px"
  },

  modal_head: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "30px"
  },

  head_title: {
    color: "#33354D",
    fontFamily: "Manrope",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
    marginBottom: 10
  },

  head_icon: {
    height: "24px",
    width: "24px",
    cursor: "pointer"
  },

  modal_body: {
    marginBottom: "30px"
  },

  listText: {
    color: "#33354D",
    fontFamily: "Manrope",
    fontSize: "16px",
    fontWeight: 700,
  },

  body_text: {
    color: "#33354D",
    fontFamily: "Manrope",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "140%",
    marginBottom: "10px"
  },

  closeBtn: {
    display: "flex",
    height: "48px",
    padding: "16px",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    borderRadius: "4px",
    background: "#8760A9",
    float: "right",
    color: "white",
    textTransform: "none"
  },

  heading: {
    color: "#33354D",
    fontSize: "30px",
    lineHeight: "40px",
    fontWeight: "bold"
  },

  main_heading: {
    marginTop: 60,
    marginBottom: 50,
    display: "flex",
    alignItems: "center",
    gap: "5px"
  },

  categoriesListWrapper: {
    display: "flex",
    gap: "20px"
  },

  categoriesGroupList: {
    flex: "1",
    padding: "16px",
    borderRadius: "8px",
    boxShadow: "0px 8px 32px 0px rgba(135, 96, 169, 0.16), 0px 4px 8px 0px rgba(135, 96, 169, 0.13)",
    alignSelf: "flex-start"
  },

  addNewButtonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 42
  },

  inputLabel: {
    fontFamily: 'Manrope',
    color: '#3D405C',
    fontSize: '12px',
    fontWeight: 500
  },

  cancelButton: {
    color: '#8760A9',
    fontFamily: 'Manrope',
    fontSize: '16px',
    fontWeight: 700,
    textTransform: 'initial' as const,
  },

  submitButton: {
    backgroundColor: '#8760A9',
    fontFamily: 'Manrope',
    fontSize: '16px',
    fontWeight: 600,
    color: '#FFFFFF',
    textTransform: 'initial' as const,
    padding: '5px 25px 5px 25px',
  },

  addNew: {
    padding: "10px 16px",
    color: "#33354D",
    backgroundColor: "#E6DEED",
    fontFamily: "Manrope",
    fontWeight: 700,
    fontSize: "16px",
    letterSpacing: "0em",
    lineHeight: "16px",
    textTransform: "initial",
    borderRadius: "4px"
  },

}
// Customizable Area End