Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.Deletemethod = "DELETE"
exports.slapolicyAPiEndPoint = "/bx_block_cfslamanagement/sla_and_ola_policies";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cfslamanagement";
exports.labelBodyText = "cfslamanagement Body";
exports.btnExampleTitle = "CLICK ME";
exports.textSetting = "Setting";
exports.textserviceManagement = "Service management";
exports.textPolicies = "SLA Policies";
exports.labelpolicynameText = "New SLA policy";
exports.labelpolicynamebtn = "Create new policy";
exports.deleteApiEndpoint = "bx_block_cfslamanagement/sla_and_ola_policies/"
exports.inputfieldName="Name";
exports.radiofieldPriority="Priority";
exports.selectfieldResponseTime ="First Response";
exports.selectfieldResolutionTime="Resolution Time";
exports.textdescriptionField = "Description";
exports.cancelBtn = "Cancel";
exports.submitBtn = "Submit";
exports.subscriptionApiContentType = "application/json";
exports.labelpolicyname="SLA and OLA Policies";
exports.getpolicyAPIMethod="GET";
exports.slapoliciesnameEndpoints="/bx_block_cfslamanagement/sla_and_ola_policies"
// Customizable Area End