import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  ButtonGroup,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import { MoreVert, ArrowDropDown} from "@material-ui/icons";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import CfchangemanagementticketController, {
  Props,
  configJSON,
} from "./CfchangemanagementticketController";
import NavigationSideBar from "../../../components/src/NavigationSideBar";
import ChangeTicketForm from "../../customform3/src/ChangeTicketForm.web";

export default class Cfchangemanagementticket extends CfchangemanagementticketController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <NavigationSideBar active={'Changes'} navigation={this.props.navigation} />

        <Box style={styles.container}>
        <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >

            <Box
              style={webStyles.topheader}>
              <span
                style={webStyles.navigation}>
                <span >{configJSON.textChanges}

                </span>
                <span>
                  {" > "}</span>
                <span>
                  {configJSON.textChangeRequestName}</span>

              </span>
            </Box>
            <Box style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
              <ButtonGroup
                orientation='horizontal'
                color="primary"
                variant="contained"
                style={{ textTransform: 'none' }}
              >
                <Button data-testId={"open-Modal"} style={{ textTransform: 'none',background:'#8760A9' }}
                  onClick={this.handleModalOpen}
                >{configJSON.textCreateNewChanges}</Button>
                <Button style={{background:'#8760A9'}}>
                  <ArrowDropDown />
                </Button>
              </ButtonGroup>
              <span style={{ width: '36px', height: '36px', background: '#e6deed', borderRadius: '5px' }}>
                <MoreVert style={{ width: '24px', height: '24px', color: 'black', padding: '5px' }} />
              </span>
            </Box>
          </Box>

        </Box>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles: Record<string, React.CSSProperties> = {
  container: {
    height: "100%",
    margin: "15px 15px 15px 300px",
    paddingBottom: "25px",
    fontFamily: "Manrope",
  } as const,

  navigation: {
    fontFamily: "Manrope",
    fontSize: "14px",
    lineHeight: "19px",
  } as const,
};
const webStyles = {
  topheader: {
    display: "flex",
    justifyContent: "space-between",
    padding: '24px 0'
  } as const,
  navigation: {
    color: '#3D405C',
    display:'flex',
    alignItems:'center',
    gap:'8px',
    fontSize: '14px',
    fontFamily: 'Manrope',
    fontWeight: 400,
    fontStyle: 'normal',
    lineHeight: 'normal',
  } as const,
}
// Customizable Area End
