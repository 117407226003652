import React from "react";
import {
  Box,
  Grid,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { MoreVert } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
const SLAandOLA = require('../assets/Vector.svg') as string;
import { slaolaIcons } from "./assets";


const listData = [
  'Account settings',
  'User management',
  'Channels',
  'Service management',
  '', // Place for subItems
  'Asset management',
]
const subListData = [
  { 
    title: 'SLA and OLA Policies', 
    href: "Cfslamanagementlist1"
  }
 
]

const serviceManage = [{
  icon:slaolaIcons,
  title: "SLA and OLA Policies",
  pOne:  "Define service agreements ",
  pTwo: "with internal and external stakeholders",
  href: "Cfslamanagementlist1"
}]

const StyledGridItem = withStyles({
  root: {
    width: "100%",
    height: "192px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    lineHeight: '5px',
    alignItems: "center",
    cursor: 'pointer',
    transition: '.3s ease-out all',
    background: "white",
    borderRadius: "20px",
    border: '1px solid #e6deed',
     "&:hover": {
      background: '#f9f5fb'
    }
  }
})(Grid)

const StyledListItem = withStyles({
  root: {
    padding: '8px 0px 8px 10px',
    borderRadius: '4px',
    transition: '.3s ease-out all',
    cursor: 'pointer',
    "&:hover": {
       color: 'white',
      borderRadius: '8px',
      background: '#8760a9'  
    }
  },
})(Box)
// Customizable Area End

import ServiceManagementController, {
  Props,
  configJSON,
} from "./ServiceManagementController";
import NavigationSideBar from "../../../components/src/NavigationSideBar";

export default class ServiceManagement extends ServiceManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <NavigationSideBar active={'Settings'} navigation={this.props.navigation} />
        <div style={webStyles.container}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <span style={webStyles.navigation}>
                           <span >Settings</span>
                           <span>{" > "}</span>
                           <span>Service management</span>
                          
                         </span>
            </div>
            <div style={{ background: '#e6deed', borderRadius: '5px' }}>
              <span style={{ width: '36px', height: '36px', }}>
                <MoreVert style={{ width: '24px', height: '24px', color: 'black', padding: '5px' }} />
              </span>
            </div>
          </div>
          <h2 style={{ marginTop: "60px", fontWeight:600,marginBottom: "50px", fontSize: '30px', lineHeight: '41px' , fontFamily: 'Manrope',color:"#33354D",marginLeft:"1.5rem" }}>
            Settings
          </h2>
          <div style={webStyles.main_wrapper}>
            <div style={webStyles.group_card_wrapper}>
              
              <div style={webStyles.content__wrapper}>
                <div style={webStyles.flex__wrapper}>
                  <h3 style={webStyles.headline}>{configJSON.slanadolaPolicy}</h3>
                  <Grid style={webStyles.grid}>
                    {serviceManage.map((item, idx) => (
                      <StyledGridItem key={idx} data-test-id={`one${idx}`} onClick={() => this.redirectUser(item?.href)}>
                        <img src={item.icon} style={webStyles.gridSvg} />
                        <div style={webStyles.grid__item__wrapper}>
                          <h5 style={webStyles.h5}>{item.title}</h5>
                          <h6 style={webStyles.h6}>{item.pOne}<br />{item.pTwo}</h6>
                        </div>
                      </StyledGridItem>
                    ))}
                  </Grid>
                </div>
              </div>
            </div>
            <div style={{width:"36%"}}>
              <div style={webStyles.list}>
                {listData.map((item, idx) => {
                  return item === '' ? ( 
                    subListData.map((subItem, subIdx) => (
                      <StyledListItem
                        data-testid="right-sidebar"

                        onClick={() =>{ this.redirectUser(subItem.href)

                        }}
                   key={subIdx}
                      >
                        <span style={webStyles.subListItem}>{subItem.title}</span>
                      </StyledListItem>
                    ))
                  ) : (
                    <StyledListItem key={idx}>
                      <span style={webStyles.span}>{item}</span>
                    </StyledListItem>
                  );
                })}

              </div>
            </div>
          </div>
        </div>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyles: Record<string, React.CSSProperties> = {
  container: {
    width: 'calc(100% - 250px) !important',
    height: '1024px',
    display: 'flex',
    flexDirection: 'column',
    margin: '15px 15px 15px 300px',
    paddingBottom: '25px',
    fontFamily: "Manrope"
  } as const,
  navigation: {
    color: '#3D405C',
    display:'flex',
    alignItems:'center',
    gap:'8px',
    fontSize: '14px',
    fontFamily: 'Manrope',
    fontWeight: 400,
    fontStyle: 'normal',
    lineHeight: 'normal',
  },
  content__wrapper: {
    display: "flex",
    justifyContent: 'center',
    gap: "30px",
    width: "100%"
  },
  flex__wrapper: {
    width: '100%',
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    padding: "15px",
    borderRadius: "15px",
    boxShadow: "0px 3px 30px 20px #e6deed",
  } as const,
  grid: {
    background: "white",
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gridColumnGap: "15px",
    gridRowGap: "15px",
  },
  gridSvg: {
    width: '32px',
    height: '32px',
    color: '#8760a9',
    marginBottom: '-30px'
  },
  grid__item__wrapper: {
    height: "50px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    lineHeight: "15px",
    textAlign: "center",
    gap:'5px',
  } as const,
  h5: {
    fontFamily: 'Manrope',
    margin: '10px',
    marginBottom: '0px',
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: '24px',
    color:"#33354D"
  } as const,
  h6: {
    fontFamily: 'Manrope',
    margin: '0px',
    fontSize: '12px',
    color: "#a3a6c2",
    fontWeight: 600,
    lineHeight: '19px'
  } as const,
  list: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: "15px",
    boxShadow: "0px 3px 30px 20px #e6deed",
    height: "250px",
    background: "white",
    marginTop:"15px",
    padding: "5px",
    width:"90%",
    float: "right"
  } as const,

  main_wrapper: {
    display: "flex",
    gap: "0px",
    padding: "20px"
  },

  group_card_wrapper: {
    flex: "81%"
  },

  headline: {
    fontFamily: "Manrope",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "27px",
    marginTop: "0px", 
    marginBottom: "30px",
    color:"#33354D"
  },

  span: {
    fontFamily: 'Manrope',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '22px',
    color:"#33354D"
  },
  subListItem: {
    fontFamily: 'Manrope',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '22px',
    marginLeft: '12px',
    color:"#33354D"
  } as const,
};
// Customizable Area End
