import React from "react";
// Customizable Area Start
import { Path1 } from "./assets"
import { FinalLogo1 } from "./assets"
import { Path2 } from "./assets"
import { Box, Button, Grid, IconButton, InputAdornment, Container, MenuItem, OutlinedInput, Paper, Select, InputLabel, ThemeProvider, Typography, createTheme } from "@material-ui/core";
import * as Yup from "yup";
import moment from "moment-timezone";
import { Field, Form, Formik } from "formik";
import { Path24 } from "./assets"
import { Path25 } from "./assets"
import Loader from "../../../components/src/Loader.web";
import { Path26 } from "./assets";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { Toaster } from "react-hot-toast";
// Customizable Area End


// Customizable Area Start
const webStyle = {
    signupBox: {
        flexShrink: 0,
        top: "252px",
        left: "220px",
        bottom: "178px",
        position: "relative",
    },
    signup_papaer: {
        width: 1000,
        background: "var(--basic-white, #FFF)",
        boxShadow:
            "0px 8px 32px 0px rgba(135, 96, 169, 0.16), 0px 4px 8px 0px rgba(135, 96, 169, 0.13)",
        borderRadius: "var(--1, 8px)",
    },
    verfifyOTPPpr: {
        borderRadius: "var(--1, 8px)",
        width: 500,
       
        background: "var(--basic-white, #FFF)",
        boxShadow:  "0px 8px 32px 0px rgba(135, 96, 169, 0.16), 0px 4px 8px 0px rgba(135, 96, 169, 0.13)",

    },
    boxLogoWrapper: {
        position: "absolute",
        left: "120px",
        right: "1193px",
        bottom: "920px",
        top: "52px",
    },
    footerMainWrapperSignup: {
        display: "flex", justifyContent: "space-between"
    },

    path24StyleSignup: {
        position: "absolute",
         zIndex: 1, 
         bottom: 0, 
         right: 0
    },
    path25StyleSignup: {
        position: "absolute", 
        zIndex: 1, bottom: 0,
         right: "45px"
    },
    path2StyleSignup: {
        position: "absolute",
         zIndex: 1, bottom: 0
    },
    path1StyleSignup: {
        position: "absolute", zIndex: 1,
         bottom: 0, left: 0
    },
}

const buttonContainerStyle = {
    display: "flex",
    // width: 452,
    alignItems: "center",
    gap: 12,
    borderRadius: "0px 0px var(--1, 8px) var(--1, 8px)",
    background: "var(--basic-white, #FFF)",
    padding: "10px 0px",
    justifyContent: "flex-end",
 
};
const theme = createTheme({
    palette: {
        primary: {
            main: "#8760A9",
            contrastText: "#fff",
        },
    },
});

const validationSchemaAddData = Yup.object().shape({
    password: Yup
        .string()
        .required('Password is required')
        .min(8, 'Password must be at least 8 characters')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
            'Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character'
        ),
    confirmPassword: Yup
        .string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Confirm Password is required'),
    timeZone: Yup.string(),
});

// Customizable Area End


import EmailAccountRegistrationControllerData, {
    Props
} from "./EmailAccountRegistrationControllerData";



class EmailAccountRegistrationData extends EmailAccountRegistrationControllerData {
    constructor(props: Props) {
        super(props);
   // Customizable Area Start
   // Customizable Area End

    }

    // Customizable Area Start
    formRender= ({errors, touched}: {errors: any, touched: any})=>{
        const timezones = moment.tz.names();
        return  <Form translate={undefined}>
        <Grid container spacing={2} >
            
            <Grid item xs={6}>
                <Box>
                    <InputLabel style={{ marginBottom: "5px" }} htmlFor="password">
                        Password<span style={{ color: "red" }}>*</span>
                    </InputLabel>
                    <Field
                        type={!this.state.enablePasswordField ? "text" : "password"}
                        testID="textInputPassword"
                        name="password"
                        id="password"
                        as={OutlinedInput}
                        fullWidth
                        variant="outlined"
                        error={touched.password && !!errors.password}
                        
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    edge="end"
                                    onClick={this.webbtnPasswordShowHideProps.onPress}
                                >
                                    {this.state.enablePasswordField ? (
                                        <Visibility />
                                    ) : (
                                        <VisibilityOff />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                    <span id="testPassowrd" style={{ color: "red", fontSize: '12px' }}>{touched.password && errors.password}</span>

                </Box>
            </Grid>
            <Grid item xs={6}>
                <Box>
                    <InputLabel style={{ marginBottom: "5px" }} htmlFor="confirmPassword">
                        Retype Password<span style={{ color: "red" }}>*</span>
                    </InputLabel>
                    <Field
                        type={!this.state.enableReTypePasswordField ? "text" : "password"}
                        testID="textInputConfirmPassword"
                        name="confirmPassword"
                        id="confirmPassword"
                        as={OutlinedInput}
                        fullWidth
                        variant="outlined"
                        error={touched.confirmPassword && !!errors.confirmPassword}
                       
                        endAdornment={
                            
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    edge="end"
                                    onClick={this.webbtnConfirmPasswordShowHideProps.onPress}
                                >
                                    {this.state.enableReTypePasswordField ? (
                                        <Visibility />
                                    ) : ( 
                                        <VisibilityOff /> 
                                     )} 
                                </IconButton>
                            </InputAdornment>
                        }
                        
                    />
                    <span id="testPassowrd" style={{ color: "red", fontSize: '12px' }}>{touched.confirmPassword && errors.confirmPassword}</span>
                </Box>
            </Grid>
        
            <Grid item xs={6}>
                <Box>
                    <InputLabel style={{ marginBottom: "5px" }} htmlFor="timeZone">
                        Time Zone
                    </InputLabel>
                    <Field
                        type="text"
                        name="timeZone"
                        id="timeZone"
                        as={Select}
                        fullWidth
                        variant="outlined"
                        inputProps={{
                            name: "timeZone",
                            id: "timeZone"
                        }}
                        style={{ maxHeight: "300px" }}

                    >
                        {timezones.map((timezone: string, index: number) => (
                            <MenuItem key={index} value={timezone}>
                                {timezone}
                            </MenuItem>
                        ))}
                    </Field>
                </Box>
            </Grid>
            <Grid item xs={6}>
                <Box>
                    <InputLabel style={{ marginBottom: "5px" }} htmlFor="language">
                        Language
                    </InputLabel>
                    <Field
                        type="text"
                        name="language"
                        id="language"
                        as={Select}
                        fullWidth
                        variant="outlined"
                        inputProps={{
                            name: "language",
                            id: "language"
                        }}
                        style={{ maxHeight: "300px" }}

                    >
                        {this.state.languages.map((timezone: string, index: number) => (
                            <MenuItem key={index} value={timezone}>
                                {timezone}
                            </MenuItem>
                        ))}
                    </Field>
                </Box>
            </Grid>
        </Grid>
        <Box sx={buttonContainerStyle}>
          
            <Button
                data-test-id={"btn_submit"}
                variant="contained"
                color="primary"
                type="submit"
            >
                Submit
            </Button>
        </Box>
    </Form>
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        
        return (
            <ThemeProvider theme={theme}>
                <Toaster/>
                           <Box 
                           style={{position:'fixed',height:'100%',
                           width:'100%',pointerEvents:'none',
                           overflow:'hidden',zIndex:1000}}>
                                        <Loader loading={this.state.isLoading} />
                                        </Box>
                <Container style={{ maxWidth: 1440, height: 1024 }}>
                    <Box sx={webStyle.boxLogoWrapper} >
                        <img src={FinalLogo1} alt="My Image" />
                    </Box>
                   
                        <Box
                            sx={webStyle.signupBox}
                        >
                            <Paper
                                style={webStyle.signup_papaer}
                            >
                                <Box style={{ padding: "24px" }}>
                                    <Typography 
                                    variant="h4" style={{ marginBottom: "18.5px" }}
                                     component="h2">
                                        Complete your registration
                                    </Typography>
                                    <Typography
                                     style={{ marginBottom: "18.5px" }}
                                      variant="subtitle1" component="div">
                                        During your free 21-day trial period, your account will be on the highest trial plan - Enterprise (Trial) and you will have access to all of the features and capabilities of Ganga
                                    </Typography>
                                    <Formik
                                        data-test-id="formikClickEvent"
                                        initialValues={{
                                            password: this.state.password || "",
                                            confirmPassword: this.state.confirmPassword || "",
                                            language: this.state.selectedLanguage || "",
                                            timeZone: this.state.timeZone || "",
                                        }}
                                        validationSchema={validationSchemaAddData}
                                        onSubmit={(values, actions) => {
                                            this.setState({  
                                                password: values.password,
                                                confirmPassword: values.confirmPassword,
                                                timeZone: values.timeZone,
                                                selectedLanguage: values.language
                                            })
                                            this.updateDataForAccount()
                                        }}
                                    >
                                       {({ errors, touched }) => this.formRender({ errors, touched })}
                                    </Formik>
                                </Box>
                            </Paper>
                        </Box>
             
                 
                    <Box
                     sx={webStyle.footerMainWrapperSignup}  >
                        <Box 
                        style={{ display: "flex", }} >
                            <Box 
                            sx={webStyle.path2StyleSignup}  >
                                <img 
                                src={Path2} alt="Path2" />
                            </Box>
                            <Box
                             sx={webStyle.path1StyleSignup} >
                                <img src={Path1} alt="Path1" />
                            </Box>
                        </Box>
                        <Box 
                        style={{ display: "flex", }}>
                            <Box
                             sx={webStyle.path24StyleSignup} >
                                <img 
                                src={Path24} alt="Path24" />
                            </Box >
                            <Box 
                            sx={webStyle.path25StyleSignup} >
                                <img
                                 src={Path25} alt="Path23" />
                            </Box>
                            <Box 
                            sx={webStyle.path24StyleSignup}>
                                <img
                                 src={Path26} alt="Path26" />
                            </Box>
                        </Box>
                    </Box>

                </Container >
            </ThemeProvider >
        );
        // Customizable Area End
    }


}

export default EmailAccountRegistrationData;


// Customizable Area Start

// Customizable Area End
